import { yupResolver } from '@hookform/resolvers/yup';
import { validateCep, validateCPF } from 'validations-br';

import yup from '../../../config/yup';

import LANG from '../../../locale/pt-br';

export default yupResolver(
  yup.object({
    cpf: yup.string().test(
      'is-cpf',
      LANG.VALIDATION.INVALID_CPF,
      (value = '') => validateCPF(value),
    ).required(),
    name: yup.string().required(),
    zipCode: yup
      .string()
      .test('is-cep', LANG.VALIDATION.INVALID_ZIPCODE, (value = '') => validateCep(value))
      .nullable()
      .required(),
    email: yup.string().email(),
    address: yup.string().required().nullable(),
    vehicleType: yup.string().nullable(),
    bodyworkType: yup.string().nullable(),
    number: yup.string().notRequired().nullable(),
    moreInfo: yup.string().notRequired().nullable(),
    neighborhood: yup.string().required().nullable(),
    contactEmail: yup.string().email().nullable().notRequired(),
    contactPhone: yup.string().nullable().notRequired(),
    contactCelPhone: yup.string().nullable().notRequired(),
    uf: yup.string().required().nullable(),
    city: yup.string().required().nullable(),
  }),
);

import axios from '../../config/axios';

export async function getUfs() {
  try {
    const ufs = await axios.get('/ufs');

    return ufs.data || [];
  } catch (error) {
    console.error(error);
  }

  return [];
}

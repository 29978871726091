/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  Controller, SubmitHandler, useForm, useWatch,
} from 'react-hook-form';
import { useCookies } from 'react-cookie';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import schema from './schema';

import { createContact, editContact, getContact } from '../../../providers/contacts';
import { modalGradient } from '../../../constants/backgroundStyle';
import MASKS from '../../../constants/masks';

import { ContactBody } from '../../../types';

interface NewContactProps {
  isOpen: boolean;
  onClose: any;
  hasId: number;
}

function NewContactModal({ isOpen, onClose, hasId }: NewContactProps) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ContactBody>({
    resolver: schema,
    defaultValues: {
      title: '',
      phone: '',
      description: '',
      whatsapp: false,
    },
  });

  const fontColor = '#f3ebeb';

  const [cookies] = useCookies();
  const toast = useToast();

  const resetAndClose = () => {
    reset();
    onClose();
  };

  const loadContactData = useCallback(async (id: number) => {
    if (id && cookies.token) {
      const contact = await getContact(id, cookies.token);

      reset(contact);
    }
  }, [cookies.token, reset]);

  const watchIsWhatsApp = useWatch({
    control,
    name: 'whatsapp',
  });

  const onSubmit: SubmitHandler<ContactBody> = async (data) => {
    try {
      let message;
      let status;

      if (hasId) {
        const hasEdited = await editContact(hasId, data, cookies?.token);

        message = hasEdited.message;
        status = hasEdited.status;
      } else {
        const hasCreated = await createContact(data, cookies?.token);

        message = hasCreated.message;
        status = hasCreated.status;
      }

      toast({
        description: message,
        status,
        duration: 9000,
        isClosable: true,
      });

      resetAndClose();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (hasId > 0) loadContactData(hasId);
  }, [hasId, loadContactData]);

  return (
    <Modal isOpen={isOpen} onClose={resetAndClose} size="lg">
      <ModalOverlay />
      <ModalContent style={modalGradient}>
        <ModalHeader color={fontColor}>{t('USER_PROFILE.NEW_CONTACT_TITLE')}</ModalHeader>
        <ModalCloseButton color={fontColor} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6}>
            <VStack spacing={4}>
              <FormControl isInvalid={!!errors?.title}>
                <Input
                  type="text"
                  placeholder={t('USER_PROFILE.NEW_CONTACT_NAME')}
                  variant="outline"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  borderWidth={2}
                  h={50}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('title')}
                />
                <FormErrorMessage color="white">
                  {errors?.title && errors?.title?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.phone}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <NumberFormat
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      customInput={Input}
                      placeholder={t('USER_PROFILE.CONTACT_PHONE')}
                      format={MASKS.DYNAMIC_PHONE}
                      h={50}
                      borderWidth={2}
                      borderColor="#314292"
                      focusBorderColor="#314292"
                      color={fontColor}
                      _placeholder={{
                        color: fontColor,
                      }}
                    />
                  )}
                />
                <FormErrorMessage color="white">
                  {errors?.phone && errors?.phone?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.description}>
                <Input
                  placeholder={t('USER_PROFILE.NEW_CONTACT_DESC')}
                  variant="outline"
                  type="text"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={50}
                  borderWidth={2}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('description')}
                />
                <FormErrorMessage color="white">
                  {errors?.description && errors?.description?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.whatsapp}>
                <Checkbox
                  h={50}
                  checked={watchIsWhatsApp}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('whatsapp')}
                >
                  {t('USER_PROFILE.NEW_CONTACT_WHATSAPP')}
                </Checkbox>
                <FormErrorMessage color="white">
                  {errors?.whatsapp && errors?.whatsapp?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              bgColor="#314292"
              isLoading={isSubmitting}
              type="submit"
              mr={3}
              color={fontColor}
            >
              {t('USER_PROFILE.NEW_CONTACT_REGISTER')}
            </Button>
            <Button
              color={fontColor}
              onClick={resetAndClose}
            >
              {t('USER_PROFILE.NEW_CONTACT_CANCEL')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default NewContactModal;

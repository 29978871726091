import React, { useEffect } from 'react';
import { Box, Center } from '@chakra-ui/react';

import LANG from '../../locale/pt-br';
import Title from '../../components/Title';

interface PageProps {
  transparent?: boolean;
}

function UserTerms({ transparent }: PageProps) {
  const glassGradient = {
    background:
      'transparent linear-gradient(143deg, #FFFFFF66 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box',
    opacity: 0.96,
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      w="100%"
      style={transparent ? undefined : glassGradient}
      minH="100vh"
      py={3}
    >
      <Center py={4}>
        <Title text={LANG.USER_TERMS.TITLE} size="md1" />
      </Center>

      <Box color="#1a244e" px={8} pt={8} textAlign="justify">
        <p>
          Estes Termos e Condições de Uso tem por objetivo regular o acesso e as
          condições gerais de utilização dos serviços disponibilizados na
          Plataforma FRETEcia, propriedade da WAV MEI Ltda., inscrita no CNPJ
          sob o nº 44.545.878/0001-88, com sede na Rua Quinze de Novembro, 584 ,
          CEP 14.784-186 Cidade de Barretos - SP, doravante denominada
          simplesmente (&quot;FRETEcia&quot;).
        </p>
        <p>
          Tanto a Plataforma FRETEcia, compreendidas nesta, os sites,
          aplicativos e demais mídias de sua propriedade, serão doravante aqui
          referidas indistintamente como (&quot;FRETEcia&quot;).
        </p>
        <p>
          Ao utilizar a Plataforma, o Usuário declara ter lido, compreendido e
          aceito os Termos e Condições de Uso vigentes, em sua integralidade,
          bem como todos os outros alertas e avisos vinculados a assuntos aqui
          tratados que possam aparecer na Plataforma. Caso você não concorde com
          estes Termos e Condições de Uso, não deverá utilizar a Plataforma ou
          acessar seu conteúdo. Se você tiver alguma dúvida sobre estes Termos e
          Condições de Uso, sinta-se à vontade para entrar em contato conosco.
        </p>

        <strong>1. A FRETEcia E OS SERVIÇOS PRESTADOS</strong>
        <br />
        <br />

        <p>
          1.1 A FRETEcia é uma empresa de tecnologia, proprietária da Plataforma
          online (&quot;FRETEcia&quot;), que oferece um espaço virtual de
          comunicação para que seus Usuários, por conta própria, ofereçam e
          contratem serviços de transporte e logística.
        </p>
        <p>
          1.2 Seu objetivo é atender empresas embarcadoras, transportadoras,
          frotistas e caminhoneiros autônomos (em conjunto, denominados
          indistintamente como &quot;Usuário&quot; ou &quot;Usuários&quot;),
          otimizando a oferta e a procura por cargas, empresas e veículos por
          meio da internet.
        </p>

        <p>
          1.3 Os serviços são oferecidos em três plataformas (em conjunto,
          denominadas &quot;Plataforma&quot;):
        </p>
        <ul>
          <li>Site desktop: www.fretecia.com</li>
          <li>Aplicativo para celular: FRETEcia</li>
        </ul>
        <br />
        <p>
          1.4 A Plataforma permite que os Usuários Assinantes (conforme
          definição no item 2.1) compartilhem certas informações e façam
          Anúncios manifestando seu interesse em contratar serviços de
          transporte de carga (doravante &quot;Anúncios&quot;).
        </p>
        <p>
          1.5 Os demais Usuários da Plataforma - em regra, empresas
          transportadoras, frotistas ou motoristas autônomos - podem então
          identificar oportunidades compatíveis com as condições comerciais que
          podem oferecer, e, através dos dados disponibilizados nos Anúncios,
          entrar diretamente em contato com os anunciantes, de forma a negociar
          e celebrar acordos e contratos entre si.
        </p>
        <p>
          1.6 A FRETEcia, portanto, limita-se a fornecer e disponibilizar a
          Plataforma como um canal de comunicação, não interferindo, controlando
          ou verificando, sob qualquer forma, as informações e dados inseridos
          na Plataforma, bem como a interação, negociações e relações entre
          Usuários eventualmente surgidas em razão do uso e/ou contato através
          da Plataforma.
        </p>

        <strong>2. TIPOS DE USUÁRIOS</strong>
        <br />
        <br />

        <p>
          2.1 Na Plataforma, há dois tipos de Usuários, assim definidos: •
          Usuário &quot;Assinante&quot;: são os Usuários que, mediante a
          contratação de um plano de assinatura, tem permissão para divulgar
          Anúncios na Plataforma, além de ter acesso a outras ferramentas
          exclusivas.
        </p>
        <p>
          O Usuário Assinante pode ser tanto uma empresa embarcadora (com
          produtos a transportar), quanto uma empresa transportadora,
          agenciadores e todos aqueles que contratarem um serviço de assinatura
          da plataforma. Os Anúncios, contudo, limitam-se à divulgação do
          interesse em contratar estes serviços, que podem ser prestados por
          transportadoras, frotistas e/ou motoristas caminhoneiros autônomos.
          Para realizar o cadastro na Plataforma como Usuário Assinante, é
          necessário que o Usuário seja uma pessoa jurídica/fisica, com
          inscrição válida perante o CPF (Cadastro de Pessoa Fisica) ou CNPJ
          (Cadastro Nacional da Pessoa Jurídica) aberta há pelo menos 30
          (trinta) dias. • Usuário &quot;Não-Assinante&quot;: são os demais
          Usuários da Plataforma, que não possuem um plano de assinatura, e,
          portanto, não tem permissão para fazer Anúncios. Podem ser empresas
          embarcadoras, transportadoras, frotistas, motoristas autônomos ou
          quaisquer outras pessoas interessadas, que acessem a Plataforma para
          visualizar seu conteúdo. O Usuário &quot;Não-Assinante&quot; tem
          acesso apenas às informações fornecidas pelos Usuários Assinantes que
          forem disponibilizadas ao público geral da Plataforma.
        </p>
        <p>
          2.2 Para utilizar a Plataforma, os Usuários devem registrar-se e
          manter uma conta pessoal de usuário (&quot;Conta&quot;), fornecendo as
          informações ali indicadas (dentre elas, dados pessoais e de contato),
          e criando uma senha de acesso, que deverá ser considerada sigilosa,
          pessoal e intransferível, sendo proibido o uso ou cessão a terceiros.
        </p>
        <p>
          2.3 O Usuário é responsável por todos os dados inseridos em seu ato de
          cadastro, comprometendo-se a manter as informações corretas, completas
          e atualizadas.
        </p>
        <p>
          2.4 O Usuário é responsável por todas as atividades realizadas em sua
          conta, devendo manter os seus dados de acesso (nome de usuário e
          senha) em segurança.
        </p>

        <strong>3. PREÇOS E TAXAS COBRADOS PELO USO DA PLATAFORMA</strong>
        <br />
        <br />
        <p>
          3.1 O uso da Plataforma é completamente gratuito para os Usuários
          Não-Assinantes. Nenhum preço ou taxa é cobrado destes Usuários em
          qualquer etapa do uso da Plataforma.
        </p>

        <p>
          3.2 Para os Usuários Assinantes, podem ser cobradas taxas a título de
          assinatura e manutenção de cadastro, sempre mediante prévia ciência e
          aceite destes Usuários.
        </p>

        <p>
          3.3 Os preços e descontos dependem do formato da assinatura, e podem
          ser consultados no site da FRETEcia (www.fretecia.com).
        </p>

        <p>
          3.3.1 Independentemente do formato de assinatura escolhido pelo
          Usuário Assinante, o atraso no pagamento de quaisquer parcelas devidas
          à FRETEcia implicará em multa moratória única no valor de 2%, e juros
          moratórios de 0,22% por dia até a data do efetivo pagamento.
        </p>

        <p>
          3.4 BLOQUEIO TEMPORÁRIO POR ATRASO NO PAGAMENTO. Na hipótese de atraso
          no pagamento superior a 3 (treis) dias, a FRETEcia reserva-se o
          direito de bloquear temporariamente o acesso do Usuário Assinante à
          Central do Assinante. No momento do bloqueio, todas as cargas ativas
          do Usuário serão excluídas de forma automática e definitiva. Se
          regularizado o pagamento, as cargas excluídas ao tempo do bloqueio
          temporário não serão reativadas.
        </p>

        <p>
          3.5 BLOQUEIO PERMANENTE POR ATRASO NO PAGAMENTO. Após 30 (trinta) dias
          de atraso no pagamento de determinada parcela (ou seja, após 3 dias do
          bloqueio temporário de acesso), o cadastro do Usuário Assinante será
          automaticamente desativado da Plataforma.
        </p>

        <strong>4. FUNCIONAMENTO DA PLATAFORMA</strong>
        <br />
        <br />

        <p>
          4.1 Como forma de propiciar e facilitar o contato entre os Usuários, a
          Plataforma FRETEcia permite que os Usuários Assinantes façam os
          Anúncios descritos no item 1.4.
        </p>
        <p>
          4.2 Nos Anúncios, dentre outras informações, são disponibilizadas para
          visualização dos Usuários os dados referentes aos trajetos, produtos,
          tipos de veículos e demais informações necessárias para a contratação
          e realização dos fretes, bem como os dados para contato das partes.
        </p>
        <p>
          4.3 Caso haja interesse na contratação/realização do frete, os
          Usuários deverão contatar uns aos outros, por conta própria, através
          dos dados de contato por eles fornecidos e disponibilizados na
          Plataforma.
        </p>
        <p>
          4.4 É responsabilidade das partes interessadas, de forma exclusiva,
          todo o processo de contato, negociação, formalização, realização,
          execução e pagamento de todos e quaisquer acordos e contratos
          pactuados e celebrados no âmbito da Plataforma.
        </p>
        <p>
          4.5 A FRETEcia não interfere, participa ou se responsabiliza em
          nenhuma hipótese pela relação comercial dos Usuários surgida em razão
          do uso, contato e/ou contratação de serviços através da Plataforma.
        </p>
        <p>
          4.6 Os serviços prestados pela FRETEcia limitam-se à facilitação da
          comunicação entre os Usuários através de sua Plataforma digital,
          responsabilizando-se nestes limites.
        </p>

        <strong>5. PAGAMENTOS</strong>
        <br />
        <br />

        <p>
          5.1 Os pagamentos relativos às contratações acordadas em razão da
          utilização da Plataforma serão feitos diretamente entre as Partes,
          através dos meios por elas acordados.
        </p>
        <p>
          5.2 A FRETEcia não tem qualquer tipo de controle e não se
          responsabiliza, em nenhuma hipótese, pelo atraso, mora, inadimplência
          ou quaisquer violações contratuais ou legais relativas aos fretes
          acordados na Plataforma, em relação a quaisquer Usuários, sejam eles
          Assinantes ou Não-Assinantes.
        </p>

        <strong>6. DAS NEGOCIAÇÕES E PREÇOS PRATICADOS PELAS PARTES</strong>
        <br />
        <br />

        <p>
          6.1 A FRETEcia não monitora, fiscaliza e não possui qualquer tipo de
          controle sobre as informações e preços oferecidos pelos Usuários na
          Plataforma, limitando-se a fornecer o espaço de comunicação digital.
        </p>
        <p>
          6.2 Neste sentido, as Partes declaram e se comprometem a respeitar
          todas as normas legais, administrativas e regulatórias aplicáveis,
          emitidas por quaisquer autoridades competentes, em relação aos preços
          e condições pactuadas nas prestações de serviços, respondendo
          exclusivamente por quaisquer violações neste sentido.
        </p>

        <strong>
          7. BLOQUEIO E DESATIVAÇÃO DE CADASTRO DE USUÁRIO POR RECLAMAÇÕES
        </strong>
        <br />
        <br />

        <p>
          7.1 Constatada a violação destes Termos e Condições de Uso por um
          Usuário Assinante, a FRETEcia poderá notificar e alertar este Usuário
          de seu descumprimento, reiterando as regras e condições de uso
          vigentes.
        </p>
        <p>
          7.2 Caso se verifique a ocorrência de repetidas violações destes
          Termos e Condições de Uso, a FRETEcia poderá notificar formalmente o
          Usuário Assinante e bloquear temporariamente o seu acesso à
          Plataforma.
        </p>
        <p>
          7.3 Caso, não obstante, o Usuário cometa novas infrações, a FRETEcia
          poderá desativar permanentemente o seu cadastro, a seu critério.
        </p>
        <p>
          7.4 Não serão devolvidas as taxas de assinatura ou de manutenção de
          cadastro referentes ao período em que o acesso do Usuário à Plataforma
          foi bloqueado, de forma temporária ou permanente, em razão da prática
          de infrações a estes Termos e Condições de Uso.
        </p>
        <p>
          7.5 Não obstante as disposições acima, a FRETEcia reserva-se o direito
          de, a seu critério e a qualquer tempo, bloquear ou desativar o
          cadastro de Usuários que violarem os Termos e Condições de Uso da
          Plataforma, conforme sua avaliação da gravidade das infrações
          praticadas.
        </p>

        <strong>8. RESPONSABILIDADE DA FRETEcia</strong>
        <br />
        <br />

        <p>
          8.1 A FRETEcia, na qualidade de empresa de tecnologia, fornecedora da
          Plataforma, responsabiliza-se no limite dos serviços de intermediação
          que oferece, comprometendo-se a manter a Plataforma, na medida do
          possível, ativa e em funcionamento regular.
        </p>
        <p>
          8.2 Nós envidaremos os melhores esforços no sentido de manter as
          informações publicadas por nós na Plataforma precisas, atualizadas e
          completas, o quanto possível. No entanto, não nos responsabilizamos
          por imprecisão, erro, inexatidão, insuficiência ou divergências nas
          informações, dados, imagens ou outros conteúdos relacionados, sejam
          eles postados por nós ou por outros Usuários.
        </p>
        <p>
          8.3 O acesso ao Site é permitido em caráter temporário, e nos
          reservamos o direito de retirar ou alterar os serviços oferecidos na
          Plataforma sem aviso prévio. Nós não nos responsabilizaremos se, por
          qualquer razão, a Plataforma estiver indisponível a qualquer tempo ou
          por qualquer período. Eventualmente, podemos restringir o acesso a
          determinado conteúdo ou a toda a Plataforma.
        </p>
        <p>
          8.4 Na medida do permitido pela legislação, nós e terceiros
          relacionados a nós, por meio deste, expressamente excluímos quaisquer
          responsabilidades por quaisquer fatos ou perdas decorrentes do uso da
          Plataforma.
        </p>
        <p>
          8.5 Nós não nos responsabilizamos por qualquer confiança eventualmente
          depositada pelo Usuário, em relação ao conteúdo disponibilizado na
          Plataforma, ou por pessoas com as quais o Usuário compartilhe tais
          informações.
        </p>
        <p>
          8.6 A FRETEcia, como proprietária da Plataforma, atua como mera
          intermediadora e facilitadora do contato entre as empresas e seus
          potenciais clientes, não interferindo ou se responsabilizando, sob
          qualquer forma, pelas relações mantidas entre os Usuários em razão do
          uso, contato e/ou contratação de serviços através da Plataforma, em
          quaisquer esferas, inclusive na ocorrência de conflitos judiciais ou
          extrajudiciais entre eles.
        </p>
        <p>
          8.7 A FRETEcia não se responsabiliza, endossa, verifica, garante a
          idoneidade ou oferece qualquer tipo de garantia em relação a quaisquer
          Usuários, aos serviços oferecidos e contratados na Plataforma, ou a
          quaisquer informações inseridas por Usuários na Plataforma,
          responsabilizando-se no limite da intermediação a que se propõe,
          considerando que serve apenas de canal de comunicação entre as partes
          interessadas.
        </p>
        <p>
          8.8 Cabe aos Usuários, por sua própria conta, verificar e confirmar as
          informações inseridas na Plataforma, e decidir, por si, pela
          negociação e contratação dos fretes.
        </p>
        <p>
          8.9 A FRETEcia não participa ou interfere na relação dos Usuários em
          quaisquer das etapas das relações de Usuários que podem advir em razão
          do uso da Plataforma, seja na fase de pré-negociação, negociação,
          proposta comercial, celebração, execução, pagamentos de acordos e
          contratos, ou ainda em discussões e conflitos extrajudiciais ou
          judiciais deles decorrentes.
        </p>
        <p>
          8.10 O Usuário reconhece e compreende que a FRETEcia não presta
          serviços de transporte de mercadorias e logística, e não contrata,
          subcontrata ou terceiriza tais serviços, de forma que não tem qualquer
          responsabilidade, ingerência ou controle sobre os transportes
          contratados através da Plataforma, tanto do ponto de vista dos
          Usuários contratantes dos fretes, quanto dos Usuários transportadores,
          prestadores dos serviços.
        </p>
        <p>
          8.11 A FRETEcia não possui qualquer tipo de vínculo com os Usuários
          além da relação mantida pela disponibilização e manutenção da
          Plataforma virtual, na qualidade de prestadora de serviços de
          tecnologia.
        </p>
        <p>
          8.12 A FRETEcia não possui qualquer relação ou responsabilidade
          perante os Usuários em relação aos vínculos que eles possam
          eventualmente manter entre si, sejam estes vínculos oriundos ou não do
          uso e/ou contato através da Plataforma.
        </p>

        <strong>9. RESPONSABILIDADES DOS USUÁRIOS NA PLATAFORMA</strong>
        <br />
        <br />

        <p>
          9.1 Você reconhece, desde já, que a FRETEcia não tem qualquer
          ingerência sobre o conteúdo que for postado por você e pelos outros
          Usuários, não se responsabilizando, em nenhuma hipótese, por suas
          consequências. Dessa forma, você será o único responsável pelo
          conteúdo que inserir ou compartilhar na Plataforma, que deverá ser
          lícito, verídico, e respeitar os direitos de terceiros, bem como a
          legislação nacional em vigor.
        </p>
        <p>
          9.2 São terminantemente proibidas no ambiente da Plataforma mensagens,
          textos, imagens e inserção de qualquer tipo de textos e imagens
          indecorosos, preconceituosos, desrespeitosos, discriminatórios,
          injuriosos, caluniosos, difamatórios e/ou que, de qualquer forma,
          atentem contra a dignidade, a imagem, a reputação, a honra, a moral, a
          integridade ou qualquer outro direito de qualquer pessoa, sua
          nacionalidade, etnia, preferência política ou religião, bem como que
          atentem contra a ordem pública, os bons costumes e/ou qualquer norma
          jurídica vigente e/ou que constituam qualquer espécie de plágio. Os
          Usuários da Plataforma ficam cientes que responderão judicialmente por
          todo e qualquer dano causado pela infração a estas normas de conduta.
        </p>
        <p>
          9.3 Quaisquer negociações, acordos ou contratos celebrados pelos
          Usuários em razão do contato e uso da Plataforma, que ensejem qualquer
          tipo de questão relacionada aos serviços contratados, deverão ser
          tratadas diretamente entre as partes. A FRETEcia não se responsabiliza
          ou oferece qualquer tipo de garantia que transborde os limites da
          intermediação a que se propõe, considerando que serve apenas de canal
          de comunicação entre as partes interessadas.
        </p>
        <p>
          9.4 Quaisquer reclamações de terceiros serão de responsabilidade do
          Usuário, que deverá assumir todas as responsabilidades legais e
          processuais com relação à reclamação. O Usuário fica ciente que a
          FRETEcia denunciará todas e quaisquer lides, litígios e disputas no
          qual for envolvida por conta de atos e condutas submetidas ou
          perpetradas por Usuários ou terceiros, na medida permitida pela lei,
          de modo a não mais ser parte da lide, litígio ou disputa.
        </p>
        <p>
          9.5 A FRETEcia também faculta-se o direito de chamar ao processo
          qualquer Usuário, em razão de quaisquer ações originárias de sua
          conduta e conteúdos por ele submetidos à Plataforma. Faculta-se também
          o direito de responsabilizar diretamente o Usuário perpetrador da
          conduta abusiva, valendo-se para isso de todos os recursos legalmente
          possíveis, incluindo direito de regresso, dentre outros, valendo-se,
          para tanto, dos dados de registro constantes na Plataforma, sem que
          isto configure violação da privacidade de seus dados.
        </p>
        <p>
          9.6 A FRETEcia reserva-se, ainda, o direito de cancelar o registro a
          qualquer tempo de qualquer Usuário, por sua única e exclusiva
          discricionariedade, sem qualquer aviso prévio.
        </p>

        <strong>10. PROPRIEDADE INTELECTUAL</strong>
        <br />
        <br />

        <p>
          10.1 Somos titulares da marca &quot;FRETEcia&quot;, registro nº
          927045060 perante o Instituto Nacional de Propriedade Industrial, bem
          como do conteúdo postado na Plataforma, layout, bancos de dados, redes
          e arquivos que dão suporte ao funcionamento da Plataforma. A
          distribuição ou utilização comercial desses direitos somente pode
          ocorrer mediante a prévia autorização por escrito da FRETEcia.
        </p>
        <p>
          10.2 O usuário poderá utilizar, imprimir e acessar o conteúdo da
          Plataforma somente para uso pessoal. É expressamente proibido utilizar
          o conteúdo da Plataforma de forma comercial ou copiar, reproduzir,
          modificar, publicar, criar trabalho derivado, alimentar outros sites e
          plataformas da Internet, transferir ou vender qualquer conteúdo da
          Plataforma com fins comerciais.
        </p>
        <p>
          10.3 A proibição acima inclui, a título exemplificativo (i) as
          práticas de &quot;screen scraping&quot; ou &quot;database
          scraping&quot;, nos quais são obtidas listas de informações da
          Plataforma, seja para uso pessoal ou comercial; (ii) utilizar a
          Plataforma ou os serviços para fins diversos daqueles a que se
          destinam; (iii) cadastrar-se com informações falsas na Plataforma;
          (iv) utilizar de técnica de engenharia reversa para criação de outros
          trabalhos com base na Plataforma ou conteúdo da Plataforma; e (v)
          acessar a base de dados da Plataforma sem a devida autorização por
          escrito da FRETEcia.
        </p>
        <p>
          10.4 A violação dos termos acima poderá ensejar o cancelamento ou
          suspensão do cadastro do Usuário ou acesso à Plataforma, de forma
          temporária ou definitiva, sem prejuízo de outras sanções legais
          aplicáveis, inclusive na esfera criminal, e indenização aos
          prejudicados, seja a FRETEcia ou qualquer terceiro.
        </p>

        <strong>11. PUBLICIDADE</strong>
        <br />
        <br />

        <p>
          11.1 A FRETEcia disponibiliza, por meio de banners em suas diferentes
          plataformas, espaços para divulgação publicitária de terceiros,
          relacionados ou não ao setor de transportes. Links para outros sites
          não representam relação de sociedade, solidariedade, ou ainda qualquer
          vínculo com a FRETEcia. Nestes casos, a FRETEcia não será responsável
          pelo conteúdo dos mesmos.
        </p>

        <strong>12. COOKIES</strong>
        <br />
        <br />

        <p>
          12.1 Quando o Usuário visita a Plataforma, em quaisquer de seus
          formatos, a FRETEcia pode enviar um ou mais cookies para o seu
          computador ou outro dispositivo. Utilizaremos os cookies para melhorar
          a qualidade dos nossos serviços, armazenando as preferências e
          tendências dos Usuários.
        </p>

        <strong>
          13. EXIBIÇÃO DE NOME E MARCAS DE USUÁRIOS COMO PORTFÓLIO
        </strong>
        <br />
        <br />

        <p>
          13.1 A FRETEcia poderá exibir o nome, logotipos ou marcas dos Usuários
          em sua Plataforma, incluindo sites e redes sociais, para fins de
          divulgação e marketing, e desde que exclusivamente para este fim.
        </p>

        <strong>14. SEGURANÇA VIRTUAL</strong>
        <br />
        <br />

        <p>
          14.1 O Usuário não deve fazer uso indevido da Plataforma permitindo a
          introdução consciente de vírus, &quot;cookies&quot; ou qualquer outro
          artifício malicioso ou tecnologicamente prejudicial (em conjunto,
          &quot;Vírus&quot;). O Usuário não deve tentar obter acesso não
          autorizado a nosso Site, nossos servidores ou qualquer servidor,
          computador, ou base de dados relacionada a este Site.
        </p>
        <p>
          14.2 A Plataforma somente poderá ser vinculada por meio de links
          mediante a prévia autorização por escrito da FRETEcia.
        </p>

        <strong>15. POLÍTICA DE PRIVACIDADE</strong>
        <br />
        <br />

        <p>
          15.1 A FRETEcia se compromete a resguardar informações de uso interno
          não disponíveis para consulta que comprometam a segurança e a
          privacidade dos Usuários, salvo nos casos de requisição judicial
          emitidas por autoridade competente.
        </p>

        <strong>16. DISPOSIÇÕES GERAIS</strong>
        <br />
        <br />

        <p>
          16.1 Estes Termos e Condições de Uso não geram nenhum contrato ou
          relação de trabalho entre a FRETEcia e os Usuários da Plataforma.
        </p>
        <p>
          16.2 A FRETEcia poderá a qualquer momento, visando seu aprimoramento,
          alterar as cláusulas destes Termo. Os novos Termos entrarão em vigor
          na data de sua publicação no site www.fretecia.com, sem a necessidade
          de notificação prévia.
        </p>

        <strong>17. LEGISLAÇÃO E FORO</strong>
        <br />
        <br />
        <p>
          Estes Termos e Condições de Uso serão regidos pelas leis vigentes na
          República Federativa do Brasil. A FRETEcia se compromete a cooperar
          com as autoridades competentes para garantir o cumprimento da
          legislação. Possíveis disputas provenientes do presente Termo, deverão
          ser submetidas ao foro da Comarca de Barretos, Estado de São Paulo.
        </p>
      </Box>
    </Box>
  );
}

UserTerms.defaultProps = {
  transparent: false,
};

export default UserTerms;

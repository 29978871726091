import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Center,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useCookies } from 'react-cookie';
import LANG from '../../locale/pt-br';
import Title from '../../components/Title';
import { glassGradientMenu } from '../../constants/backgroundStyle';
import PaypalForm from './SubscriptionForm/paypal';
import { getPayments } from '../../providers/payments';

interface PageProps {
  transparent?: boolean;
}

interface PaymentList {
  id: number;
  createdAt: string;
  updatedAt: string;
  referenceId: string;
  value: string;
  currency: string;
  type: string;
  instructionLine1: string | null;
  instructionLine2: string | null;
  installments: number;
  dueDate: string | null;
  capture: boolean;
  softDescriptor: string | null;
  description: string;
  recurringPaymentType: string;
  status: string;
  userId: number;
}

function UserSubscription({ transparent }: PageProps) {
  const [cookies] = useCookies();
  const [payments, setPayments] = useState<PaymentList[]>([]);

  const getPaymentData = useCallback(async () => {
    const paymentData = await getPayments(cookies.token);

    setPayments(paymentData?.data ?? []);
  }, [cookies.token]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPaymentData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSubscribed = useMemo(() => {
    if (!payments?.length) {
      return {
        isSub: false,
        lastPaymentDate: null,
        nextPaymentDate: null,
      };
    }

    const lastPayment = payments[0];

    // know if last payment.updatedAt is less than 30 days
    if (lastPayment) {
      const lastPaymentDate = new Date(lastPayment.updatedAt);
      const today = new Date();

      const diffTime = Math.abs(today.getTime() - lastPaymentDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 30) {
        return {
          isSub: false,
          lastPaymentDate: lastPayment.updatedAt,
          nextPaymentDate: null,
        };
      }
    }

    const nextPayment = new Date(lastPayment.updatedAt);

    return {
      isSub: true,
      lastPaymentDate: lastPayment.updatedAt,
      nextPaymentDate: nextPayment.setDate(nextPayment.getDate() + 30),
    };
  }, [payments]);

  return (
    <Box
      w="100%"
      style={transparent ? undefined : glassGradientMenu}
      minH="100vh"
    >
      <Center py={4}>
        <Title text={LANG.USER_SUBSCRIPTION.TITLE} size="md1" />
      </Center>

      <VStack spacing={8} mt={4} mx={4} justifyContent="center">
        <Box border="2px solid #314292" p={8}>
          <Box>
            <PaypalForm />
            {!isSubscribed ? <PaypalForm />
              : (
                <VStack>
                  <Text py={2} fontWeight="semibold" color="#FFF">
                    {LANG.USER_SUBSCRIPTION.IS_SUBSCRIBED_TITLE}
                  </Text>
                  <Text py={2} fontWeight="semibold" color="#FFF">
                    {`${LANG.USER_SUBSCRIPTION.SUBSCRIPTION_VALID_UNTIL} ${isSubscribed.nextPaymentDate && new Date(isSubscribed.nextPaymentDate).toLocaleString('pt-BR')}`}
                  </Text>
                </VStack>
              )}
          </Box>
        </Box>
      </VStack>
    </Box>
  );
}

UserSubscription.defaultProps = {
  transparent: false,
};

export default UserSubscription;

import { yupResolver } from '@hookform/resolvers/yup';
import { validateCep, validateCNPJ } from 'validations-br';

import yup from '../../../config/yup';

import LANG from '../../../locale/pt-br';

export default yupResolver(
  yup.object({
    cnpj: yup
      .string()
      .test('is-cnpj', LANG.VALIDATION.INVALID_CNPJ, (value = '') => validateCNPJ(value))
      .required(),
    zipCode: yup
      .string()
      .test('is-cep', LANG.VALIDATION.INVALID_ZIPCODE, (value = '') => validateCep(value))
      .nullable()
      .required(),
    tradeName: yup.string().nullable().required(),
    companyName: yup.string().nullable().required(),
    businnessType: yup.string().required(),
    email: yup.string().email(),
    address: yup.string().required().nullable(),
    number: yup.string().notRequired().nullable(),
    moreInfo: yup.string().notRequired().nullable(),
    neighborhood: yup.string().required().nullable(),
    contactEmail: yup.string().email().nullable().notRequired(),
    contactPhone: yup.string().nullable().notRequired(),
    contactCelPhone: yup.string().nullable().notRequired(),
    city: yup.string().required().nullable(),
    uf: yup.string().required().nullable(),
  }),
);

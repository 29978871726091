import {
  // eslint-disable-next-line max-len
  FormControl, FormErrorMessage, Select, Input, InputGroup, InputLeftElement, Checkbox, Text, Button, useToast, VStack,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AiFillMail, AiOutlineUnlock, AiOutlineUser } from 'react-icons/ai';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import LANG from '../../../locale/pt-br';
import MASKS from '../../../constants/masks';
import schemaCompany from '../../../pages/SignIn/RegisterForm/schemaCompany';
import { createUser, CreateUserBody } from '../../../providers/user';

function FormCompany() {
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateUserBody>({
    resolver: schemaCompany,
  });

  const onSubmit: SubmitHandler<CreateUserBody> = async (data) => {
    // eslint-disable-next-line no-param-reassign
    data.role = 'COMPANY';
    const isCreated = await createUser(data);

    toast({
      description: isCreated.message,
      status: isCreated.status,
      duration: 9000,
      isClosable: true,
    });

    navigate('/logout');
  };

  return (

    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack>
        <FormControl isInvalid={!!errors?.cnpj}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiOutlineUser color="#FFF" size="24" />
            </InputLeftElement>
            <Controller
              name="cnpj"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <NumberFormat
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  customInput={Input}
                  placeholder={LANG.SIGN_UP.CNPJ}
                  format={MASKS.CNPJ}
                  variant="flushed"
                  h={50}
                  width="full"
                  borderColor="white"
                  color="#FFF"
                  _placeholder={{
                    color: '#FFF',
                  }}
                  style={{ paddingLeft: '40px' }}
                />
              )}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.cnpj && errors?.cnpj?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.tradeName}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiOutlineUser color="#FFF" size="24" />
            </InputLeftElement>
            <Input
              id="tradeName"
              width="full"
              borderColor="white"
              placeholder={LANG.SIGN_UP.TRADE_NAME}
              color="#FFF"
              variant="flushed"
              _placeholder={{
                color: 'white',
              }}
              {...register('tradeName')}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.tradeName && errors?.tradeName?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.businnessType}>
          <Select
            borderColor="white"
            variant="flushed"
            placeholder={LANG.SIGN_UP.COMPANY_TYPE}
            color="#FFF"
            {...register('businnessType')}
          >
            <option value="AGENT">Agenciadora</option>
            <option value="SHIPPER">Embarcadora</option>
            <option value="SHIPPING_COMPANY">Transportadora</option>
          </Select>
          <FormErrorMessage color="white">
            {errors?.businnessType && errors?.businnessType?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.email}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiFillMail color="#FFF" size="24" />
            </InputLeftElement>
            <Input
              type="email"
              width="full"
              borderColor="white"
              placeholder={LANG.SIGN_UP.EMAIL}
              color="white"
              variant="flushed"
              _placeholder={{
                color: 'white',
              }}
              {...register('email')}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.email && errors?.email?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.password}>
          <InputGroup>
            <InputLeftElement>
              <AiOutlineUnlock color="#FFF" size="24" />
            </InputLeftElement>
            <Input
              type="password"
              width="full"
              placeholder={LANG.SIGN_UP.PASSWORD}
              borderColor="white"
              variant="flushed"
              color="white"
              _placeholder={{ color: 'white' }}
              {...register('password')}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.password && errors?.password?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.confirmPassword}>
          <InputGroup>
            <InputLeftElement>
              <AiOutlineUnlock color="#FFF" size="24" />
            </InputLeftElement>
            <Input
              type="password"
              width="full"
              placeholder={LANG.SIGN_UP.CONFIRM_PASSWORD}
              borderColor="white"
              variant="flushed"
              color="white"
              _placeholder={{ color: 'white' }}
              {...register('confirmPassword')}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.confirmPassword && errors?.confirmPassword?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl as="fieldset" py={2}>
          <Checkbox defaultChecked>
            <Text fontWeight="semibold" color="#FFF" fontSize="14px">
              {LANG.SIGN_UP.LGPD_CHECKBOX}
            </Text>
          </Checkbox>
        </FormControl>

        <Button
          type="submit"
          width="full"
          size="lg"
          isLoading={isSubmitting}
          bgColor="#314292"
        >
          <Text color="#FFF">{LANG.SIGN_UP.REGISTER}</Text>
        </Button>
      </VStack>
    </form>
  );
}

export default FormCompany;

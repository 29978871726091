/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';
import { ColorModeScript } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';

import * as serviceWorker from './serviceWorker';

import App from './App';
import 'react-datetime/css/react-datetime.css';
import './assets/css/styles.css';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <App />
  </React.StrictMode>,
);

// Register service worker
serviceWorker.register();

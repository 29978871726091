/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  ReactNode, ReactText, useMemo,
} from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Image,
  Center,
} from '@chakra-ui/react';
import {
  FiLogOut,
  FiMenu,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import {
  FaFileInvoice,
  FaHome, FaPhoneAlt, FaSearch, FaSignInAlt, FaTruck, FaUser,
}
  from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import ActionDialog from '../ActionDialog';

import { glassGradientMenu } from '../../constants/backgroundStyle';

import LANG from '../../locale/pt-br';

import Logo from '../../assets/logo.png';
import { useUserDataContext } from '../../contexts';
import { baseURL } from '../../config/axios';

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => (
  <Link
    style={{ textDecoration: 'none' }}
    _focus={{ boxShadow: 'none' }}
  >
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={glassGradientMenu}
      {...rest}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="20"
          _groupHover={{
            color: 'white',
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  </Link>
);

interface SidebarProps extends BoxProps {
  onCloseSidebar: () => void;
}

const SidebarContent = ({ onCloseSidebar, ...rest }: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isLoggedIn, username, avatar, role,
  } = useUserDataContext();
  const { isOpen: isOpenAlert, onClose: onCloseAlert, onOpen: onOpenAlert } = useDisclosure();
  const avatarFile = avatar === '' ? Logo : `${baseURL}/avatars/${avatar}`;

  const isNotDriver = useMemo(() => role !== 'DRIVER', [role]);

  const MENUS = useMemo(
    () => [
      {
        icon: FaHome,
        navigateTo: () => navigate('/'),
        isActive: location.pathname === '/',
        name: LANG.MENU.HOME,
        show: true,
      },
      {
        icon: FaSignInAlt,
        navigateTo: () => navigate('/sign-in'),
        isActive: location.pathname === '/sign-in',
        name: LANG.MENU.LOGIN,
        show: !isLoggedIn,
      },
      {
        icon: FaUser,
        navigateTo: () => navigate('/user-profile'),
        isActive: location.pathname === '/user-profile',
        name: LANG.MENU.MY_PROFILE,
        show: isLoggedIn,
      },
      {
        icon: FaTruck,
        navigateTo: () => navigate('/user-cargos'),
        isActive: location.pathname === '/user-cargos',
        name: LANG.MENU.MY_CARGOS,
        show: isLoggedIn && isNotDriver,
      },
      {
        icon: FaFileInvoice,
        navigateTo: () => navigate('/user-subscription'),
        isActive: location.pathname === '/user-subscription',
        name: LANG.MENU.MY_SUB,
        show: isLoggedIn && isNotDriver,
      },
      {
        icon: FaSearch,
        navigateTo: () => navigate('/search'),
        isActive: location.pathname === '/search',
        name: LANG.MENU.SEARCH,
        show: true,
      },
      {
        icon: FaPhoneAlt,
        navigateTo: () => navigate('/contact-us'),
        isActive: location.pathname === '/contact-us',
        name: LANG.MENU.CONTACT_US,
        grow: 1,
        show: true,
      },
      {
        icon: FiLogOut,
        navigateTo: () => navigate('/logout'),
        isActive: location.pathname === '/logout',
        name: LANG.MENU.LOGOUT,
        grow: 1,
        show: isLoggedIn,
      },
    ],
    [location.pathname, isLoggedIn, isNotDriver, navigate],
  );

  const navigateOut = () => {
    onCloseAlert();
    navigate('/logout');
  };

  return (
    <Box w={{ md: 60 }} pos="fixed" h="full" bgColor="#344F9F" {...rest}>
      <Center>
        <Box mt={8}>
          <Image rounded="full" src={avatarFile} alt="logo" h="75px" w="75px" />
        </Box>
      </Center>

      {isLoggedIn && (
        <Box pt={4}>
          <Center>
            <Text fontWeight="semibold" color="#FFF" fontSize="sm">
              {` Olá, ${username}`}
            </Text>
          </Center>
        </Box>
      )}

      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <CloseButton
          color="#FFF"
          display={{ base: 'flex', md: 'none' }}
          onClick={onCloseSidebar}
        />
      </Flex>

      <Box mb={4}>
        <ActionDialog
          isOpen={isOpenAlert}
          onClose={onCloseAlert}
          onConfirm={navigateOut}
          title={LANG.EXIT_DIALOG.TITLE}
          text={LANG.EXIT_DIALOG.TEXT}
        />
        {MENUS.map(
          (link) => link?.show && (
          <NavItem
            key={link.name}
            icon={link.icon}
                // Here we exit the system with a custom dialog
            onClick={
                  link.name === LANG.MENU.LOGOUT ? onOpenAlert : (
                    () => {
                      onCloseSidebar();
                      link.navigateTo();
                    }
                  )
                }
            fontWeight="bold"
            style={link?.isActive ? glassGradientMenu : undefined}
            color="#FFF"
          >
            {link.name}
          </NavItem>
          ),
        )}
      </Box>
    </Box>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => (
  <Flex
    ml={{ base: 0, md: 60 }}
    px={{ base: 4, md: 24 }}
    height="20"
    alignItems="center"
    bgColor="#344F9F  "
    borderBottomWidth="1px"
    borderBottomColor="#333"
    justifyContent="flex-start"
    {...rest}
  >
    <IconButton
      variant="outline"
      onClick={onOpen}
      aria-label="open menu"
      icon={<FiMenu />}
    />

    <Text fontSize="2xl" ml="8" fontWeight="bold" color="#FFF">
      Menu - Fretecia
    </Text>
  </Flex>
);

export default function SimpleSidebar({ children }: { children: ReactNode }) {
  const {
    isOpen: isOpenSimpleSidebar,
    onOpen: onOpenSimpleSidebar,
    onClose: onCloseSimpleSidebar,
  } = useDisclosure();
  return (
    <Box minH="100vh" bgColor="#3F83D5">
      <SidebarContent
        onCloseSidebar={() => onCloseSimpleSidebar}
        display={{ base: 'none', md: 'block' }}
        bgColor="#3F83D5"
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpenSimpleSidebar}
        placement="left"
        onClose={onCloseSimpleSidebar}
        returnFocusOnClose={false}
        onOverlayClick={onCloseSimpleSidebar}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onCloseSidebar={onCloseSimpleSidebar} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav
        display={{ base: 'flex', md: 'none' }}
        onOpen={onOpenSimpleSidebar}
      />
      <Box ml={{ base: 0, md: 60 }}>{children}</Box>
    </Box>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactNode } from 'react';
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

// import AppStoreBadge from '@/components/AppStoreBadge';
// import PlayStoreBadge from '@/components/PlayStoreBadge';

import { useLocation, useNavigate } from 'react-router-dom';
import { useUserDataContext } from '../../contexts';

import LANG from '../../locale/pt-br';
import { NO_FOOTER_PAGES } from '../../constants/noFooterPages';

const ListHeader = ({ children }: { children: ReactNode }) => (
  <Text fontWeight="semibold" fontSize="14px" mb={2}>
    {children}
  </Text>
);

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => (
  <chakra.button
    bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
    rounded="full"
    w={8}
    h={8}
    cursor="pointer"
    as="a"
    href={href}
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    transition="background 0.3s ease"
    target="_blank"
    _hover={{
      bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
    }}
  >
    <VisuallyHidden>{label}</VisuallyHidden>
    {children}
  </chakra.button>
);

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useUserDataContext();
  const { pathname } = location;

  if (NO_FOOTER_PAGES.includes(pathname)) return null;

  return (
    <Box bg="#314292" color="#FFF">
      <Container as={Stack} maxW="6xl" py={4}>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 4 }}
          spacing={8}
          fontSize="12px"
        >
          <Stack align="flex-start">
            <ListHeader>{LANG.FOOTER.COMPANY}</ListHeader>
            <Link onClick={() => navigate('who-is')}>
              {LANG.FOOTER.ABOUT_US}
            </Link>
            <Link onClick={() => navigate('how-it-works')}>
              {LANG.FOOTER.HOW_IT_WORKS}
            </Link>
            <Link onClick={() => navigate('contact-us')}>
              {LANG.FOOTER.CONTACT_US}
            </Link>
          </Stack>

          <Stack align="flex-start">
            <ListHeader>{LANG.FOOTER.LEGAL}</ListHeader>
            <Link onClick={() => navigate('privacy-policy')}>
              {LANG.FOOTER.LGPD}
            </Link>
            <Link onClick={() => navigate('user-terms')}>
              {LANG.FOOTER.USER_TERMS}
            </Link>
          </Stack>

          <Stack align="flex-start">
            <ListHeader>{LANG.FOOTER.SUBSCRIBER}</ListHeader>
            <Link onClick={() => navigate('sign-in')}>
              {LANG.FOOTER.SIGN_IN}
            </Link>
            {isLoggedIn
            && (
            <>
              <Link onClick={() => navigate('sign-in')}>
                {LANG.FOOTER.SIGN_UP}
              </Link>
              <Link onClick={() => navigate('user-cargos')}>
                {LANG.FOOTER.MY_CARGOS}
              </Link>
              <Link onClick={() => navigate('user-subscription')}>
                {LANG.FOOTER.MY_SUBSCRIPTION}
              </Link>
            </>
            )}
          </Stack>
        </SimpleGrid>
      </Container>

      <Box borderTopWidth={1} borderStyle="solid" borderColor="#FFF">
        <Container
          as={Stack}
          maxW="6xl"
          py={1}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ md: 'space-between' }}
          align={{ md: 'center' }}
        >
          <Text fontSize="12px">
            {LANG.FOOTER.ALL_RIGHTS_RESERVED}
          </Text>
          <Stack direction="row" spacing={6}>
            <SocialButton
              label="Facebook"
              href="https://www.facebook.com/freteciaoficial"
            >
              <FaFacebook />
            </SocialButton>
            <SocialButton
              label="Instagram"
              href="https://www.instagram.com/fretecia_oficial"
            >
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

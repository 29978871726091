import { yupResolver } from '@hookform/resolvers/yup';
import { validateCNPJ } from 'validations-br';

import yup from '../../../config/yup';
import LANG from '../../../locale/pt-br';

export default yupResolver(yup.object({
  cnpj: yup.string().test(
    'is-cnpj',
    LANG.VALIDATION.INVALID_CNPJ,
    (value = '') => validateCNPJ(value),
  ).required(),
  tradeName: yup.string().required(),
  businnessType: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required(),
  confirmPassword: yup.string()
    .oneOf(
      [yup.ref('password'), null],
      LANG.VALIDATION.PASSWORD_MATCHES,
    )
    .required(),
}));

import {
  // eslint-disable-next-line max-len
  FormControl, FormErrorMessage, Input, InputGroup, InputLeftElement, Checkbox, Text, Button, useToast, VStack, Select,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AiFillMail, AiOutlineUnlock, AiOutlineUser } from 'react-icons/ai';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import LANG from '../../../locale/pt-br';
import MASKS from '../../../constants/masks';
import schemaDriver from '../../../pages/SignIn/RegisterForm/schemaDriver';
import { createUser, CreateUserBody } from '../../../providers/user';

function FormDriver() {
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateUserBody>({
    resolver: schemaDriver,
  });

  const onSubmit: SubmitHandler<CreateUserBody> = async (data) => {
    // eslint-disable-next-line no-param-reassign
    data.role = 'DRIVER';
    // eslint-disable-next-line no-param-reassign
    data.businnessType = 'DRIVER';

    const isCreated = await createUser(data);

    toast({
      description: isCreated.message,
      status: isCreated.status,
      duration: 9000,
      isClosable: true,
    });

    navigate('/logout');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack>
        <FormControl isInvalid={!!errors.cpf}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiOutlineUser color="#FFF" size="24" />
            </InputLeftElement>
            <Controller
              name="cpf"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <NumberFormat
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  customInput={Input}
                  placeholder={LANG.SIGN_UP.CPF}
                  format={MASKS.CPF}
                  variant="flushed"
                  h={50}
                  width="full"
                  borderColor="white"
                  color="#FFF"
                  _placeholder={{
                    color: '#FFF',
                  }}
                  style={{ paddingLeft: '40px' }}
                />
              )}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.cpf && errors?.cpf?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.name}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiOutlineUser color="#FFF" size="24" />
            </InputLeftElement>
            <Input
              id="name"
              width="full"
              borderColor="white"
              placeholder={LANG.SIGN_UP.NAME}
              color="#FFF"
              variant="flushed"
              _placeholder={{
                color: 'white',
              }}
              {...register('name')}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.name && errors?.name?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.bodyworkType}>
          <Select
            borderColor="white"
            variant="flushed"
            placeholder={LANG.SIGN_UP.BODYWORK_TYPE}
            color="#FFF"
            {...register('bodyworkType')}
          >
            <option value="CHEST">Baú</option>
            <option value="CONTAINER_BUG">Bug porta-container</option>
            <option value="REFRIGERATOR_CHEST">Baú Frigorífico</option>
            <option value="BUCKET">Caçamba</option>
            <option value="GOSSIP">Cavaqueira</option>
            <option value="STORK">Cegonheiro</option>
            <option value="CAGE">Gaiola</option>
            <option value="GREAT_LOW">Grande Baixa</option>
            <option value="BULK_CARRIER">Graneleiro</option>
            <option value="BOARD">Prancha</option>
            <option value="MUNK">Munk</option>
            <option value="SIDER">Sider</option>
            <option value="SILO">Silo</option>
            <option value="TANK">Tanque</option>
          </Select>
          <FormErrorMessage color="white">
            {errors?.bodyworkType && errors?.bodyworkType?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.vehicleType}>
          <Select
            borderColor="white"
            variant="flushed"
            placeholder={LANG.SIGN_UP.VEHICLE_TYPE}
            color="#FFF"
            {...register('vehicleType')}
          >
            <option value="THREE_FOUR">3/4</option>
            <option value="BITRAIN">Bitrem</option>
            <option value="BITRUCK">Bitruck</option>
            <option value="CART">Carreta</option>
            <option value="LS_CART">Carreta LS</option>
            <option value="STUMP">Toco</option>
            <option value="TRUCK">Truck</option>
            <option value="ROAD_TRAIN">Rodotrem</option>
            <option value="VANDERLEIA">Vanderléia</option>
            <option value="VLC">VLC</option>
          </Select>
          <FormErrorMessage color="white">
            {errors?.vehicleType && errors?.vehicleType?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.phone}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiOutlineUser color="#FFF" size="24" />
            </InputLeftElement>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <NumberFormat
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  customInput={Input}
                  placeholder={LANG.SIGN_UP.PHONE}
                  format={MASKS.DYNAMIC_PHONE}
                  variant="flushed"
                  h={50}
                  width="full"
                  borderColor="white"
                  color="#FFF"
                  _placeholder={{
                    color: '#FFF',
                  }}
                  style={{ paddingLeft: '40px' }}
                />
              )}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.phone && errors?.phone?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.email}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiFillMail color="#FFF" size="24" />
            </InputLeftElement>
            <Input
              type="email"
              width="full"
              borderColor="white"
              placeholder={LANG.SIGN_UP.EMAIL}
              color="white"
              variant="flushed"
              _placeholder={{
                color: 'white',
              }}
              {...register('email')}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.email && errors?.email?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.password}>
          <InputGroup>
            <InputLeftElement>
              <AiOutlineUnlock color="#FFF" size="24" />
            </InputLeftElement>
            <Input
              type="password"
              width="full"
              placeholder={LANG.SIGN_UP.PASSWORD}
              borderColor="white"
              variant="flushed"
              color="white"
              _placeholder={{ color: 'white' }}
              {...register('password')}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.password && errors?.password?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.confirmPassword}>
          <InputGroup>
            <InputLeftElement>
              <AiOutlineUnlock color="#FFF" size="24" />
            </InputLeftElement>
            <Input
              type="password"
              width="full"
              placeholder={LANG.SIGN_UP.CONFIRM_PASSWORD}
              borderColor="white"
              variant="flushed"
              color="white"
              _placeholder={{ color: 'white' }}
              {...register('confirmPassword')}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.confirmPassword && errors?.confirmPassword?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl as="fieldset" py={2}>
          <Checkbox defaultChecked>
            <Text fontWeight="semibold" color="#FFF" fontSize="14px">
              {LANG.SIGN_UP.LGPD_CHECKBOX}
            </Text>
          </Checkbox>
        </FormControl>

        <Button
          type="submit"
          width="full"
          size="lg"
          isLoading={isSubmitting}
          bgColor="#314292"
        >
          <Text color="#FFF">{LANG.SIGN_UP.REGISTER}</Text>
        </Button>
      </VStack>
    </form>
  );
}

export default FormDriver;

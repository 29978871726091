/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

import LANG from '../../locale/pt-br';

interface ActionDialogProps {
  title: string;
  text: string;
  isOpen: boolean;
  onClose: any;
  onConfirm: any;
}

function ActionDialog({
  isOpen, onClose, title, text, onConfirm,
}: ActionDialogProps) {
  const cancelRef = React.useRef<any>();

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" color="white">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody color="white">{text}</AlertDialogBody>

          <AlertDialogFooter>
            <Button color="white" ref={cancelRef} onClick={onClose}>
              {LANG.TOASTS.COMMONS.CANCEL}
            </Button>
            <Button color="white" bgColor="#314292" onClick={onConfirm} ml={3}>
              {LANG.TOASTS.COMMONS.CONFIRM}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default ActionDialog;

import React, { useEffect } from 'react';
import {
  Box, Center, Stack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import FindYourCargo from './FindCargo';
import Companies from './Companies';
import Title from '../../components/Title';
import Wrapper from '../../components/Wrapper';

interface pageProps {
  transparent?: boolean;
  firstPage?: boolean;
}

function HowItWorks({ transparent, firstPage }: pageProps) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper transparent={transparent}>
      <Stack minH={!firstPage ? '100vh' : undefined}>
        <Center width="100%" pb={10} pt={!firstPage ? 10 : 0}>
          <Title
            center
            text={t('HOW_IT_WORKS.NEW_WAY_TO_CONNECT_COMPANY')}
            size="md1"
          />
        </Center>
        <Box px={4}>
          <FindYourCargo />
          <Companies />
        </Box>
      </Stack>
    </Wrapper>
  );
}

HowItWorks.defaultProps = {
  transparent: false,
  firstPage: false,
};

export default HowItWorks;

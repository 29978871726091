import { Controller } from 'react-hook-form';
import { Select as ChakraReactSelect, GroupBase } from 'chakra-react-select';
import { CSSObject } from '@chakra-ui/react';

import { OptionProps } from '../../types';

interface ControlledSelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  inputName: string;
  placeholder: string;
  options: OptionProps[];
  isMulti?: |undefined | true;
  fontColor?: string;
  selectedStyle?: 'transparent' | 'blueFilter';
}

function ControlledSelect({
  control, inputName, placeholder, options, isMulti, fontColor, selectedStyle,
}: ControlledSelectProps) {
  let style = {};

  if (selectedStyle === 'transparent') {
    style = {
      container: (provided: CSSObject) => ({
        ...provided,
        color: fontColor,
      }),
      menuList: (provided: CSSObject) => ({
        ...provided,
        bg: '#314292',
        color: fontColor,
      }),
      dropdownIndicator: (provided: CSSObject) => ({
        ...provided,
        bg: 'transparent',
        color: fontColor,
      }),
    };
  } else if (selectedStyle === 'blueFilter') {
    style = {
      container: (provided: CSSObject) => ({
        ...provided,
        color: fontColor,
        bg: '#314292',
        borderColor: '#FFF',
      }),
      menuList: (provided: CSSObject) => ({
        ...provided,
        bg: '#314292',
        color: fontColor,
      }),
      dropdownIndicator: (provided: CSSObject) => ({
        ...provided,
        bg: '#314292',
        color: fontColor,
      }),
    };
  }

  return (
    <Controller
      name={inputName}
      control={control}
      render={({
        field: {
          onChange, onBlur, value, name, ref,
        },
      }) => (
        <ChakraReactSelect<
          OptionProps,
          true,
          GroupBase<OptionProps>
        >
          isMulti={isMulti}
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          options={options}
          placeholder={placeholder}
          closeMenuOnSelect
          placeholderColor={fontColor}
          focusBorderColor="#314292"
          className="blue_input"
          chakraStyles={style}
        />
      )}
    />
  );
}

ControlledSelect.defaultProps = {
  isMulti: undefined,
  fontColor: '#f3ebeb',
  selectedStyle: 'transparent',
};

export default ControlledSelect;

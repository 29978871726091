/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { SearchFilterProps } from '../../types.d';
import axios from '../../config/axios';

export async function filterCargos(searchFilters: SearchFilterProps) {
  try {
    const cargos = await axios.get('/filter', {
      params: searchFilters,
    });

    return cargos?.data || [];
  } catch (error) {
    const err = error as AxiosError | any;

    console.error(err);
    return [];
  }
}

export async function filterCargoById(id: number) {
  try {
    const cargos = await axios.get(`/filter/${id}`);

    return cargos?.data;
  } catch (error) {
    const err = error as AxiosError | any;

    console.error(err);
    return [];
  }
}

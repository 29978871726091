/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Select,
  Stack,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Controller, SubmitHandler, useForm,
} from 'react-hook-form';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import MASKS from '../../../constants/masks';
import {
  CityProps, UfList, UfProps, UserProps,
} from '../../../types';
import schema from './schema';

import { editUser, getMe } from '../../../providers/user';
import { getUfs } from '../../../providers/uf';
import { getCitiesByUfId } from '../../../providers/cities';
import { useUserDataContext } from '../../../contexts';
import LogoUploadDialog from '../LogoUploadDialog';
import { getZipCode } from '../../../providers/zipcode';

interface FormProps {
  inputHeight: number;
  inputFontSize: string;
  onOpenContacts: any;
}

function Form({ inputHeight, inputFontSize, onOpenContacts }: FormProps) {
  const { t } = useTranslation();
  const { setIsFirstAccess } = useUserDataContext();
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<UserProps>({
    resolver: schema,
    defaultValues: {
      neighborhood: '',
      address: '',
    },
  });

  const toast = useToast();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [ufs, setUfs] = useState<UfList>([]);
  const [cities, setCities] = useState<CityProps[]>([]);

  const {
    isOpen: isOpenLogoUploadDialog,
    onOpen: onOpenLogoUploadDialog,
    onClose: onCloseLogoUploadDialog,
  } = useDisclosure();

  const fontColor = '#f3ebeb';

  const getCitiesById = async (ufId: string | undefined) => {
    try {
      const loadedCities = await getCitiesByUfId(ufId);

      setCities(loadedCities);

      return loadedCities;
    } catch (error) {
      console.error(error);
    }

    return [];
  };

  const getUserInfo = useCallback(async () => {
    try {
      if (!cookies.token) navigate('/login', { replace: true });

      const data = await getMe(cookies.token);

      if (data?.code === 401) navigate('/login', { replace: true });

      await getCitiesById(data?.uf?.toString());

      reset(data);
    } catch (error) {
      if (!cookies?.token) navigate('/login', { replace: true });
    }
  }, [cookies.token, navigate, reset]);

  const getInitialData = useCallback(async () => {
    try {
      const loadedUfs = await getUfs();

      setUfs(loadedUfs);
    } catch (error) {
      console.error('Erro ao buscar ufs');
    }
  }, []);

  const handleFindZipCode = async (e: React.FormEvent<HTMLInputElement>) => {
    const zipcode = e.currentTarget.value;
    const data = await getZipCode(zipcode);
    const uf = ufs.find((item) => item.uf === data.uf);

    setValue('address', data.logradouro);
    setValue('neighborhood', data.bairro);

    if (uf?.id) {
      setValue('uf', uf?.id?.toString());

      const foundCities = await getCitiesById(uf?.id?.toString());
      const city = foundCities.find((item: any) => item.name === data.localidade);

      setValue('city', city?.ibge.toString());
    }
  };

  const onSubmit: SubmitHandler<UserProps> = async (data) => {
    const hasEdited = await editUser(data, cookies.token);

    if (hasEdited.status === 'success') {
      setIsFirstAccess(false);
    }

    toast({
      description: hasEdited.message,
      status: hasEdited.status,
      duration: 9000,
      isClosable: true,
    });
  };

  useEffect(() => {
    getInitialData();
    getUserInfo();
  }, [getInitialData, getUserInfo]);

  return (
    <>
      <LogoUploadDialog
        title={t('USER_PROFILE.LOGO_TITLE')}
        text={t('USER_PROFILE.LOGO_DESC')}
        isOpen={isOpenLogoUploadDialog}
        onClose={onCloseLogoUploadDialog}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={2} px={12}>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="14px"
            w="100%"
          >
            <Box flexGrow={1}>
              <FormControl isInvalid={!!errors?.cnpj}>
                <Controller
                  name="cnpj"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <NumberFormat
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      customInput={Input}
                      placeholder={t('USER_PROFILE.CNPJ')}
                      format={MASKS.CNPJ}
                      borderColor="#314292"
                      focusBorderColor="#314292"
                      h={inputHeight}
                      fontSize={inputFontSize}
                      borderWidth={2}
                      color={fontColor}
                      _placeholder={{
                        color: fontColor,
                      }}
                    />
                  )}
                />
                <FormErrorMessage color="white">
                  {errors?.cnpj && errors?.cnpj?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box flexGrow={2}>
              <FormControl isInvalid={!!errors?.tradeName}>
                <Input
                  placeholder={t('USER_PROFILE.TRADE_NAME')}
                  variant="outline"
                  type="text"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={inputHeight}
                  fontSize={inputFontSize}
                  borderWidth={2}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('tradeName')}
                />
                <FormErrorMessage color="white">
                  {errors?.tradeName && errors?.tradeName?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="14px"
            w="100%"
          >
            <Box flexGrow={2}>
              <FormControl isInvalid={!!errors?.companyName}>
                <Input
                  id="companyName"
                  placeholder={t('USER_PROFILE.COMPANY_NAME')}
                  variant="outline"
                  type="text"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={inputHeight}
                  fontSize={inputFontSize}
                  borderWidth={2}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('companyName')}
                />
                <FormErrorMessage color="white">
                  {errors?.companyName && errors?.companyName?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="14px"
            w="100%"
          >
            <Box flexGrow={1}>
              <FormControl isInvalid={!!errors?.businnessType}>
                <Select
                  id="businnessType"
                  placeholder={t('USER_PROFILE.COMPANY_TYPE')}
                  disabled
                  _disabled={{ color: fontColor }}
                  variant="outline"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={inputHeight}
                  fontSize={inputFontSize}
                  borderWidth={2}
                  color={fontColor}
                  {...register('businnessType')}
                >
                  <option value="AGENT">Agenciadora</option>
                  <option value="SHIPPER">Embarcadora</option>
                  <option value="SHIPPING_COMPANY">Transportadora</option>
                </Select>
                <FormErrorMessage color="white">
                  {errors?.businnessType && errors?.businnessType?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box flexGrow={2}>
              <FormControl isInvalid={!!errors?.email}>
                <Input
                  placeholder={t('USER_PROFILE.EMAIL')}
                  variant="outline"
                  type="text"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={inputHeight}
                  fontSize={inputFontSize}
                  borderWidth={2}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  disabled
                  _disabled={{
                    color: fontColor,
                  }}
                  {...register('email')}
                />
                <FormErrorMessage color="white">
                  {errors?.email && errors?.email?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box flexGrow={1}>
              <FormControl isInvalid={!!errors?.zipCode}>
                <Controller
                  name="zipCode"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <NumberFormat
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      customInput={Input}
                      placeholder={t('USER_PROFILE.ZIPCODE')}
                      format={MASKS.ZIPCODE}
                      borderColor="#314292"
                      focusBorderColor="#314292"
                      h={inputHeight}
                      fontSize={inputFontSize}
                      borderWidth={2}
                      color={fontColor}
                      _placeholder={{
                        color: fontColor,
                      }}
                      onBlur={handleFindZipCode}
                    />
                  )}
                />
                <FormErrorMessage color="white">
                  {errors?.zipCode && errors?.zipCode?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="14px"
            w="100%"
          >
            <Box flexGrow={2}>
              <FormControl isInvalid={!!errors?.address}>
                <Input
                  id="address"
                  placeholder={t('USER_PROFILE.ADDRESS')}
                  variant="outline"
                  type="text"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={inputHeight}
                  fontSize={inputFontSize}
                  borderWidth={2}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('address')}
                />
                <FormErrorMessage color="white">
                  {errors?.address && errors?.address?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box flexShrink={1}>
              <FormControl isInvalid={!!errors?.number}>
                <Input
                  placeholder={t('USER_PROFILE.NUMBER')}
                  variant="outline"
                  type="text"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={inputHeight}
                  fontSize={inputFontSize}
                  borderWidth={2}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('number')}
                />
                <FormErrorMessage color="white">
                  {errors?.number && errors?.number?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box flexGrow={2}>
              <FormControl isInvalid={!!errors?.neighborhood}>
                <Input
                  id="neighborhood"
                  type="text"
                  placeholder={t('USER_PROFILE.NEIGHBORHOOD')}
                  variant="outline"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={inputHeight}
                  fontSize={inputFontSize}
                  borderWidth={2}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('neighborhood')}
                />
                <FormErrorMessage color="white">
                  {errors?.address && errors?.neighborhood?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="14px"
            w="100%"
          >
            <Box flexGrow={1}>
              <FormControl isInvalid={!!errors?.moreInfo}>
                <Input
                  id="moreInfo"
                  placeholder={t('USER_PROFILE.MORE_INFO')}
                  variant="outline"
                  type="text"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={inputHeight}
                  fontSize={inputFontSize}
                  borderWidth={2}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('moreInfo')}
                />
                <FormErrorMessage color="white">
                  {errors?.address && errors?.moreInfo?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box flexGrow={1}>
              <FormControl isInvalid={!!errors?.uf}>
                <Select
                  placeholder={t('USER_PROFILE.UF')}
                  variant="outline"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={inputHeight}
                  fontSize={inputFontSize}
                  borderWidth={2}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('uf')}
                  onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                    getCitiesById(e.currentTarget.value);
                  }}
                >
                  {ufs
                    && ufs.map((uf: UfProps) => (
                      <option
                        key={uf?.id}
                        value={uf?.id}
                      >
                        {uf?.name}
                      </option>
                    ))}
                </Select>
                <FormErrorMessage color="white">
                  {errors?.uf && errors?.uf?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box flexGrow={2}>
              <FormControl isInvalid={!!errors?.city}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      placeholder={t('USER_PROFILE.CITY')}
                      variant="outline"
                      borderColor="#314292"
                      focusBorderColor="#314292"
                      h={inputHeight}
                      fontSize={inputFontSize}
                      borderWidth={2}
                      color={fontColor}
                      _placeholder={{
                        color: fontColor,
                      }}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                    >
                      {cities.map((city) => (
                        <option key={city?.ibge} value={city?.ibge}>
                          {city?.name}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                <FormErrorMessage color="white">
                  {errors?.city && errors?.city?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="14px"
            w="100%"
          >
            <Box flexGrow={2}>
              <FormControl isInvalid={!!errors?.contactPhone}>
                <Controller
                  name="contactPhone"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <NumberFormat
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      customInput={Input}
                      placeholder={t('USER_PROFILE.CONTACT_PHONE')}
                      format={MASKS.DYNAMIC_PHONE}
                      borderWidth={2}
                      borderColor="#314292"
                      focusBorderColor="#314292"
                      h={inputHeight}
                      fontSize={inputFontSize}
                      color={fontColor}
                      _placeholder={{
                        color: fontColor,
                      }}
                    />
                  )}
                />
                <FormErrorMessage color="white">
                  {errors?.contactPhone && errors?.contactPhone?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box flexGrow={2}>
              <FormControl isInvalid={!!errors?.contactCelPhone}>
                <Controller
                  name="contactCelPhone"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <NumberFormat
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      customInput={Input}
                      placeholder={t('USER_PROFILE.CONTACT_CELLPHONE')}
                      format={MASKS.DYNAMIC_PHONE}
                      borderWidth={2}
                      h={inputHeight}
                      fontSize={inputFontSize}
                      borderColor="#314292"
                      focusBorderColor="#314292"
                      color={fontColor}
                      _placeholder={{
                        color: fontColor,
                      }}
                    />
                  )}
                />
                <FormErrorMessage color="white">
                  {errors?.contactCelPhone && errors?.contactCelPhone?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box flexGrow={2}>
              <FormControl isInvalid={!!errors?.contactEmail}>
                <Input
                  placeholder={t('USER_PROFILE.CONTACT_EMAIL')}
                  variant="outline"
                  type="email"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  h={inputHeight}
                  fontSize={inputFontSize}
                  borderWidth={2}
                  color={fontColor}
                  _placeholder={{
                    color: fontColor,
                  }}
                  {...register('contactEmail')}
                />
                <FormErrorMessage color="white">
                  {errors?.contactEmail && errors?.contactEmail?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="14px"
            w="100%"
          >
            <Box flexGrow={1}>
              <FormControl isInvalid={!!errors?.extraInfo}>
                <Textarea
                  placeholder={t('USER_PROFILE.EXTRA_INFO')}
                  variant="outline"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  borderWidth={2}
                  color={fontColor}
                  fontSize={inputFontSize}
                  _placeholder={{
                    color: fontColor,
                  }}
                  rows={2}
                  resize="none"
                  {...register('extraInfo')}
                />
                <FormErrorMessage color="white">
                  {errors?.address && errors?.extraInfo?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>
          <Stack
            w="100%"
            spacing="14px"
            justifyContent="space-between"
            direction={['column', 'column', 'row']}
          >
            <Button onClick={onOpenContacts} color="#FFF" bgColor="#314292">
              {t('USER_PROFILE.CONTACT_NEW')}
            </Button>
            <HStack>
              <Button
                color="#FFF"
                bgColor="#314292"
                isLoading={isSubmitting}
                type="button"
                onClick={onOpenLogoUploadDialog}
              >
                {t('USER_PROFILE.LOGO_BUTTON')}
              </Button>
              <Button
                color="#FFF"
                bgColor="#314292"
                isLoading={isSubmitting}
                type="submit"
              >
                {t('USER_PROFILE.REGISTER')}
              </Button>
            </HStack>
          </Stack>
        </VStack>
      </form>
    </>
  );
}

export default Form;

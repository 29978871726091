import * as yup from 'yup';
import LANG from '../locale/pt-br';

yup.setLocale({
  string: {
    email: LANG.VALIDATION.VALID_EMAIL_MESSAGE,
  },
  mixed: {
    required: LANG.VALIDATION.GENERAL_REQUIRED,
  },
});

export default yup;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { UseToastOptions } from '@chakra-ui/react';
import axios from '../../config/axios';

import LANG from '../../locale/pt-br';
import { DriverProps, UserProps } from '../../types';
import { returnMessage } from '../../utils';

export interface CreateUserBody {
  cnpj: string;
  email: string;
  password: string;
  confirmPassword: string;
  tradeName: string;
  businnessType: 'AGENT' | 'DRIVER' | 'SHIPPER' | 'SHIPPING_COMPANY'
  bodyworkType: 'CHEST' | 'REFRIGERATOR_CHEST' | 'SIDER' | 'BUCKET' | 'GREAT_LOW' | 'BULK_CARRIER' | 'BOARD' | 'GOSSIP' | 'CONTAINER_BUG' | 'MUNK' | 'SILO' | 'TANK' | 'CAGE' | 'STORK'
  vehicleType: 'ROAD_TRAIN' | 'BITRAIN' | 'VANDERLEIA' | 'LS_CART' | 'CART' | 'BITRUCK' | 'TRUCK' | 'STUMP' | 'THREE_FOUR' | 'VLC'
  phone: string;
  role: string;
  cpf: string;
  name: string;
}

export interface CreateSocialUser {
  email: string;
  password: string;
}

export interface GoogleCreateUserBody {
  email: string;
  password: string;
  businnessType: 'AGENT' | 'DRIVER' | 'SHIPPER' | 'SHIPPING_COMPANY'
  role: string;
}

interface ReturnUserProps {
  status: UseToastOptions['status'];
  message: string | false;
  token?: string;
  code?: number;
  firstAccess?: boolean;
}
interface ReturnAvatarProps {
  status: UseToastOptions['status'];
  message: string | false;
  token?: string;
  code?: number;
  path?: string;
}

interface LoginProps {
  email: string;
  password: string;
}

export interface EvaluationModalProps{
  evaluation: object | null;
  justification: string;
}

export async function createUser(body: CreateUserBody): Promise<ReturnUserProps> {
  try {
    const token = await axios.post('/auth/signup', body);

    return {
      status: 'success',
      message: LANG.TOASTS.CREATE_USER_SUCCESS,
      token: token?.data?.access_token,
    };
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
    };
  }
}

export async function createSocialUser(body: CreateSocialUser): Promise<ReturnUserProps> {
  try {
    const token = await axios.post('/auth/signup', body);

    return {
      status: 'success',
      message: LANG.TOASTS.CREATE_USER_SUCCESS,
      token: token?.data?.access_token,
    };
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
    };
  }
}

export async function loginUser(user: LoginProps): Promise<ReturnUserProps> {
  try {
    const token = await axios.post('/auth/signin', user);

    return {
      status: 'success',
      message: LANG.TOASTS.LOGIN_SUCCESS,
      token: token?.data?.access_token || false,
      firstAccess: token?.data?.firstAccess || false,
    };
  } catch (error) {
    const err = error as AxiosError | any;
    const message = returnMessage(err?.response?.data?.message);

    return {
      status: 'error',
      message,
    };
  }
}

export async function getUserExists(data: {email: string}): Promise<ReturnUserProps> {
  try {
    const token = await axios.post('/auth/user-exists', data);

    return {
      status: 'success',
      message: LANG.TOASTS.LOGIN_SUCCESS,
      token: token?.data?.access_token || false,
    };
  } catch (error) {
    const err = error as AxiosError | any;
    const message = returnMessage(err?.response?.data?.message);

    return {
      status: 'error',
      message,
      code: err?.response?.status,
    };
  }
}

export async function getMe(userToken: string) {
  try {
    const userData = await axios.get('users/me', {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return userData?.data;
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      code: error?.response?.status,
    };
  }
}

export async function editUser(
  body: UserProps,
  userToken: string,
): Promise<ReturnUserProps> {
  try {
    await axios.patch('users', body, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.TOASTS.EDIT_USER_SUCCESS,
    };
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
    };
  }
}
export async function editUserDriver(
  body: DriverProps,
  userToken: string,
): Promise<ReturnUserProps> {
  try {
    await axios.patch('users', body, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.TOASTS.EDIT_USER_SUCCESS,
    };
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
    };
  }
}

export async function editAvatarFile(
  file: {
    avatarFile: File[]
  },
  userToken: string,
): Promise<ReturnAvatarProps> {
  try {
    const formData = new FormData();
    formData.append('file', file.avatarFile[0]);

    const avatar = await axios.patch('uploads/avatar', formData, {
      headers: {
        authorization: `Bearer ${userToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return {
      status: 'success',
      message: LANG.TOASTS.EDIT_USER_AVATAR_SUCCESS,
      path: avatar.data || '',
    };
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      path: '',
    };
  }
}

export async function getIsSubscribed(userToken: string) {
  try {
    const userData = await axios.get('users/subscribed', {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return userData?.data;
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      code: error?.response?.status,
    };
  }
}

export async function activateUser(activationToken: string | undefined) {
  try {
    const hasActivated = await axios.post('/auth/validate', { activationToken });

    return hasActivated || false;
  } catch (error) {
    console.error(error);
  }

  return false;
}

export async function setEvaluated(
  body: EvaluationModalProps,
  userToken: string,
): Promise<ReturnUserProps> {
  try {
    await axios.post('users/evaluated', body, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.USER_CARGOS_ACTIONS.EVALUATE_TITLE_SUCESSO,
    };
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      code: error?.response?.status,
    };
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import axios from '../../config/axios';

import LANG from '../../locale/pt-br';

import { returnMessage } from '../../utils';
import { ReturnMessageProps, FeedbackBody } from '../../types.d';

export async function createFeedback(body: FeedbackBody):
Promise<ReturnMessageProps> {
  try {
    await axios.post('/feedbacks', body);

    return {
      status: 'success',
      message: LANG.TOASTS.CONTACT_RESPONSE_SUCCESS,
    };
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
    };
  }
}

export async function sendFeedbackGreetings(email: string, name: string):
Promise<ReturnMessageProps> {
  try {
    await axios.post('/mail/send-feedback-email', { email, name });

    return {
      status: 'success',
      message: LANG.TOASTS.CONTACT_RESPONSE_SUCCESS,
    };
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
    };
  }
}

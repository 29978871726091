import React from 'react';
import { VStack } from '@chakra-ui/react';

import { homeGradient } from '../../constants/backgroundStyle';
import CargoSearch from '../CargoSearch';
import ContactUs from '../ContactUs';
import HowItWorks from '../HowItWorks';
import WhoIs from '../WhoIs';
import Plans from '../Plans';
import Main from './Main';

function Home() {
  return (
    <VStack style={homeGradient} spacing={16}>
      <Main />
      <CargoSearch transparent firstPage filter={false} />
      <HowItWorks transparent firstPage />
      <Plans transparent />
      <WhoIs transparent />
      <ContactUs transparent />
    </VStack>
  );
}

export default Home;

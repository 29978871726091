/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AiOutlineUnlock, AiOutlineUser } from 'react-icons/ai';
import { useCookies } from 'react-cookie';
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FaFacebook } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import RegisterTypeModal from './RegisterTypeModal';

import { getUserExists, loginUser } from '../../../providers/user';
import schema from './schema';

interface FormProps {
  email: string;
  password: string;
  keepConnected: boolean;
}

interface LoginFormProps {
  onChangeTab: any;
}

function LoginForm({ onChangeTab }: LoginFormProps) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormProps>({
    resolver: schema,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const toast = useToast();
  const [, setCookie] = useCookies(['token']);
  const [email, setEmail] = useState('');

  const handleFacebookLoginAuth = async (response: ReactFacebookLoginInfo) => {
    if (typeof response.email !== 'undefined') {
      const userExists = await getUserExists({ email: response.email });

      if (userExists.code === 403) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setEmail(response.email);
        onOpen();
      } else {
        toast({
          description: userExists.message,
          status: userExists.status,
          duration: 9000,
          isClosable: true,
        });

        if (userExists.token) {
          setCookie('token', userExists.token, {
            path: '/',
          });

          if (!userExists.firstAccess) {
            navigate('/', { replace: true });
          } else {
            navigate('/user-profile', { replace: true });
          }
        }
      }
    } else {
      toast({
        description: t('TOASTS.ERROR.EMAIL_NOT_VALID'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleGoogleLoginAuth = async (credential: string | undefined) => {
    if (typeof credential !== 'undefined') {
      const credentialDecode = jwtDecode(credential);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const userExists = await getUserExists({ email: credentialDecode.email });

      if (userExists.code === 403) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setEmail(credentialDecode.email);
        onOpen();
      } else {
        toast({
          description: userExists.message,
          status: userExists.status,
          duration: 9000,
          isClosable: true,
        });

        if (userExists.token) {
          setCookie('token', userExists.token, {
            path: '/',
          });

          if (!userExists.firstAccess) {
            navigate('/', { replace: true });
          } else {
            navigate('/user-profile', { replace: true });
          }
        }
      }
    } else {
      toast({
        description: t('TOASTS.ERROR.EMAIL_NOT_VALID'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    const loginData = await loginUser(data);

    toast({
      description: loginData.message,
      status: loginData.status,
      duration: 9000,
      isClosable: true,
    });

    if (loginData.token) {
      setCookie('token', loginData.token, {
        path: '/',
      });

      if (!loginData.firstAccess) {
        navigate('/', { replace: true });
      } else {
        navigate('/user-profile', { replace: true });
      }
    }
  };

  return (
    <>
      <RegisterTypeModal isOpen={isOpen} onClose={onClose} email={email} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
          <FormControl isInvalid={!!errors?.email}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <AiOutlineUser color="#FFF" size="24" />
              </InputLeftElement>
              <Input
                type="email"
                width="full"
                borderColor="white"
                placeholder={t('SIGN_IN.EMAIL')}
                color="white"
                opacity={0.6}
                variant="flushed"
                _placeholder={{
                  color: 'white',
                }}
                {...register('email')}
              />
            </InputGroup>
            <FormErrorMessage color="white">
              {errors?.email && errors?.email?.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors?.password}>
            <InputGroup>
              <InputLeftElement>
                <AiOutlineUnlock color="#FFF" size="24" />
              </InputLeftElement>
              <Input
                type="password"
                width="full"
                placeholder={t('SIGN_IN.PASSWORD')}
                borderColor="white"
                variant="flushed"
                color="white"
                opacity={0.6}
                _placeholder={{ color: 'white' }}
                {...register('password')}
              />
            </InputGroup>
            <FormErrorMessage color="white">
              {errors?.password && errors?.password?.message}
            </FormErrorMessage>
          </FormControl>

          <Stack
            direction={['column', 'column', 'row']}
            width="full"
            justifyContent="space-between"
            pt={4}
          >
            <Checkbox color="#FFF" defaultChecked>
              {t('SIGN_IN.KEEP_CONNECTED')}
            </Checkbox>
            <Link color="#FFF" as={RouterLink} to="/forgot-password">
              {t('SIGN_IN.FORGOT_PASSWORD')}
            </Link>
          </Stack>

          <Button
            type="submit"
            width="full"
            size="lg"
            py={30}
            isLoading={isSubmitting}
            bgColor="#314292"
          >
            <Text color="#FFF">{t('SIGN_IN.ENTER')}</Text>
          </Button>

          <Text color="#FFF">{t('SIGN_IN.DONT_KNOW_US')}</Text>
          <Button
            px={1}
            variant="ghost"
            onClick={onChangeTab}
            color="#314292"
            borderRadius="xl"
            fontSize="light"
          >
            {t('SIGN_IN.CREATE_ACC')}
          </Button>

          <Center width="full">
            <Box flexGrow={1} borderWidth={1} borderColor="#314292" />
            <Text px={4} color="#FFF">
              {t('SIGN_IN.OR')}
            </Text>
            <Box flexGrow={1} borderWidth={1} borderColor="#314292" />
          </Center>

          <Stack direction="column" justifyContent="space-between">
            <GoogleOAuthProvider clientId="518976697664-hjalcrp7gt8qpmhbhhjfken69fq2nu9j.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={(credentialResponse: CredentialResponse) => {
                  handleGoogleLoginAuth(credentialResponse.credential);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </GoogleOAuthProvider>

            <FacebookLogin
              appId="230134999942390"
              autoLoad={false}
              fields="email"
              callback={handleFacebookLoginAuth}
              render={(renderProps: any) => (
                <Button
                  onClick={renderProps.onClick}
                  width="full"
                  size="md"
                  py={4}
                  bgColor="#314292"
                  leftIcon={<FaFacebook />}
                  color="#FFF"
                  fontSize="14px"
                  fontWeight="light"
                >
                  {t('SIGN_IN.FACEBOOK_LOGIN')}
                </Button>
              )}
            />
          </Stack>
        </VStack>
      </form>
    </>
  );
}

export default LoginForm;

import { ZipCode } from '../../types.d';
import axios from '../../config/axios';

export async function getZipCode(zipcode: string):Promise<ZipCode> {
  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

    return {
      cep: data.cep || '',
      logradouro: data.logradouro || '',
      complemento: data.complemento || '',
      bairro: data.bairro || '',
      localidade: data.localidade || '',
      uf: data.uf || '',
    };
  } catch (error) {
    console.error(error);
  }

  return {
    cep: '',
    logradouro: '',
    complemento: '',
    bairro: '',
    localidade: '',
    uf: '',
  };
}

import axios from '../../config/axios';

export async function getCitiesByUfId(id: number | string | undefined) {
  try {
    if (!id) return [];

    const cities = await axios.get(`/cities/${id}`);

    return cities.data || [];
  } catch (error) {
    console.error(error);
  }

  return [];
}

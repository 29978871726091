export default {
  MENU: {
    HOME: 'Página inicial',
    LOGIN: 'Entrar / cadastrar',
    SEARCH: 'Buscar Fretes',
    HOW_IT_WORKS: 'Como funciona ?',
    CONTACT_US: 'Fale conosco',
    MY_CARGOS: 'Meus fretes',
    MY_PROFILE: 'Meu perfil',
    MY_SUB: 'Minha assinatura',
    PRIVACY_POLICY: 'LGPD',
    LOGOUT: 'Sair do sistema',
  },

  EXIT_DIALOG: {
    TITLE: 'Aviso',
    TEXT: 'Você deseja mesmo sair do sistema?',
  },

  USER: {
    CANT_CREATE_CARGO_BECAUSE_IS_NOT_ACTIVE:
      'Você não pode cadastrar nenhum frete porque ainda não atualizou seus dados, clique em Meu Perfil e complete seu cadastro!',
    ACTIVATION:
      'Muito obrigado! Seu endereço de email foi confirmado com sucesso!',
    ACTIVATION_BUTTON: 'Clique aqui para começar a utilizar a plataforma!',
    ACTIVATION_TITLE: 'Confirmação de email',
  },

  HOME: {
    LOGO_TEXT: 'SEU FRETE COMEÇA AQUI',
    SEARCH_BUTTON: 'ENCONTRE SEU FRETE',
    SIGN_IN_BUTTON: 'ENTRAR',
  },

  CONTACT: {
    TITLE: 'FALE CONOSCO',
    NAME: 'Nome: ',
    EMAIL: 'E-mail:',
    MESSAGE: 'Mensagem: ',
    SEND: 'ENVIAR',
  },

  SIGN_IN: {
    TITLE: 'Entrar',
    EMAIL: 'Entre com seu E-mail',
    PASSWORD: 'Entre com sua Senha',
    KEEP_CONNECTED: 'Manter conectado',
    FORGOT_PASSWORD: 'Esqueceu sua Senha?',
    CREATE_ACC: 'Crie sua conta',
    DONT_KNOW_US: 'Não conhece a plataforma ?',
    ENTER: 'ENTRAR',
    OR: 'Ou',
  },

  SIGN_UP: {
    TITLE: 'Cadastrar',
    CNPJ: 'CNPJ *',
    CPF: 'CPF *',
    TRADE_NAME: 'Nome Fantasia *',
    NAME: 'Nome *',
    COMPANY_TYPE: 'Tipo da Empresa *',
    BODYWORK_TYPE: 'Tipo de Carroceria',
    VEHICLE_TYPE: 'Tipo de Veiculo',
    EMAIL: 'E-mail *',
    PASSWORD: 'Senha *',
    PHONE: 'Telefone *',
    CONFIRM_PASSWORD: 'Confirme sua senha *',
    ALREADY_REGISTERED: 'Ja é cadastrado na nossa plataforma?',
    CLICK_TO_SIGN_IN: 'Clique aqui para entrar',
    REGISTER: 'CADASTRAR',
    REGISTER_TYPE: {
      COMPANY: 'Empresa',
      DRIVER: 'Motorista',
    },
    LGPD_CHECKBOX: 'Eu concordo com os Termos de Uso e da LGPD listados no site.',
  },

  FORGOT_PASSWORD: {
    TITLE: 'Esqueceu sua Senha?',
    CNPJ: 'CNPJ *',
    EMAIL: 'E-mail *',
    RECOVER_PASSWORD: 'RECUPERAR SENHA',
  },

  PLANS: {
    TITLE: 'PLANOS',
    SIGN_NOW: 'Assine Já',
    PIX: 'PIX',
    GENERAL_TEXT:
      'O MELHOR PLANO DISPONÍVEL! COMECE A CADASTRAR SEUS FRETES NA PLATAFORMA JÁ!',
    PIX_DESC_2: 'Acesso em até 6 Horas',
    CARD: 'CARTÃO',
    CARD_DESC: 'Tenha acesso imediato à plataforma! <br /> <strong>Teste grátis por 30 dias.</strong>',
    BOLETO: 'BOLETO',
    BOLETO_DESC: 'Acesse a plataforma em até dois dias.<br /><br/>',
  },

  HOW_IT_WORKS: {
    REGISTER: 'CADASTRE-SE',
    FOR: 'PARA',
    COMPANIES: 'EMPRESAS',
    NEW_WAY_TO_CONNECT_COMPANY: 'UMA NOVA PLATAFORMA DE FRETES!',
    YOUR_PRODUCT:
      'Sua mercadoria transportada para qualquer lugar do Brasil de maneira segura.',
    REGISTER_CARGO: 'Cadastre a sua carga de forma rápida e simples.',
    FIND_DRIVER: 'Encontre o caminhão ideal para a sua carga sem esforço.',
    SEND_ANYWHERE: 'Envia para qualquer lugar do Brasil.',
    EFICIENCY_AGILITY: 'Empresa, alcance Eficácia & Agilidade',
    TRUCK_DRIVERS: 'CAMINHONEIROS',
    NEW_WAY_TO_CONNECT_TRUCK:
      'Uma nova forma de conectar você com as melhores empresas do Brasil!',
    FIND_EVERYWHERE:
      'De onde você estiver, encontre os melhores fretes com facilidade e agilidade.',
    FIND_YOUR_CARGO: 'Motorista, encontre sua Carga',
    COMPATIBLE_TRUCK: 'Encontre uma carga compatível com o seu caminhão.',
    CONNECT_WITH_CARGO: 'Conectamos você a empresa que disponibiliza o frete',
    FAST_SECURE_CARGO: 'Solicite o frete de forma rápida e segura.',
  },

  SEARCH: {
    FIRST_PAGE_TITLE: 'ÚLTIMOS FRETES CADASTRADOS',
    TITLE: 'FRETES E CARGAS',
    FILTER_TITLE: 'FILTROS',
    ORIGINS: 'Origem',
    DESTINY: 'Destino',
    CITY: 'Cidade',
    UF: 'Estado',
    ADVANCED: 'Avançado',
    VEHICLE: 'Veículo',
    BODYWORK: 'Carroceria',
    FILTER: 'FILTRAR',
    CLEAR: 'LIMPAR',

    CARGO_ORIGIN: 'Origem',
    CARGO_DESTINY: 'Destino',
    CARGO_VEHICLE: 'Veículo',
    CARGO_BODYWORK: 'Carroceria',
    CARGO_WEIGHT: 'Peso',
    CARGO_PRICE: 'Valor',

    FIND_OUT_MORE: 'Ver frete',

    WHATSAPP_MSG:
      'Olá, encontrei sua carga na FRETEcia e gostaria de combinar este frete!',

    FILTER_SELECT: 'Selecione',

    NO_RESULTS: 'Nenhum frete disponível para as localidades selecionadas. <br /> Faça sua busca novamente',
  },

  VALIDATION: {
    GENERAL_REQUIRED: 'Este campo é obrigatório',
    VALID_EMAIL_MESSAGE: 'Digite um email válido',
    EMAIL_MESSAGE: 'Digite seu email',
    PASSWORD_MESSAGE: 'Digite sua senha',
    PASSWORD_MATCH_MESSAGE: 'A senha e a confirmação de senha devem as mesmas',
    PHONE_NUMBER: 'Não é um núm. de telefone válido',
    MUST_ACCEPT_USER_TERMS: 'Você precisa aceitar os termos de uso',
    PASSWORD_MATCHES: 'A senha e confirmação de senha devem ser iguais!',
    INVALID_CNPJ: 'CNPJ inválido',
    INVALID_CPF: 'CPF inválido',
    INVALID_ZIPCODE: 'CEP inválido',

    VALID_IMAGE_FORMAT: 'Por favor, insira uma imagem válida',
    VALID_IMAGE_SIZE: 'Por favor, insira uma imagem de até 5MB',
  },

  USER_PROFILE: {
    TITLE: 'DADOS PESSOAIS',

    NAME: 'Nome',
    CNPJ: 'CNPJ *',
    TRADE_NAME: 'Nome fantasia *',
    COMPANY_NAME: 'Razão social *',
    COMPANY_TYPE: 'Tipo de empresa *',
    EMAIL: 'Email *',
    CONTACT_EMAIL: 'Email de contato',
    ZIPCODE: 'CEP',
    NUMBER: 'Número',
    ADDRESS: 'Endereço',
    MORE_INFO: 'Complemento',
    NEIGHBORHOOD: 'Bairro',
    CITY: 'Cidade',
    UF: 'Estado',
    EXTRA_INFO: 'Observações',
    LOGO_BUTTON: 'Enviar logo',
    LOGO_TITLE: 'Inserir meu logo',
    LOGO_DESC: 'Envie sua imagem selecionando abaixo:',

    CONTACT_TITLE: 'Contatos',
    CONTACT_NAME: 'Nome *',
    CONTACT_PHONE: 'Telefone',
    CONTACT_CELLPHONE: 'Celular',
    CONTACT_DESCRIPTION: 'Quem é este contato?',
    CONTACT_WHATSAPP: 'Whatsapp?',
    CONTACT_NEW: 'Novo contato',

    EDIT: 'ALTERAR',
    REMOVE: 'REMOVER',
    REGISTER: 'Salvar',

    NEW_CONTACT_TITLE: 'Criar novo contato',
    NEW_CONTACT_NAME: 'Nome',
    NEW_CONTACT_PHONE: 'Telefone',
    NEW_CONTACT_DESC: 'Descrição',
    NEW_CONTACT_REGISTER: 'Cadastrar',
    NEW_CONTACT_CANCEL: 'Cancelar',
    NEW_CONTACT_WHATSAPP: 'Este contato é Whatsapp?',
  },

  CARGO_MODAL: {
    TITLE: 'INFORMAÇÕES COMPLETAS DO FRETE',
    DATE: 'DATA DO FRETE',
    VEHICLES: 'VEÍCULOS',
    BODYWORKS: 'CARROCERIAS',
    AGENT: 'AGENCIADOR',
    COMPLEMENTARY: 'COMPLEMENTO',
    TRACER: 'RASTREADOR',
    MORE_INFO: 'OBSERVAÇÃO',
    CONTACTS: 'CONTATOS',
    PRICE: 'VALOR',
  },

  USER_CARGOS: {
    TITLE: 'CADASTRE SEU FRETE',
    NEW_BUTTON: 'NOVO FRETE',
    UPDATE_BUTTON: 'EDITAR',
    FINISH_BUTTON: 'FINALIZAR',
    RENEW_BUTTON: 'RENOVAR',
    DELETE_BUTTON: 'EXCLUIR',
    STATUS: 'STATUS',
    STATUS_ACTIVE: 'ATIVO',
    STATUS_INACTIVE: 'VENCIDO',
    STATUS_FINISHED: 'FINALIZADO',
    PRODUCT: 'Produto',
    VEHICLE: 'Veículo',
  },

  EDIT_CARGO_MODAL: {
    NEW_CARGO_TITLE: 'Novo frete',
    EDIT_CARGO_TITLE: 'Editar frete',
    PRODUCT: 'Produto',
    WEIGHT: 'Peso (em KG)',
    PRODUCT_TYPE: 'Tipo de carga',
    PRICE: 'Valor (em R$)',
    DATE: 'Data do frete',
    NOTES: 'Observações',
    OTHERINFO: '',
    TRACING: 'Rastreamento',
    AGENCING: 'Agenciador',
    COMPLEMENTARY: 'Complementar',
    VEHICLES: 'Veículos',
    BODYWORKS: 'Carrocerias',

    FROM_UF: 'Estado (origem)',
    FROM_CITY: 'Cidade (origem)',
    TO_UF: 'Estado (destino)',
    TO_CITY: 'Cidade (destino)',

    REGISTER: 'Salvar',
    CANCEL: 'Cancelar',
  },

  TOASTS: {
    CREATE_USER_TITLE: 'Criação de contas',
    CREATE_USER_SUCCESS:
      'Usuário criado com sucesso! Um email será enviado para ativar sua conta!',
    LOGIN_SUCCESS: 'Bem vindo! Redirecionando...',
    EDIT_USER_SUCCESS: 'Dados editados com sucesso!',
    EDIT_USER_AVATAR_SUCCESS: 'Logo cadastrado com sucesso!',

    CREATE_CONTACT_TITLE: 'Criação ou edição de contato',
    CREATE_CONTACT_SUCCESS: 'Contato cadastrado com sucesso!',
    EDIT_CONTACT_SUCCESS: 'Contato atualizado com sucesso!',
    REMOVE_CONTACT_TITLE: 'Remover contato contato',
    REMOVE_CONTACT_QUESTION:
      'Tem certeza que deseja remover este contato? Esta ação não pode ser desfeita!',
    REMOVE_CONTACT_SUCCESS: 'Contato remvovido com sucesso',

    CONTACT_RESPONSE_SUCCESS:
      'Feedback enviado com sucesso! Fique de olho em sua caixa de e-mails para nossa resposta!',

    CREATE_PAYMENT_SUCCESS: {
      B: 'Seu boleto foi gerado com sucesso! Fique atento as datas de pagamento',
      P: 'Código PIX gerado com sucesso! Por favor realize o pagamento dentro dos próximos 30 minutos',
      C: 'Seu pagamento foi gerado com sucesso!',
    },

    GET_PAYMENTS_SUCCESS: 'Pagamentos carregados com sucesso!',

    ERROR: {
      CREDENTIAL_IS_TAKEN: 'Usuário já cadastrado!',
      INVALID_USER: 'Usuário ou senha inválidos!',
      EMAIL_NOT_EXISTS: 'O Email não está cadastrado',
      EMAIL_NOT_VALID: 'O Email não é Válido!',
      GENERIC_ERROR: 'Erro - entre em contato com o suporte!',
      INACTIVE_EMAIL:
        'Por favor, entre em seu endereço de email e faça a confirmação de conta',
    },

    COMMONS: {
      CANCEL: 'Cancelar',
      REMOVE: 'Deletar',
      CONFIRM: 'Confirmar',
    },
  },

  USER_CARGOS_ACTIONS: {
    RENEW_TITLE: 'Renovar frete',
    RENEW_DESC: 'Deseja RENOVAR seu frete por mais cinco dias?',
    RENEW_SUCCESS: 'Data renovada com sucesso!',

    DELETE_TITLE: 'Remover frete',
    DELETE_DESC: 'Deseja REMOVER seu frete?',
    DELETE_SUCCESS: 'Frete removido com sucesso!',

    FINISH_TITLE: 'Finalizar frete',
    FINISH_DESC: 'Deseja FINALIZAR seu frete?',
    FINISH_SUCCESS: 'Frete finalizado com sucesso!',

    CREATE_CARGO_TITLE: 'Criação de frete',
    CREATE_CARGO_SUCCESS: 'Frete criado com sucesso!',

    EDIT_CARGO_TITLE: 'Edição de frete',
    EDIT_CARGO_SUCCESS: 'Frete criado com sucesso!',

    EVALUATE_TITLE_SUCESSO: 'Sucesso ao Avaliar a Plataforma',
  },

  USER_SUBSCRIPTION: {
    TITLE: 'MINHA ASSINATURA',
    NOT_SUBSCRIBED:
      'Você ainda não está assinando nossa plataforma - veja as opções abaixo',
    SUBSCRIPTION_TYPE: 'Plano',
    CARD_NAME: 'Nome do Titular do cartão',
    CARD_NUMBER: 'Número do cartão de crédito',
    CARD_CVV: 'CVV',
    CARD_EXPIRATION_DATE: 'Venc. cartão (Ex. 06/2030)',
    CARD_CPF: 'CPF do Titular do Cartão',
    SELECT_PLAN: 'Selecione o plano mais adequado:',

    IS_SUBSCRIBED_TITLE: 'Parabéns! Você já está assinando a plataforma!',
    SUBSCRIPTION_VALID_UNTIL:
      'Sua assinatura, renovada automaticamente, é válida até: ',

    BOLETO_AWAITING_TITLE:
      'Obrigado pelo interesse! Agora é só fazer o pagamento, seguindo as instruções abaixo!',
    BOLETO_BARCODE: 'Linha digitável',
    BOLETO_DUE_DATE: 'Prazo para pagamento',
    BOLETO_VIEW: 'Clique aqui para visualizar o boleto',

    CREDIT_CARD_SUCESS: 'Muito obrigado pela sua assinatura!',
    CREDIT_CARD_SUCCESS_SUB: 'Agora é só aguardar a aprovação para usar os benefícios',

    CREATE_PAYMENT_ERROR_TITLE: 'Algo não deu certo, por favor tente novamente.',
    CREATE_PAYMENT_RELOAD: 'Tentar novamente',
  },

  WHO_IS: {
    TITLE: 'QUEM SOMOS',
  },

  COMMONS: {
    YES: 'SIM',
    NO: 'NÃO',
    CANCEL: 'Cancelar',
    REMOVE: 'Deletar',
    CONFIRM: 'Confirmar',
  },

  FEEDBACK: {
    CONTACT_RESPONSE_TITLE: 'Fale conosco',
  },

  PRIVACY_POLICY: {
    TITLE: 'Políticas de Privacidade e LGPD',
  },

  USER_TERMS: {
    TITLE: 'TERMOS DE USO DA PLATAFORMA',
  },

  FOOTER: {
    COMPANY: 'Empresa',
    ABOUT_US: 'Sobre nós',
    CONTACT_US: 'Fale conosco',
    BLOG: 'Blog',
    HOW_IT_WORKS: 'Como funciona',

    LEGAL: 'Dpto. Jurídico',
    LGPD: 'LGPD e política de privacidade',
    USER_TERMS: 'Termos de uso',

    SUBSCRIBER: 'Assinante',
    SIGN_IN: 'Acesse já',
    SIGN_UP: 'Cadastre-se',
    MY_CARGOS: 'Meus fretes',
    MY_SUBSCRIPTION: 'Minha assinatura',

    DOWNLOAD_APP: 'Baixe nossos Apps',
    ALL_RIGHTS_RESERVED: 'Todos os direitos reservados',
  },

  LOGOUT: {
    TITLE: 'Você saiu do sistema com sucesso, será redirecionado em instantes!',
  },
};

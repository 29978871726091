import * as React from 'react';
import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react';
import {
  HashRouter, Route, Routes,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/ForgotPassword';
import Plans from './pages/Plans';
import HowItWorks from './pages/HowItWorks';
import CargoSearch from './pages/CargoSearch';
import UserProfile from './pages/UserProfile';
import UserCargos from './pages/UserCargos';
import WhoIs from './pages/WhoIs';
import SimpleSidebar from './components/Sidebar';
import UserSubscription from './pages/UserSubscription';
import Activation from './pages/Activate';
import PrivacyPolicy from './pages/PrivacyPolicy';

import { UserDataProvider } from './contexts';
import theme from './theme';
import UserTerms from './pages/UserTerms';
import Footer from './components/Footer';
import Logout from './pages/Logout';

import enTranslation from './locale/en-us/index.json';
import ptBrTranslation from './locale/pt-br/index.json';

export default function App() {
  i18n.use(initReactI18next).init({
    resources: {
      ptbr: {
        translation: ptBrTranslation,
      },
      en: {
        translation: enTranslation,
      },
    },
    lng: 'ptbr', // Set the default language
    fallbackLng: 'ptbr', // Fallback language if a translation is missing
    interpolation: {
      escapeValue: false, // React already escapes content
    },
  });

  return (
    <CookiesProvider>
      <HashRouter>
        <ChakraProvider theme={theme}>
          <ColorModeProvider value="dark">
            <UserDataProvider>
              <SimpleSidebar>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/sign-in" element={<SignIn />} />
                  <Route path="/plans" element={<Plans />} />
                  <Route path="/how-it-works" element={<HowItWorks />} />
                  <Route path="/search" element={<CargoSearch />} />
                  <Route path="/user-profile" element={<UserProfile />} />
                  <Route path="/user-cargos" element={<UserCargos />} />
                  <Route path="/user-subscription" element={<UserSubscription />} />
                  <Route path="/who-is" element={<WhoIs />} />
                  <Route path="/activate/:activationToken" element={<Activation />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/user-terms" element={<UserTerms />} />
                  <Route path="/logout" element={<Logout />} />
                </Routes>
                <Footer />
              </SimpleSidebar>
            </UserDataProvider>
          </ColorModeProvider>
        </ChakraProvider>
      </HashRouter>
    </CookiesProvider>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { removeContact } from '../../../providers/contacts';

interface RemoveContactModalProps {
  isOpen: boolean;
  onClose: any;
  contactId: number;
}

function RemoveContactModal({
  isOpen,
  onClose,
  contactId,
}: RemoveContactModalProps) {
  const { t } = useTranslation();
  const [cookies] = useCookies();
  const toast = useToast();
  const ref = useRef<any>();

  const onRemoveContact = useCallback(async () => {
    try {
      if (contactId && cookies.token) {
        const hasRemoved = await removeContact(contactId, cookies.token);

        toast({
          description: hasRemoved.message,
          status: hasRemoved.status,
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
    }

    onClose();
  }, [contactId, cookies.token, onClose, toast]);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={ref} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader color="#FFF" fontSize="lg" fontWeight="bold">
            {t('TOASTS.REMOVE_CONTACT_TITLE')}
          </AlertDialogHeader>

          <AlertDialogBody color="#FFF">
            {t('TOASTS.REMOVE_CONTACT_QUESTION')}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button color="#FFF" ref={ref} onClick={onClose}>
              {t('TOASTS.COMMONS.CANCEL')}
            </Button>
            <Button color="#FFF" colorScheme="red" onClick={onRemoveContact} ml={3}>
              {t('TOASTS.COMMONS.REMOVE')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default RemoveContactModal;

import { AxiosError } from 'axios';
import {
  PaymentType,
  ReturnMessageProps,
} from '../../types.d';
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from '../../config/axios';

import { returnMessage } from '../../utils';
import LANG from '../../locale/pt-br';
import { PayPalResponse } from '../../components/PaypalButton';

interface PaymentBody {
  cardName?: string;
  cardNumber?: number;
  cardCVV?: number;
  cardExpirationDate?: string;
  cardOwnerCPF?: number;
  type: PaymentType;
}

export async function createPayment(
  body: PaymentBody,
  userToken: string,
): Promise<ReturnMessageProps> {
  try {
    const { data = '' } = await axios.post('/payments', body, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.TOASTS.CREATE_PAYMENT_SUCCESS[body.type],
      data,
    };
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
    };
  }
}

export async function createPaymentWithPaypal(userToken: string, paypalResponse: PayPalResponse) {
  try {
    const { data = '' } = await axios.post('/payments/paypal', paypalResponse, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.TOASTS.CREATE_PAYMENT_SUCCESS.C,
      data,
    };
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
    };
  }
}

export async function getPayments(
  userToken: string,
): Promise<ReturnMessageProps> {
  try {
    const { data = [] } = await axios.get('/payments', {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.TOASTS.GET_PAYMENTS_SUCCESS,
      data,
    };
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
    };
  }
}

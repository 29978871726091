import React from 'react';
import {
  Box,
  Center,
  Grid,
  Image,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Title from '../../components/Title';

import ContactUsImg from '../../assets/contact-us.png';
import freteCiaLogo from '../../assets/fretecia-logo.svg';

import Form from './Form';
import { insideGradient, outsideSpinBox } from '../../constants/backgroundStyle';

function ForgotPassword() {
  const { t } = useTranslation();
  const selectedTabStyle = {
    color: 'white',
    borderTopWidth: 3,
    borderTopColor: '#314292',
  };

  return (
    <Box style={outsideSpinBox}>
      <Grid
        style={insideGradient}
        pb={12}
        borderWidth={1}
        borderColor="white"
        borderLeftRadius="xl"
      >
        <Stack
          px={16}
          flexDir={{ sm: 'column-reverse', md: 'column-reverse', lg: 'row' }}
          alignItems={{ sm: 'center', md: 'center', lg: 'flex-start' }}
          justifyContent="space-around"
        >
          <Box mt={56}>
            <Image src={ContactUsImg} boxSize="xl" />
          </Box>
          <Box width={{ sm: 'full', md: 'full', lg: '45%' }}>
            <Center mt={8} mb={12}>
              <Image src={freteCiaLogo} alt="fretecia-logo" height={16} />
            </Center>
            <Tabs>
              <Center pt={2} pb={8}>
                <TabList>
                  <Tab _selected={selectedTabStyle}>
                    <Title size="sm" text={t('FORGOT_PASSWORD.TITLE')} />
                  </Tab>
                </TabList>
              </Center>

              <TabPanels>
                <TabPanel>
                  <Form />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Stack>
      </Grid>
    </Box>
  );
}

export default ForgotPassword;

import SpinImg from '../assets/spin.png';

export const insideGradient = {
  background: 'transparent linear-gradient(122deg, #B0D4F8 0%, #7181B9 100%) 0% 0% no-repeat padding-box',
  opacity: 0.99,
  backdropFilter: 'blur(9px)',
  WebkitBackdropFilter: 'blur(9px)',
};

export const outsideSpinBox = {
  backgroundImage: `url(${SpinImg})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

export const modalGradient = {
  background:
        'transparent linear-gradient(122deg, #7EB9EF 0%, #6772A9 100%) 0% 0% no-repeat padding-box',
  opacity: 0.99,
  backdropFilter: 'blur(9px)',
  WebkitBackdropFilter: 'blur(9px)',
};

export const menuGradient = {
  background:
        'transparent linear-gradient(180deg, #49AAFF 0%, #344F9F 100%) 0% 0% no-repeat padding-box',
  opacity: 0.99,
  backdropFilter: 'blur(9px)',
  WebkitBackdropFilter: 'blur(9px)',
};
export const menuGradient2 = {
  background:
        'transparent linear-gradient(120deg, #49AAFF 0%, #344F9F 100%) 0% 0% no-repeat padding-box',
  opacity: 0.99,
  backdropFilter: 'blur(9px)',
  WebkitBackdropFilter: 'blur(9px)',
};

export const glassGradientMenu = {
  background: 'transparent linear-gradient(143deg, #FFFFFF66 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box',
};

export const homeGradient = {
  background:
        'transparent linear-gradient(180deg, #80BBF7 0%, #6772A9 100%) 0% 0% no-repeat padding-box',
  opacity: 0.99,
  backdropFilter: 'blur(9px)',
  WebkitBackdropFilter: 'blur(9px)',
};

export const cargoCardGlassGradient = {
  background: 'transparent linear-gradient(122deg, #FFFFFF66 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box',
  opacity: 0.96,
  backdropFilter: 'blur(10px)',
  WebkitBackdropFilter: 'blur(10px)',
};

import { useTranslation } from 'react-i18next';
import { Center, Stack, Text } from '@chakra-ui/react';

import truck2Img from '../../../assets/truck2.png';
import cargoImg from '../../../assets/cargo.png';
import connectImg from '../../../assets/connect.png';
import searchImg from '../../../assets/search.png';

import { renderImage, renderOverlay } from '../utils';

function FindYourCargo() {
  const { t } = useTranslation();

  return (
    <Stack direction={['column', 'column', 'row']} px={[0, 0, 32]}>
      <Stack flexDirection="column" spacing={2} width="100%">
        <Stack>
          <Center style={renderImage(truck2Img)}>
            <Center style={renderOverlay(false)}>
              <Text
                fontSize="24px"
                fontWeight="semibold"
                textAlign="center"
                p={2}
                color="#FFF"
              >
                {t('HOW_IT_WORKS.FIND_YOUR_CARGO')}
              </Text>
            </Center>
          </Center>
        </Stack>
        <Stack spacing={2} direction={['column', 'column', 'row']}>
          <Center
            width={{ sm: '100%', md: '100%', lg: '33%' }}
            style={renderImage(cargoImg)}
          >
            <Center style={renderOverlay(false)}>
              <Text
                fontSize="18px"
                fontWeight="semibold"
                textAlign="center"
                p={2}
                color="#FFF"
              >
                {t('HOW_IT_WORKS.COMPATIBLE_TRUCK')}
              </Text>
            </Center>
          </Center>
          <Center
            width={{ sm: '100%', md: '100%', lg: '33%' }}
            style={renderImage(connectImg)}
          >
            <Center style={renderOverlay(false)}>
              <Text
                fontSize="18px"
                fontWeight="semibold"
                textAlign="center"
                p={2}
                color="#FFF"
              >
                {t('HOW_IT_WORKS.CONNECT_WITH_CARGO')}
              </Text>
            </Center>
          </Center>
          <Center
            width={{ sm: '100%', md: '100%', lg: '33%' }}
            height={160}
            style={renderImage(searchImg)}
          >
            <Center style={renderOverlay(false)}>
              <Text
                fontSize="18px"
                fontWeight="semibold"
                textAlign="center"
                p={2}
                color="#FFF"
              >
                {t('HOW_IT_WORKS.FAST_SECURE_CARGO')}
              </Text>
            </Center>
          </Center>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default FindYourCargo;

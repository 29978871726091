import React, { useState } from 'react';
import {
  Badge,
  Box,
  Button,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { isFuture, addDays } from 'date-fns';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import ActionDialog from '../../../components/ActionDialog';
import { deleteCargo, finishCargo, renewCargo } from '../../../providers/cargos';

import { CargoProps } from '../../../types';

function Cargo({ cargoData, onReload, onEdit }: CargoProps) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [cookies] = useCookies();
  const toast = useToast();
  const parsedDate = addDays(new Date(cargoData?.date), 5);
  const isDateInFuture = isFuture(parsedDate);

  const [chosenAction, setChosenAction] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogText, setDialogText] = useState('');

  const closeAndReset = () => {
    setDialogTitle('');
    setChosenAction('');
    setDialogText('');

    onClose();
    onReload();
  };

  const renewCargoAction = async (id: number) => {
    const isRenewed = await renewCargo(id, cookies.token);

    toast({
      description: isRenewed.message,
      status: isRenewed.status,
      duration: 4500,
      isClosable: true,
    });

    closeAndReset();
  };

  const deleteCargoAction = async (id: number) => {
    const isDeleted = await deleteCargo(id, cookies.token);

    toast({
      description: isDeleted.message,
      status: isDeleted.status,
      duration: 4500,
      isClosable: true,
    });

    closeAndReset();
  };

  const finishCargoAction = async (id: number) => {
    const isFinished = await finishCargo(id, cookies.token);

    toast({
      description: isFinished.message,
      status: isFinished.status,
      duration: 4500,
      isClosable: true,
    });

    closeAndReset();
  };

  const setAction = (action: string) => {
    if (action === 'RENEW') {
      setDialogTitle(t('USER_CARGOS_ACTIONS.RENEW_TITLE'));
      setDialogText(t('USER_CARGOS_ACTIONS.RENEW_DESC'));
      setChosenAction('RENEW');
    }

    if (action === 'DELETE') {
      setDialogTitle(t('USER_CARGOS_ACTIONS.DELETE_TITLE'));
      setDialogText(t('USER_CARGOS_ACTIONS.DELETE_DESC'));
      setChosenAction('DELETE');
    }

    if (action === 'FINISH') {
      setDialogTitle(t('USER_CARGOS_ACTIONS.FINISH_TITLE'));
      setDialogText(t('USER_CARGOS_ACTIONS.FINISH_DESC'));
      setChosenAction('FINISH');
    }

    onOpen();
  };

  const onConfirmAction = (action: string) => {
    if (action === 'RENEW') renewCargoAction(cargoData?.id);

    if (action === 'DELETE') deleteCargoAction(cargoData?.id);

    if (action === 'FINISH') finishCargoAction(cargoData?.id);
  };

  const renderStatusBadge = (isDone: boolean):string => {
    if (isDone) return 'blue.500';
    if (isDateInFuture) return '#009F50';

    return 'red.900';
  };

  const renderStatusText = (isDone: boolean) => {
    if (isDone) return t('USER_CARGOS.STATUS_FINISHED');
    if (isDateInFuture) return t('USER_CARGOS.STATUS_ACTIVE');

    return t('USER_CARGOS.STATUS_INACTIVE');
  };

  const fixedPrice = cargoData?.price.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
  });

  return (
    <Stack
      direction={['column', 'column', 'row']}
      my={4}
      pb={4}
      borderBottomWidth={2}
      borderColor="#314292"
    >
      <Box w="100%">
        <HStack pb={2}>
          <Text fontSize="lg" fontWeight="semibold" color="#FFF">
            {t('USER_CARGOS.STATUS')}
            :
          </Text>
          <Badge
            fontSize="md"
            bgColor={renderStatusBadge(cargoData?.done)}
            px={4}
          >
            {renderStatusText(cargoData?.done)}
          </Badge>
        </HStack>
        <Stack direction={['column', 'column', 'row']}>
          <Stack
            justifyContent="space-between"
            direction={['column', 'column', 'row']}
            w="100%"
          >
            <Stack spacing="14px" direction={['column', 'column', 'row']}>
              <Text fontSize="xl" color="#FFF">
                {`${cargoData?.fromCity?.name} | ${cargoData?.fromCity?.uf?.name}`}
              </Text>
              <FaArrowRight color="#314292" size="24px" />
              <Text fontSize="xl" color="#FFF">
                {`${cargoData?.toCity?.name} | ${cargoData?.toCity?.uf?.name}`}
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing="14px" direction={['column', 'column', 'row']}>
          <Stack
            justifyContent="space-between"
            direction={['column', 'column', 'row']}
            w="100%"
          >
            <Stack direction={['column', 'column', 'row']}>
              <Text fontSize="xl" fontWeight="semibold" color="#314292">
                {t('USER_CARGOS.PRODUCT')}
                :
              </Text>
              <Text fontSize="xl" color="#FFF">
                {cargoData?.bodyworks?.map(
                  (item) => `${item?.bodywork?.name} | `,
                )}
              </Text>
              <Text fontSize="xl" fontWeight="semibold" color="#314292">
                {t('USER_CARGOS.VEHICLE')}
                :
              </Text>
              <Text fontSize="xl" color="#FFF">
                {cargoData?.vehicles.map((item) => `${item?.vehicle?.name} | `)}
              </Text>
              <Text fontSize="xl" fontWeight="semibold" color="#314292">
                R$
              </Text>
              <Text color="#FFF" fontSize="xl">
                {fixedPrice}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {!cargoData?.done && (
        <Box pt={4}>
          <VStack>
            <Button
              rounded="none"
              bgColor="#016AF2"
              size="xs"
              w="122px"
              onClick={() => setAction('RENEW')}
              color="#FFF"
            >
              {t('USER_CARGOS.RENEW_BUTTON')}
            </Button>
            <Button
              rounded="none"
              bgColor="#314292"
              size="xs"
              w="122px"
              onClick={() => onEdit(cargoData?.id)}
              color="#FFF"
            >
              {t('USER_CARGOS.UPDATE_BUTTON')}
            </Button>
            <Button
              rounded="none"
              bgColor="#009F50"
              size="xs"
              w="122px"
              color="#FFF"
              onClick={() => setAction('FINISH')}
            >
              {t('USER_CARGOS.FINISH_BUTTON')}
            </Button>
            <Button
              rounded="none"
              bgColor="red.800"
              size="xs"
              w="122px"
              color="#FFF"
              onClick={() => setAction('DELETE')}
            >
              {t('USER_CARGOS.DELETE_BUTTON')}
            </Button>
          </VStack>
        </Box>
      )}

      <ActionDialog
        isOpen={isOpen}
        onClose={closeAndReset}
        title={dialogTitle}
        text={dialogText}
        onConfirm={() => onConfirmAction(chosenAction)}
      />
    </Stack>
  );
}

export default Cargo;

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box, Button, Center, FormControl, FormErrorMessage, Select, Text, useMediaQuery, VStack,
} from '@chakra-ui/react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  BodyworkProps,
  UfList,
  UfProps,
  CityProps,
  VehicleProps,
  OptionProps,
} from '../../../types';

import { getUfs } from '../../../providers/uf';
import schema from './schema';

import { getBodyworks } from '../../../providers/bodyworks';
import { getVehicles } from '../../../providers/vehicles';
import { getCitiesByUfId } from '../../../providers/cities';
import ControlledSelect from '../../../components/ControlledSelect';

interface FormProps {
  fromUf?: OptionProps;
  fromCity?: OptionProps;
  toUf?:OptionProps;
  toCity?: OptionProps;
  bodywork?: string;
  vehicle?: string;
}

interface FilterProps {
  onFilter: any;
}

function Filters({ onFilter }: FilterProps) {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const glassGradient = {
    background:
      'transparent linear-gradient(122deg, #FFFFFF66 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box',
    opacity: 0.96,
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormProps>({
    resolver: schema,
    defaultValues: {
      fromUf: undefined,
      fromCity: undefined,
      toUf: undefined,
      toCity: undefined,
      bodywork: undefined,
      vehicle: undefined,
    },
  });

  const [fromUfs, setFromUfs] = useState<UfList>([]);
  const [toUfs, setToUfs] = useState<UfList>([]);

  const [fromCities, setFromCities] = useState<CityProps[]>([]);
  const [toCities, setToCities] = useState<CityProps[]>([]);

  const [vehicles, setVehicles] = useState<VehicleProps[]>([]);
  const [bodyworks, setBodyworks] = useState<BodyworkProps[]>([]);

  const getInitialData = useCallback(async () => {
    try {
      const ufs = await getUfs();
      const bodyworksData = await getBodyworks();
      const vehicleData = await getVehicles();

      setFromUfs(ufs);
      setToUfs(ufs);
      setBodyworks(bodyworksData);
      setVehicles(vehicleData);

      reset();
    } catch (error) {
      console.error('Erro ao buscar ufs');
    }
  }, [reset]);

  const getCitiesById = async (ufId: string | undefined, origin: 'from' | 'to') => {
    try {
      const loadedCities = await getCitiesByUfId(ufId);

      if (origin === 'from') setFromCities(loadedCities);
      if (origin === 'to') setToCities(loadedCities);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = (searchData: FormProps) => {
    let fromCityIbgeLat;
    let fromCityIbgeLon;
    let toCityIbgeLat;
    let toCityIbgeLon;

    if (searchData?.fromCity?.value) {
      const foundFromCityData = fromCities.find(
        (item) => item?.ibge.toString() === searchData?.fromCity?.value?.toString(),
      );

      if (foundFromCityData) {
        fromCityIbgeLat = foundFromCityData?.latitude;
        fromCityIbgeLon = foundFromCityData?.longitude;
      }
    }

    if (searchData?.toCity?.value) {
      const foundToCityData = toCities.find(
        (item) => item?.ibge?.toString() === searchData?.toCity?.value?.toString(),
      );

      if (foundToCityData) {
        toCityIbgeLat = foundToCityData?.latitude;
        toCityIbgeLon = foundToCityData?.longitude;
      }
    }

    const data = {
      ...searchData,
      fromUf: searchData?.fromUf?.value,
      fromCityIbge: searchData?.fromCity?.value,
      fromCityIbgeLat,
      fromCityIbgeLon,
      toUf: searchData?.toUf?.value,
      toCityIbge: searchData?.toCity?.value,
      toCityIbgeLat,
      toCityIbgeLon,
    };

    onFilter(data);
  };

  const watchFromUf = useWatch({
    control,
    name: 'fromUf',
  });

  const watchToUf = useWatch({
    control,
    name: 'toUf',
  });

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    // @ts-ignore
    if (watchFromUf) getCitiesById(watchFromUf?.value, 'from');
  }, [watchFromUf]);

  useEffect(() => {
    // @ts-ignore
    if (watchToUf) getCitiesById(watchToUf?.value, 'to');
  }, [watchToUf]);

  return (
    <VStack
      borderWidth={2}
      borderColor="#e3e3e3"
      spacing={4}
      paddingBottom={isMobile ? 4 : undefined}
      height="100%"
      minWidth="100%"
      minHeight={650}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Center py={2} bgColor="#314292" w="100%">
          <Text fontSize="30px" fontWeight="semibold" color="#FFF">
            {t('SEARCH.FILTER_TITLE')}
          </Text>
        </Center>

        <VStack w="100%" pb={2}>
          <Center h={35} py={2} style={glassGradient} w="100%">
            <Text fontWeight="semibold" color="#FFF">
              {t('SEARCH.ORIGINS')}
            </Text>
          </Center>

          <Box px={4} w="100%">
            <FormControl isInvalid={!!errors?.fromUf}>
              <ControlledSelect
                inputName="fromUf"
                control={control}
                placeholder={t('SEARCH.UF')}
                options={fromUfs.map((uf: UfProps) => ({
                  label: uf?.name,
                  value: uf?.id,
                }))}
                selectedStyle="blueFilter"
              />
              <FormErrorMessage color="white">
                {errors?.fromUf && errors?.fromUf?.value?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box px={4} w="100%">
            <FormControl isInvalid={!!errors?.fromCity}>
              <ControlledSelect
                inputName="fromCity"
                control={control}
                placeholder={t('SEARCH.CITY')}
                // @ts-ignore
                options={fromCities.map((city) => ({
                  label: city?.name,
                  value: city?.ibge,
                }))}
                selectedStyle="blueFilter"
              />
              <FormErrorMessage color="white">
                {errors?.fromCity && errors?.fromCity?.value?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </VStack>

        <VStack w="100%" py={2}>
          <Center h={35} py={2} style={glassGradient} w="100%">
            <Text fontWeight="semibold" color="#FFF">
              {t('SEARCH.DESTINY')}
            </Text>
          </Center>

          <Box px={4} w="100%">
            <FormControl isInvalid={!!errors?.toUf}>
              <ControlledSelect
                inputName="toUf"
                control={control}
                placeholder={t('SEARCH.UF')}
                options={toUfs.map((uf: UfProps) => ({
                  label: uf?.name,
                  value: uf?.id,
                }))}
                selectedStyle="blueFilter"
              />
              <FormErrorMessage color="white">
                {errors?.toUf && errors?.toUf?.value?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box px={4} w="100%">
            <FormControl isInvalid={!!errors?.toCity}>
              <ControlledSelect
                inputName="toCity"
                control={control}
                placeholder={t('SEARCH.CITY')}
                // @ts-ignore
                options={toCities.map((city) => ({
                  label: city?.name,
                  value: city?.ibge,
                }))}
                selectedStyle="blueFilter"
              />
              <FormErrorMessage color="white">
                {errors?.toCity && errors?.toCity?.value?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </VStack>

        <VStack w="100%" py={4}>
          <Center h={35} py={2} style={glassGradient} w="100%">
            <Text fontWeight="semibold" color="#FFF">
              {t('SEARCH.ADVANCED')}
            </Text>
          </Center>

          <Box px={4} w="100%">
            <FormControl isInvalid={!!errors?.vehicle}>
              <Controller
                name="vehicle"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    height="40px"
                    bgColor="#314292"
                    placeholder={t('SEARCH.VEHICLE')}
                    value={value}
                    ref={ref}
                    onChange={onChange}
                    color="#FFF"
                  >
                    {vehicles.map((vehicle: VehicleProps) => (
                      <option key={vehicle?.id} value={vehicle?.id}>
                        {vehicle?.name}
                      </option>
                    ))}
                  </Select>
                )}
              />
              <FormErrorMessage color="white">
                {errors?.vehicle && errors?.vehicle?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box px={4} w="100%">
            <FormControl isInvalid={!!errors?.bodywork}>
              <Controller
                name="bodywork"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    height="40px"
                    bgColor="#314292"
                    placeholder={t('SEARCH.BODYWORK')}
                    value={value}
                    ref={ref}
                    onChange={onChange}
                    color="#FFF"
                  >
                    {bodyworks.map((bodywork: BodyworkProps) => (
                      <option key={bodywork?.id} value={bodywork?.id}>
                        {bodywork?.name}
                      </option>
                    ))}
                  </Select>
                )}
              />
              <FormErrorMessage color="white">
                {errors?.bodywork && errors?.bodywork?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </VStack>

        <VStack px={4}>
          <Button
            type="submit"
            bgColor="#314292"
            size="sm"
            isLoading={isSubmitting}
            color="#FFF"
            width="100%"
          >
            {t('SEARCH.FILTER')}
          </Button>
          <Button
            type="button"
            bgColor="#314292"
            size="sm"
            isLoading={isSubmitting}
            color="#FFF"
            onClick={() => window.location.reload()}
            width="100%"
          >
            {t('SEARCH.CLEAR')}
          </Button>
        </VStack>
      </form>
    </VStack>
  );
}

export default Filters;

import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../../config/yup';

export default yupResolver(yup.object({
  product: yup.string().required(),
  weight: yup.string().default('0').notRequired(),
  productType: yup.string().notRequired(),
  price: yup.string().default('0').notRequired(),
  date: yup.string().notRequired(),
  notes: yup.string().notRequired(),
  tracing: yup.boolean().default(false),
  agencing: yup.boolean().default(false),
  complementary: yup.boolean().default(false),
  fromCityIbge: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }).required(),
  fromUf: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  toCityIbge: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  toUf: yup.object({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  bodyworks: yup.array().required().min(1),
  vehicles: yup.array().required().min(1),
}));

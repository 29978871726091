import React, { ReactNode } from 'react';
import {
  Box, Stack, StackProps,
} from '@chakra-ui/react';
import { insideGradient, outsideSpinBox } from '../../constants/backgroundStyle';

interface WrapperProps {
  transparent: boolean | undefined;
  children: ReactNode;
  stackProps?: StackProps;
}

function Wrapper({ transparent, children, ...stackProps }: WrapperProps) {
  return (
    <Box style={transparent ? undefined : outsideSpinBox}>
      <Stack
        style={transparent ? undefined : insideGradient}
        {...stackProps}
      >
        {children}
      </Stack>
    </Box>
  );
}

Wrapper.defaultProps = {
  stackProps: {},
};

export default Wrapper;

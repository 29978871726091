import {
  Button, Center, HStack, Text, VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function PlanCard({
  fullValue,
  html,
  minorTextFontSizeMoneySymbol,
  minorPriceText,
  minorDescText,
  signUpText,
  title,
  description,
  value,
}: {
  minorTextFontSizeMoneySymbol: string;
  minorPriceText: string;
  minorDescText: string;
  signUpText: string;
  title: string;
  description: string;
  fullValue?: string;
  value: string;
  html?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <VStack spacing={-1}>
      <Center w="100%" borderRadius="lg" backgroundColor="#FFF" h="62px">
        <Text fontWeight="bold" fontSize="24px" color="#3994EA">
          {title}
        </Text>
      </Center>
      <VStack
        w={{ base: '100%', sm: undefined }}
        textAlign="center"
        bgColor="#324393"
        borderBottomRadius="lg"
        justifyContent="space-around"
        px={4}
      >
        {fullValue ? (
          <HStack spacing={0.5} pt={8}>
            <Text
              pt={1}
              color="#FFF"
              fontWeight="bold"
              fontSize="10px"
              textDecoration="line-through"
            >
              {t('PLANS.FROM')}
              :
              {' '}
            </Text>
            <Text
              pt={1}
              color="#FFF"
              fontWeight="bold"
              fontSize="10px"
              textDecoration="line-through"
            >
              R$
            </Text>
            <Text fontSize="14px" color="#FFF" fontWeight="bold" textDecoration="line-through">
              {fullValue}
            </Text>
          </HStack>
        ) : (
          <HStack spacing={0.5} pt={8}>
            <Text
              pt={1}
              color="#FFF"
              fontWeight="bold"
              fontSize="10px"
            >
              {t('PLANS.FOR')}
              :
              {' '}
            </Text>
          </HStack>
        )}

        <HStack spacing={0.5} mt={!html ? 10 : undefined}>
          <Text
            pt={1}
            color="#FFF"
            fontSize={minorTextFontSizeMoneySymbol}
            fontWeight="bold"
          >
            R$
          </Text>
          <Text color="#FFF" fontSize={minorPriceText} fontWeight="bold">
            {value}
          </Text>
        </HStack>

        <Center>
          <Text px={4} fontSize={minorDescText} color="#FFF">
            {html ? (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            ) : (
              description
            )}
          </Text>
        </Center>

        <Center py={4}>
          <Button size="sm" bgColor="#3994EA">
            <Link to="/sign-in?register=1">
              <Text color="#FFF" fontSize={signUpText} fontWeight="bold">
                {t('PLANS.SIGN_NOW')}
              </Text>
            </Link>
          </Button>
        </Center>
      </VStack>
    </VStack>
  );
}

PlanCard.defaultProps = {
  html: false,
  fullValue: '',
};

export default PlanCard;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Button,
  Center,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import FormCompany from '../../../components/SignUp/FormCompany';
import FormDriver from '../../../components/SignUp/FormDriver';

interface RegisterFormProps {
  onChangeTab: any;
}

function RegisterForm({ onChangeTab }: RegisterFormProps) {
  const { t } = useTranslation();
  const [registerType, setRegisterType] = useState('COMPANY');

  return (
    <Stack w="100%">
      <RadioGroup defaultValue="COMPANY" onChange={setRegisterType} value={registerType}>
        <Stack direction="row" justifyContent="space-evenly">
          <Radio value="COMPANY" isChecked><Text color="white" fontWeight="semibold">{t('SIGN_UP.REGISTER_TYPE.COMPANY')}</Text></Radio>
          <Radio value="DRIVER"><Text color="white" fontWeight="semibold">{t('SIGN_UP.REGISTER_TYPE.DRIVER')}</Text></Radio>
        </Stack>
      </RadioGroup>

      {
        registerType === 'COMPANY' ? <FormCompany /> : <FormDriver />
      }

      <VStack>
        <Text textAlign={{ base: 'center' }} color="#FFF">{t('SIGN_UP.ALREADY_REGISTERED')}</Text>
        <Button
          px={1}
          variant="ghost"
          onClick={onChangeTab}
          color="#314292"
          borderRadius="xl"
          fontSize="light"
        >
          {t('SIGN_UP.CLICK_TO_SIGN_IN')}
        </Button>
      </VStack>
      <Center />
    </Stack>
  );
}

export default RegisterForm;

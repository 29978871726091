import React from 'react';
import { Center, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Title from '../../components/Title';
import Wrapper from '../../components/Wrapper';
import PlanCard from './Card';

interface PageProps {
  transparent?: boolean;
}

function Plans({ transparent }: PageProps) {
  const { t } = useTranslation();

  const minorTextFontSizeMoneySymbol = '18px';
  const minorPriceText = '36px';
  const minorDescText = '14px';
  const signUpText = '20px';

  return (
    <Wrapper transparent={transparent}>
      <Stack px={16}>
        <Center pb={10}>
          <Title text={t('PLANS.TITLE')} size="md1" />
        </Center>

        <Stack
          direction={['column', 'column', 'row']}
          spacing={16}
          px={4}
          justifyContent="space-around"
        >
          <PlanCard
            html
            title={t('PLANS.BOLETO')}
            description={t('PLANS.BOLETO_DESC')}
            value="39,90"
            minorTextFontSizeMoneySymbol={minorTextFontSizeMoneySymbol}
            minorPriceText={minorPriceText}
            minorDescText={minorDescText}
            signUpText={signUpText}
          />
          <PlanCard
            html
            title={t('PLANS.CARD')}
            description={t('PLANS.CARD_DESC')}
            fullValue="39,90"
            value="29,90"
            minorTextFontSizeMoneySymbol={minorTextFontSizeMoneySymbol}
            minorPriceText={minorPriceText}
            minorDescText={minorDescText}
            signUpText={signUpText}
          />
        </Stack>
      </Stack>
    </Wrapper>
  );
}

Plans.defaultProps = {
  transparent: false,
};

export default Plans;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Box, Button, Input, Stack, Text, VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ContactBody } from '../../../types';

interface ContactsProps {
  contacts: ContactBody[];
  onEdit: any;
  onRemove: any;
  inputHeight: number;
  inputFontSize: string;
}

function Contact({
  contacts, onEdit, onRemove, inputHeight, inputFontSize,
}: ContactsProps) {
  const { t } = useTranslation();
  const fontColor = '#f3ebeb';

  return (
    <VStack spacing={2}>
      {contacts?.length > 0
        ? contacts.map((contact: ContactBody) => (
          <Stack
            w="100%"
            direction={['column', 'column', 'row']}
            spacing="7px"
            px={12}
            key={contact.id}
          >
            <Box flexGrow={2}>
              <Input
                placeholder={t('USER_PROFILE.CONTACT_NAME')}
                variant="outline"
                type="text"
                borderColor="#314292"
                focusBorderColor="#314292"
                h={inputHeight}
                fontSize={inputFontSize}
                borderWidth={2}
                color={fontColor}
                _placeholder={{
                  color: fontColor,
                }}
                disabled
                _disabled={{
                  color: fontColor,
                }}
                value={contact?.title}
              />
            </Box>
            <Box flexGrow={1}>
              <Input
                id="contactPhone"
                placeholder={t('USER_PROFILE.CONTACT_PHONE')}
                variant="outline"
                type="text"
                borderColor="#314292"
                focusBorderColor="#314292"
                h={inputHeight}
                fontSize={inputFontSize}
                borderWidth={2}
                color={fontColor}
                _placeholder={{
                  color: fontColor,
                }}
                disabled
                _disabled={{
                  color: fontColor,
                }}
                value={contact?.phone}
              />
            </Box>
            <Box flexGrow={2}>
              <Input
                id="contactDesc"
                placeholder={t('USER_PROFILE.CONTACT_DESCRIPTION')}
                variant="outline"
                type="text"
                borderColor="#314292"
                focusBorderColor="#314292"
                h={inputHeight}
                fontSize={inputFontSize}
                borderWidth={2}
                color={fontColor}
                _placeholder={{
                  color: fontColor,
                }}
                disabled
                _disabled={{
                  color: fontColor,
                }}
                value={contact?.description}
              />
            </Box>
            <VStack justifyContent="center" spacing={0.5}>
              <Button
                bgColor="#314292"
                size="xs"
                color="#FFF"
                onClick={() => onEdit(contact?.id)}
              >
                <Text>{t('USER_PROFILE.EDIT')}</Text>
              </Button>
              <Button
                bgColor="red.500"
                size="xs"
                color="#FFF"
                onClick={() => onRemove(contact?.id)}
              >
                <Text>{t('USER_PROFILE.REMOVE')}</Text>
              </Button>
            </VStack>
          </Stack>
        ))
        : null}
    </VStack>
  );
}

export default Contact;

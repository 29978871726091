import { addDays } from 'date-fns';
import axios from '../../config/axios';

import LANG from '../../locale/pt-br';

import { EditCargoDatabaseProps, ReturnMessageProps } from '../../types';

export async function getCargos(userToken: string) {
  try {
    const cargos = await axios.get('/cargos', {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return cargos?.data || [];
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      code: error?.response?.status,
    };
  }
}

export async function getCargoById(userToken: string, id: number) {
  try {
    const cargos = await axios.get(`/cargos/${id}`, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return cargos?.data;
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      code: error?.response?.status,
    };
  }
}

export async function createCargo(
  userToken: string,
  body: EditCargoDatabaseProps,
):Promise<ReturnMessageProps> {
  try {
    await axios.post('/cargos', {
      ...body,
    }, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.USER_CARGOS_ACTIONS.CREATE_CARGO_SUCCESS,
    };
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      code: error?.response?.status,
    };
  }
}

export async function editCargoById(
  id: number,
  userToken: string,
  body: EditCargoDatabaseProps,
):Promise<ReturnMessageProps> {
  try {
    await axios.patch(`/cargos/${id}`, {
      ...body,
    }, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.USER_CARGOS_ACTIONS.RENEW_SUCCESS,
    };
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      code: error?.response?.status,
    };
  }
}

export async function renewCargo(
  id: number,
  userToken: string,
):Promise<ReturnMessageProps> {
  try {
    const newDate = addDays(new Date(), 5);

    await axios.patch(`/cargos/${id}`, {
      date: newDate,
    }, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.USER_CARGOS_ACTIONS.RENEW_SUCCESS,
    };
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      code: error?.response?.status,
    };
  }
}

export async function finishCargo(
  id: number,
  userToken: string,
):Promise<ReturnMessageProps> {
  try {
    await axios.patch(`/cargos/${id}`, {
      done: true,
    }, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.USER_CARGOS_ACTIONS.FINISH_SUCCESS,
    };
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      code: error?.response?.status,
    };
  }
}

export async function deleteCargo(
  id: number,
  userToken: string,
):Promise<ReturnMessageProps> {
  try {
    await axios.delete(`/cargos/${id}`, {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    });

    return {
      status: 'success',
      message: LANG.USER_CARGOS_ACTIONS.DELETE_SUCCESS,
    };
  } catch (error) {
    return {
      status: 'error',
      message: LANG.TOASTS.ERROR.GENERIC_ERROR,
      code: error?.response?.status,
    };
  }
}

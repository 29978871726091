import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Image,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Title from '../../components/Title';
import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';

import ContactUsImg from '../../assets/contact-us.png';
import freteCiaLogo from '../../assets/fretecia-logo.svg';

import { insideGradient, outsideSpinBox } from '../../constants/backgroundStyle';

function SignIn() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [tabIndex, setTabIndex] = useState(0);

  const changeToRegister = () => setTabIndex(1);
  const changeToLogin = () => setTabIndex(0);

  const selectedTabStyle = {
    color: 'white',
    borderTopWidth: 3,
    borderTopColor: '#314292',
    paddingTop: 0,
  };

  const register = searchParams.get('register');

  useEffect(() => {
    window.scrollTo(0, 0);

    setTabIndex(register === '1' ? 1 : 0);
  }, [register]);

  return (
    <Box style={outsideSpinBox} minH="100vh">
      <Box style={insideGradient} minH="100vh">
        <Stack
          pt={4}
          px={isMobile ? 4 : 16}
          direction={['column-reverse', 'column-reverse', 'row']}
          alignItems={{ sm: 'center', md: 'center', lg: 'flex-start' }}
          justifyContent="space-around"
        >
          {!isMobile && (
          <Box pt={12}>
            <Image src={ContactUsImg} boxSize="xl" />
          </Box>
          )}

          <Box width={{ lg: '45%' }}>
            <Center py={2} my={8}>
              <Image src={freteCiaLogo} alt="fretecia-logo" height={12} />
            </Center>

            <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
              <Center>
                <TabList>
                  <Tab _selected={selectedTabStyle}>
                    <Title size="sm" text={t('SIGN_IN.TITLE')} />
                  </Tab>
                  <Tab _selected={selectedTabStyle}>
                    <Title size="sm" text={t('SIGN_UP.TITLE')} />
                  </Tab>
                </TabList>
              </Center>

              <TabPanels>
                <TabPanel>
                  <LoginForm onChangeTab={changeToRegister} />
                </TabPanel>
                <TabPanel>
                  <RegisterForm onChangeTab={changeToLogin} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default SignIn;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { UseToastOptions } from '@chakra-ui/react';
import axios from '../../config/axios';

import { returnMessage } from '../../utils';
import LANG from '../../locale/pt-br';
import { ContactBody } from '../../types';

interface ReturnUserProps {
  status: UseToastOptions['status'];
  message: string | false;
}

export async function getContacts(token: string) {
  try {
    const contacts = await axios.get('/bookmarks', {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    return contacts?.data || [];
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
      code: err?.response?.status,
    };
  }
}

export async function getContact(id: number, token: string) {
  try {
    const contact = await axios.get(`/bookmarks/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    return contact?.data || {};
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
      code: err?.response?.status,
    };
  }
}

export async function createContact(body: ContactBody, token: string): Promise<ReturnUserProps> {
  try {
    await axios.post('/bookmarks', body, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    return {
      status: 'success',
      message: LANG.TOASTS.CREATE_CONTACT_SUCCESS,
    };
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
    };
  }
}

export async function editContact(
  id: number,
  body: ContactBody,
  token: string,
):Promise<ReturnUserProps> {
  try {
    await axios.patch(`/bookmarks/${id}`, body, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    return {
      status: 'success',
      message: LANG.TOASTS.EDIT_CONTACT_SUCCESS,
    };
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
    };
  }
}

export async function removeContact(
  id: number,
  token: string,
):Promise<ReturnUserProps> {
  try {
    await axios.delete(`/bookmarks/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    return {
      status: 'success',
      message: LANG.TOASTS.REMOVE_CONTACT_SUCCESS,
    };
  } catch (error) {
    const err = error as AxiosError | any;

    return {
      status: 'error',
      message: returnMessage(err?.response?.data?.message),
    };
  }
}

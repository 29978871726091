/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { getMe } from '../providers/user';

interface IUserDataContext {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;

  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;

  isFirstAccess: boolean;
  setIsFirstAccess: Dispatch<SetStateAction<boolean>>;

  username: string;
  setUsername: Dispatch<SetStateAction<string>>;

  avatar: string;
  setAvatar: Dispatch<SetStateAction<string>>;

  role: string;
  setRole: Dispatch<SetStateAction<string>>;
}

const defaultValue = {
  isLoggedIn: false,
  setIsLoggedIn: () => {},

  isActive: false,
  setIsActive: () => {},

  isFirstAccess: false,
  setIsFirstAccess: () => {},

  username: 'Usuário(a)',
  setUsername: () => {},

  avatar: '',
  setAvatar: () => {},

  role: '',
  setRole: () => {},
};

const UserDataContext = createContext<IUserDataContext>(defaultValue);

export function UserDataProvider({ children }: { children: React.ReactNode }) {
  const [cookie] = useCookies();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isFirstAccess, setIsFirstAccess] = useState(true);
  const [username, setUsername] = useState('Usuário(a)');
  const [avatar, setAvatar] = useState('');
  const [role, setRole] = useState('');

  const getMeData = useCallback(async () => {
    const myData = await getMe(cookie.token);

    setIsActive(myData?.active || false);
    setIsLoggedIn(!!myData?.email && !!myData?.id);
    setIsFirstAccess(Boolean(myData?.firstAccess));
    setUsername(myData?.tradeName || myData?.name || 'Usuário(a)');
    setAvatar(myData?.avatarFile || '');
    setRole(myData?.role || '');
  }, [cookie.token]);

  useEffect(() => { getMeData(); }, [getMeData]);

  return (
    <UserDataContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isActive,
        setIsActive,
        isFirstAccess,
        setIsFirstAccess,
        username,
        setUsername,
        avatar,
        setAvatar,
        role,
        setRole,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
}

export function useUserDataContext() {
  return useContext(UserDataContext);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';

import yup from '../../../config/yup';
import LANG from '../../../locale/pt-br';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export default yupResolver(yup.object({
  avatarFile: yup
    .mixed()
    .test(
      'fileSupportedLength',
      LANG.VALIDATION.GENERAL_REQUIRED,
      (value) => value.length,
    )
    .test(
      'fileSupportedSize',
      LANG.VALIDATION.VALID_IMAGE_SIZE,
      (value) => {
        const size = parseFloat(value[0]?.size) / 1024 / 1024;

        return size <= 5;
      },
    )
    .test(
      'fileSupportedFormats',
      LANG.VALIDATION.VALID_IMAGE_FORMAT,
      (value) => SUPPORTED_FORMATS.includes(value[0]?.type),
    ),
}));

import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useCookies } from 'react-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { modalGradient } from '../../../constants/backgroundStyle';
import { EvaluationModalProps, setEvaluated } from '../../../providers/user';

import schema from './schema';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function EvaluationModal({ isOpen, onClose }: ModalProps) {
  const { t } = useTranslation();
  const [cookies] = useCookies();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EvaluationModalProps>({
    resolver: schema,
  });

  const onSubmit: SubmitHandler<EvaluationModalProps> = async (data) => {
    const action = await setEvaluated(data, cookies.token);

    toast({
      description: action.message,
      status: action.status,
      duration: 4500,
      isClosable: true,
    });

    reset();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent style={modalGradient}>
        <ModalHeader color="white">
          {t('EVALUATE_PLATAFORM.TITLE')}
          :
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={5}>
              <FormControl isRequired isInvalid={!!errors.evaluation}>
                <FormLabel color="white">{t('EVALUATE_PLATFORM.RATING')}</FormLabel>
                <Select
                  textColor="white"
                  placeholder={t('EVALUATE_PLATFORM.SELECT_RATING')}
                  {...register('evaluation')}
                >
                  <option value="VERY_GOOD">{t('EVALUATE_PLATFORM.VERY_GOOD')}</option>
                  <option value="GOOD">{t('EVALUATE_PLATFORM.GOOD')}</option>
                  <option value="MORE_LESS">{t('EVALUATE_PLATFORM.MORE_LESS')}</option>
                  <option value="BAD">{t('EVALUATE_PLATFORM.BAD')}</option>
                  <option value="TOO_BAD">{t('EVALUATE_PLATFORM.VERY_BAD')}</option>
                </Select>
              </FormControl>

              <FormControl isRequired isInvalid={!!errors.justification}>
                <FormLabel color="white">{t('EVALUATE_PLATFORM.JUSTIFY')}</FormLabel>
                <Textarea
                  color="white"
                  maxLength={200}
                  resize="none"
                  {...register('justification')}
                />
              </FormControl>
            </Stack>

            <Stack direction={['column', 'column', 'row-reverse']} pt={3}>
              <Button type="submit" color="white" bgColor="#009F50">
                {t('EVALUATE_PLATFORM.EVALUATE')}
              </Button>
              <Button
                type="button"
                color="white"
                bgColor="red.800"
                onClick={onClose}
              >
                {t('EVALUATE_PLATFORM.LATER')}
              </Button>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EvaluationModal;

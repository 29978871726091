import { VStack, Center, Text } from '@chakra-ui/react';
import PayPalButton from '../../../components/PaypalButton';

function PaypalForm() {
  return (
    <VStack mx={12}>
      <Text py={2} fontWeight="semibold" color="#FFF">
        Todo este processo de pagamento está protegido e feito dentro da
        plataforma Paypal.
        <br />
        Clique no botão abaixo para continuar o pagamento.
      </Text>

      <Center>
        <PayPalButton />
      </Center>
    </VStack>
  );
}

export default PaypalForm;

import { yupResolver } from '@hookform/resolvers/yup';
import { validateCPF, validatePhone } from 'validations-br';

import yup from '../../../config/yup';
import LANG from '../../../locale/pt-br';

export default yupResolver(yup.object({
  cpf: yup.string().test(
    'is-cpf',
    LANG.VALIDATION.INVALID_CPF,
    (value = '') => validateCPF(value),
  ).required(),
  name: yup.string().required(),
  bodyworkType: yup.string().required(),
  vehicleType: yup.string().required(),
  phone: yup.string().test(
    'is-phone',
    LANG.VALIDATION.PHONE_NUMBER,
    (value = '') => validatePhone(value),
  ),
  email: yup.string().email().required(),
  password: yup.string().required(),
  confirmPassword: yup.string()
    .oneOf(
      [yup.ref('password'), null],
      LANG.VALIDATION.PASSWORD_MATCHES,
    )
    .required(),
}));

import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Box, Center, Divider, useDisclosure,
} from '@chakra-ui/react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Title from '../../components/Title';

import Contact from './Contact';
import Form from './Form';
import DriverForm from './Form/driver';
import RemoveContactModal from './RemoveContactModal';
import NewContactModal from './NewContactModal';

import { outsideSpinBox } from '../../constants/backgroundStyle';
import { useUserDataContext } from '../../contexts';
import { getContacts } from '../../providers/contacts';

function UserProfile() {
  const { t } = useTranslation();
  const gradient = {
    background:
    'transparent linear-gradient(122deg, #7EB9EF 0%, #6772A9 100%) 0% 0% no-repeat padding-box',
    opacity: 0.99,
    backdropFilter: 'blur(9px)',
    WebkitBackdropFilter: 'blur(9px)',
  };

  const inputHeight = 34;
  const inputFontSize = 'sm';

  const navigate = useNavigate();
  const { role } = useUserDataContext();
  const [cookies, , removeCookie] = useCookies();
  const [contacts, setContacts] = useState([]);
  const [contactId, setContactId] = useState(0);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenRemoveModal,
    onOpen: onOpenRemoveModal,
    onClose: onCloseRemoveModal,
  } = useDisclosure();

  const showContactModal = () => onOpen();

  const loadContacts = useCallback(async () => {
    try {
      const foundContacts = await getContacts(cookies?.token);

      if (foundContacts.code === 401) {
        removeCookie('token', { path: '/' });
        navigate('/sign-in', { replace: true });
      }

      setContacts(foundContacts);
    } catch (error) {
      console.error(error);
    }
  }, [cookies?.token, navigate, removeCookie]);

  const closeAndLoad = () => {
    onClose();
    setContactId(0);
    loadContacts();
  };

  const getIdAndOpenModal = (id: number) => {
    setContactId(id);
    onOpen();
  };

  const getIdAndOpenRemoveModal = (id: number) => {
    setContactId(id);
    onOpenRemoveModal();
  };

  const closeRemoveModalAndReload = () => {
    onCloseRemoveModal();
    loadContacts();
  };

  useEffect(() => { loadContacts(); }, [loadContacts]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      style={outsideSpinBox}
      borderWidth={1}
      borderColor="white"
      borderLeftRadius="xl"
    >
      <Box style={gradient} minH="100vh" p={12}>
        <Center mb={8} textAlign="center">
          <Title text={t('USER_PROFILE.TITLE')} size="md1" />
        </Center>
        {(role === 'DRIVER'
          ? (
            <DriverForm
              inputFontSize={inputFontSize}
              inputHeight={inputHeight}
              onOpenContacts={showContactModal}
            />
          )
          : (
            <Form
              inputFontSize={inputFontSize}
              inputHeight={inputHeight}
              onOpenContacts={showContactModal}
            />
          )
          )}
        <Box mx={2} px={10} py={4}>
          <Divider py={0.4} bgColor="#314292" orientation="horizontal" />
        </Box>
        <Contact
          inputFontSize={inputFontSize}
          inputHeight={inputHeight}
          contacts={contacts}
          onEdit={getIdAndOpenModal}
          onRemove={getIdAndOpenRemoveModal}
        />
        <NewContactModal
          hasId={contactId}
          isOpen={isOpen}
          onClose={closeAndLoad}
        />
        <RemoveContactModal
          onClose={closeRemoveModalAndReload}
          isOpen={isOpenRemoveModal}
          contactId={contactId}
        />
      </Box>
    </Box>
  );
}

export default UserProfile;

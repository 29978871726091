/* eslint-disable @typescript-eslint/no-explicit-any */
import { useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { createPaymentWithPaypal } from '../../providers/payments';

export interface PayPalResponse {
  orderID: string;
  subscriptionID: string;
  facilitatorAccessToken: string;
  paymentSource: string;
}

const PayPalButton: React.FC = () => {
  const toast = useToast();
  const [cookies] = useCookies();

  // prod
  const clientId = 'AYHjUOky_LhOpXkJmuvdt2lbgGYa-BmUuwAERCaxjbw5kPFXhidlkGYrUO9y1gB5tmVpHWq0Xv3rWxP0';
  const planId = 'P-76496979TB363953VMYMTXUQ';

  // sandbox
  // eslint-disable-next-line max-len
  // const clientId = 'ARilMpgPOqD_imqgLaXJ3dQC-RyQ76UDc6hCkjROwYvHTTBsYtnsfiVzGSz_ZxzihY-Ev58N3dal6rm0';
  // const planId = 'P-1Y965925TV561102BMSNBE5A';

  // .URL
  const url = `https://www.paypal.com/sdk/js?client-id=${clientId}&vault=true&intent=subscription`;

  useEffect(() => {
    const initializePayPalButton = () => {
      (window as any).paypal
        .Buttons({
          style: {
            shape: 'pill',
            color: 'gold',
            layout: 'vertical',
            label: 'subscribe',
          },
          createSubscription(data: any, actions: any) {
            return actions.subscription.create({
              /* Creates the subscription */
              plan_id: planId,
            });
          },
          async onApprove(data: PayPalResponse) {
            // Sandbox example return
            // {
            //   "orderID": "5KK35133YM955103H",
            //   "subscriptionID": "I-PB3MDJJX3KFE",
            // eslint-disable-next-line max-len
            //   "facilitatorAccessToken": "A21AAJBIijblxVFSaKsEIJERkbweLkdGH7e--LC0wkIdKUlfQhyWrKOa7BptPvG7ijAyaw1CvHu1cK19G7_Av8UcHRy3KB-Kw",
            //   "paymentSource": "paypal"
            // }

            // Approves the subscription
            const isApproved = await createPaymentWithPaypal(cookies?.token, data);

            if (isApproved.status === 'success') {
              toast({
                description: isApproved.message,
                status: isApproved.status,
                duration: 4500,
                isClosable: true,
              });
            }
          },
        })
        .render(`#paypal-button-container-${planId}`); // Renders the PayPal button
    };

    // Load the PayPal SDK asynchronously
    const script = document.createElement('script');
    script.src = url;
    script.setAttribute('data-sdk-integration-source', 'button-factory');
    script.addEventListener('load', initializePayPalButton);
    document.body.appendChild(script);

    // Clean up the script tag on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [cookies?.token, toast, url]);

  return <div id={`paypal-button-container-${planId}`} />;
};

export default PayPalButton;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import Cargo from './Cargo';
import EvaluationModal from './EvaluationModal';
import EditCargoModal from './EditCargoModal';

import { getCargos } from '../../providers/cargos';
import { getMe } from '../../providers/user';

import { useUserDataContext } from '../../contexts';
import { CargoProps } from '../../types';

const gradient = {
  background:
    'transparent linear-gradient(122deg, #7EB9EF 0%, #6772A9 100%) 0% 0% no-repeat padding-box',
  opacity: 0.99,
  backdropFilter: 'blur(9px)',
  WebkitBackdropFilter: 'blur(9px)',
};

function UserCargos() {
  const { t } = useTranslation();
  const {
    isOpen: isOpenEditCargoModal,
    onOpen: onOpenEditCargoModal,
    onClose: onCloseEditCargoModal,
  } = useDisclosure();

  const {
    isOpen: isOpenEvaluationModal,
    onOpen: onOpenEvaluationModal,
    onClose: onCloseEvaluationModal,
  } = useDisclosure();

  const [cookies] = useCookies();
  const [cargos, setCargos] = useState([]);
  const [cargoId, setCargoId] = useState(0);

  const { isFirstAccess } = useUserDataContext();

  const reloadCargos = useCallback(async () => {
    try {
      const foundCargos = await getCargos(cookies.token);

      setCargos(foundCargos);
    } catch (error) {
      console.error(error);
    }
  }, [cookies.token]);

  const showEditModal = (id = 0) => {
    setCargoId(id);
    onOpenEditCargoModal();
  };

  const closeAndReload = () => {
    onCloseEditCargoModal();
    setCargoId(0);
    reloadCargos();
  };

  const randomizeShowEvaluatedModal = useCallback(async () => {
    const user = await getMe(cookies.token);

    if (!user.hasEvaluated) {
      const value = Math.floor(Math.random() * (100 - 1 + 1) + 1);

      if (value === 99) {
        onOpenEvaluationModal();
      }
    }
  }, [cookies.token, onOpenEvaluationModal]);

  useEffect(() => {
    reloadCargos();
    randomizeShowEvaluatedModal();
  }, [randomizeShowEvaluatedModal, reloadCargos]);

  return (
    <Box style={gradient} minH="100vh" borderWidth={1} px={12}>
      <EvaluationModal
        isOpen={isOpenEvaluationModal}
        onClose={onCloseEvaluationModal}
      />
      <EditCargoModal
        cargoId={cargoId}
        isOpen={isOpenEditCargoModal}
        onClose={closeAndReload}
      />
      <Stack
        direction={['column', 'column', 'row']}
        justifyContent="space-between"
        mt={12}
      >
        <Box />
        <Text color="white" fontSize="4xl" fontWeight="extrabold">
          {t('USER_CARGOS.TITLE')}
        </Text>
        <Button
          color="white"
          px={12}
          bgColor="#314292"
          onClick={() => showEditModal()}
          disabled={isFirstAccess}
        >
          {t('USER_CARGOS.NEW_BUTTON')}
        </Button>
      </Stack>

      {isFirstAccess && (
        <Center pt={4}>
          <Alert status="error" variant="solid">
            <AlertIcon color="#FFF" />
            <Text color="white" fontWeight="semibold">
              {t('USER.CANT_CREATE_CARGO_BECAUSE_IS_NOT_ACTIVE')}
            </Text>
          </Alert>
        </Center>
      )}

      {!isFirstAccess
        && cargos.map((cargo: CargoProps['cargoData']) => (
          <Cargo
            key={cargo?.id}
            cargoData={cargo}
            onReload={reloadCargos}
            onEdit={() => showEditModal(cargo?.id)}
          />
        ))}
    </Box>
  );
}

export default UserCargos;

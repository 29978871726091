import React, { useEffect } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Logout() {
  const { t } = useTranslation();
  const [, , removeCookies] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    removeCookies('token');
    navigate('/');
  }, [navigate, removeCookies]);

  return (
    <Box minH="100vh">
      <Center h="300" py={4}>
        <Text fontSize="xl" color="#FFF" fontWeight="semibold">
          {t('LOGOUT.TITLE')}
        </Text>
      </Center>
    </Box>
  );
}

export default Logout;

import axios from '../../config/axios';

export async function getBodyworks() {
  try {
    const bodyworks = await axios.get('/bodyworks');

    return bodyworks.data || [];
  } catch (error) {
    console.error(error);
  }

  return [];
}

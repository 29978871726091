import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Button, FormControl, FormErrorMessage, Input, InputGroup, InputLeftElement, Text, VStack,
} from '@chakra-ui/react';
import { AiFillMail, AiOutlineUser } from 'react-icons/ai';

import { useTranslation } from 'react-i18next';
import schema from './schema';

interface FormProps {
  email: string;
  cnpj: string;
}

function Form() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormProps>({
    resolver: schema,
  });

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    console.log({ data });
    return true;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack mt={{ sm: 10, md: 10, lg: 0 }} spacing={8}>
        <FormControl isInvalid={!!errors?.cnpj}>
          <InputGroup>
            <InputLeftElement>
              <AiOutlineUser size="24" />
            </InputLeftElement>
            <Input
              id="cnpj"
              width="full"
              placeholder={t('SIGN_UP.CNPJ')}
              borderColor="white"
              variant="flushed"
              color="white"
              opacity={0.6}
              _placeholder={{ color: 'white' }}
              type="cnpj"
              {...register('cnpj')}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.cnpj && errors?.cnpj?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.email}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiFillMail size="24" />
            </InputLeftElement>
            <Input
              id="email"
              width="full"
              borderColor="white"
              placeholder={t('FORGOT_PASSWORD.EMAIL')}
              color="white"
              opacity={0.6}
              variant="flushed"
              _placeholder={{
                color: 'white',
              }}
              {...register('email')}
            />
          </InputGroup>
          <FormErrorMessage color="white">
            {errors?.email && errors?.email?.message}
          </FormErrorMessage>
        </FormControl>
        <br />
        <Button
          type="submit"
          width="full"
          size="lg"
          py={30}
          isLoading={isSubmitting}
          bgColor="#314292"
          borderRadius="xl"
          fontSize="light"
        >
          <Text color="white">{t('FORGOT_PASSWORD.RECOVER_PASSWORD')}</Text>
        </Button>
      </VStack>
    </form>
  );
}

export default Form;

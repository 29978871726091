import {
  Box, Button, Grid, HStack, Icon, Image, Link, Text, useMediaQuery,
} from '@chakra-ui/react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import truckFullImg from '../../assets/truck1.png';
import TextLogo from '../../assets/fretecia-logo.svg';

function Main() {
  const { t } = useTranslation();

  const backgroundStyle = {
    backgroundImage: `url(${truckFullImg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  const [isLargerThanHD] = useMediaQuery(['(min-width: 1440px)']);
  const renderLogoMy = isLargerThanHD ? 8 : 6;

  const navigate = useNavigate();
  const goToSearch = () => navigate('search');

  return (
    <Grid
      minH="100vh"
      style={backgroundStyle}
      width={{
        base: '100%',
        sm: undefined,
      }}
    >
      <Box>
        <HStack my={renderLogoMy}>
          <Image pl={[4, 8, 8]} py={2} src={TextLogo} h={90} />
        </HStack>
        <HStack
          pl={[0, 0, 12]}
          textAlign={{ sm: 'center', md: 'center', lg: 'left' }}
          maxWidth={{ lg: '650px' }}
          justifyContent={{ sm: 'center', md: 'center', lg: undefined }}
          alignItems={{ sm: 'center', md: 'center', lg: undefined }}
        >
          <Text
            align={['center', 'center', 'left']}
            lineHeight={1.2}
            color="#FFF"
            fontWeight="extrabold"
            fontSize={['48px', '64px', '72px', '94px', '94px', '100px']}
            style={
              { textShadow: '6px 6px 2px rgba(0, 63, 141, 1)' }
            }
          >
            {t('HOME.LOGO_TEXT')}
          </Text>
        </HStack>
        <HStack
          pl={12}
          pt={20}
          justifyContent={{ sm: 'center', md: 'center', lg: 'flex-start' }}
        >
          <Button p={8} size="lg" bgColor="#303B8B" onClick={goToSearch}>
            <Text fontSize="2xl" fontWeight="semibold" color="#FFF">
              {t('HOME.SEARCH_BUTTON')}
            </Text>
          </Button>
        </HStack>
      </Box>
      <HStack
        pt={{ sm: 10, md: 10, lg: 10 }}
        justifyContent={{ sm: 'center', md: 'center', lg: 'flex-end' }}
      >
        <Link href={process.env.REACT_APP_FACEBOOK_LINK} isExternal>
          <Icon as={FaFacebookF} w={8} h={8} color="#314292" />
        </Link>
        <Link href={process.env.REACT_APP_INSTAGRAM_LINK} isExternal>
          <Icon as={FaInstagram} w={8} h={8} color="#314292" />
        </Link>
        <Box bgColor="#314292" height={0.5} width={{ md: '0px', lg: '10em' }} />
      </HStack>
    </Grid>
  );
}

export default Main;

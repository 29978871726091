import React, { useEffect } from 'react';
import {
  Box, Center, Image, Stack, Text, useMediaQuery,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import Title from '../../components/Title';

import whoIsImg from '../../assets/whoIsCut.png';
import Wrapper from '../../components/Wrapper';
import InstallPWAButton from '../../components/PWAButton';

interface PageProps {
  transparent?: boolean;
}

function WhoIs({ transparent }: PageProps) {
  const { t } = useTranslation();
  const [isLargerThanHD] = useMediaQuery('(min-width: 1440px)');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const defaultFont = isLargerThanHD ? '17.5px' : '15px';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper transparent={transparent}>
      <Center textAlign="center" pb={10}>
        <Title text={t('WHO_IS.TITLE')} size="md1" />
      </Center>
      <Stack
        direction={['column', 'column', 'row']}
        alignItems={{ sm: 'center', md: 'center', lg: 'flex-start' }}
        justifyContent="center"
        gap={5}
      >
        <Box
          width={{ base: undefined, lg: '45%' }}
          pt={1}
          px={isMobile ? 4 : undefined}
          textAlign="justify"
        >
          <Text fontWeight="bold" color="white" fontSize={defaultFont}>
            {t('WHO_IS.PARAGRAPH_1')}
          </Text>
          <br />
          <Text fontWeight="bold" color="white" fontSize={defaultFont}>
            {t('WHO_IS.PARAGRAPH_2')}
          </Text>
          <br />
          <Text fontWeight="bold" color="white" fontSize={defaultFont}>
            {t('WHO_IS.PARAGRAPH_3')}
          </Text>
          <br />
          <Text textAlign="center" fontWeight="bold" color="#314292" fontSize={defaultFont}>
            {t('WHO_IS.PARAGRAPH_4')}
          </Text>
          <br />
          <Center>
            <InstallPWAButton buttonName="Clique aqui para instalar a versão Lite do App" />
          </Center>
        </Box>
        <Box>
          <Image
            mt={2}
            src={whoIsImg}
            boxSize="md"
            height={250}
            px={isMobile ? 4 : undefined}
          />
        </Box>
      </Stack>
    </Wrapper>
  );
}

WhoIs.defaultProps = {
  transparent: false,
};

export default WhoIs;

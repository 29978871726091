import React from 'react';

import {
  FormControl, Icon, Input, InputGroup, InputLeftAddon, Tooltip,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface CargoTooltipProps {
  label: string;
  text: string;
  height?: number;
  icon: IconType;
  bgColor?: string;
  inputBgColor?: string;
  inputColor?: string;
  color?: string;
  iconFontSize?: string;
}

function CargoTooltip({
  bgColor,
  color,
  height,
  icon,
  iconFontSize,
  inputBgColor,
  inputColor,
  label,
  text,
}:CargoTooltipProps) {
  return (
    <Tooltip hasArrow label={label} bgColor={bgColor} color={color}>
      <FormControl>
        <InputGroup>
          <InputLeftAddon height={height} bgColor={bgColor}>
            <Icon color={color} as={icon} fontSize={iconFontSize} />
          </InputLeftAddon>
          <Input
            size="sm"
            disabled
            bgColor={inputBgColor}
            color={inputColor}
            value={text}
            _disabled={{ bgColor: inputBgColor }}
            rounded="md"
          />
        </InputGroup>
      </FormControl>
    </Tooltip>
  );
}

CargoTooltip.defaultProps = {
  bgColor: '#314292',
  color: '#FFF',
  height: 8,
  iconFontSize: '18',
  inputBgColor: '#FFF',
  inputColor: '#060d2b',
};

export default CargoTooltip;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Box,
  Button,
  Center,
  Image,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  FaHome,
  FaMapMarkerAlt, FaTruckLoading, FaTruckPickup,
} from 'react-icons/fa';
import { GiWeight } from 'react-icons/gi';
import { MdAttachMoney } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

import CargoModal from '../CargoModal';
import CargoTooltip from '../CargoTooltip';

import { cargoCardGlassGradient } from '../../../constants/backgroundStyle';
import { baseURL } from '../../../config/axios';
import noTruckLogo from '../../../assets/truck-no-logo.png';

interface CargoProps {
  cargoData: {
    id: number;
    product: string;
    productType: string;
    fromCity: string;
    fromCityIbge: number;
    fromUf: string;
    latitude: number;
    longitude: number;
    toUf: string;
    toCity: string;
    toCityIbge: number;
    toCityLat: number;
    toCityLon: number;
    date: any;
    createdAt: any;
    vehicles: string;
    bodyworks: string;
    weight: number;
    price: number;
    tracing: boolean;
    agencing: boolean;
    complementary: boolean;
    avatarFile: string;
    done: boolean;
  };
}

function Cargo({ cargoData }: CargoProps) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const viewDetails = () => onOpen();

  const logo = !cargoData.avatarFile ? noTruckLogo : `${baseURL}/avatars/${cargoData.avatarFile}`;
  const fixedPrice = cargoData?.price.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  const price = cargoData?.price > 0 ? `R$ ${fixedPrice}` : 'A combinar';
  const weight = `${cargoData?.weight.toLocaleString('pt-BR')} KG`;

  return (
    <Box style={cargoCardGlassGradient} borderRadius="lg" my={0.5} py={2}>
      <Stack direction={['column', 'column', 'row']} mx={2}>
        <Center
          boxSize={{ sm: undefined, md: undefined, lg: 20 }}
          borderRadius="lg"
        >
          <Image w="75px" h="75px" src={logo} alt="truck-logo" borderRadius="lg" />
        </Center>

        <Stack direction={['column', 'column', 'row']}>
          <VStack spacing={0} px={2} flexGrow={2} justifyContent="space-evenly">
            <CargoTooltip
              label={t('SEARCH.CARGO_ORIGIN')}
              text={`${cargoData?.fromCity} | ${cargoData?.fromUf}`}
              icon={FaHome}
            />
            <CargoTooltip
              label={t('SEARCH.CARGO_DESTINY')}
              text={`${cargoData?.toCity} | ${cargoData?.toUf}`}
              icon={FaMapMarkerAlt}
            />
          </VStack>
          <VStack spacing={0} flexGrow={2} px={2} justifyContent="space-evenly">
            <CargoTooltip
              label={t('SEARCH.CARGO_VEHICLE')}
              text={cargoData?.vehicles}
              icon={FaTruckPickup}
            />
            <CargoTooltip
              label={t('SEARCH.CARGO_BODYWORK')}
              text={cargoData?.bodyworks}
              icon={FaTruckLoading}
            />
          </VStack>
          <VStack
            spacing={0}
            flexShrink={1}
            px={2}
            justifyContent="space-evenly"
          >
            <CargoTooltip
              label={t('SEARCH.CARGO_WEIGHT')}
              text={weight}
              icon={GiWeight}
            />
            <CargoTooltip
              label={t('SEARCH.CARGO_PRICE')}
              text={price}
              icon={MdAttachMoney}
            />
          </VStack>
          <Center>
            <Button bgColor="#314292" onClick={viewDetails}>
              <Text color="white" fontSize="md" fontWeight="semibold">
                {t('SEARCH.FIND_OUT_MORE')}
              </Text>
            </Button>
          </Center>
        </Stack>
      </Stack>

      <CargoModal isOpen={isOpen} onClose={onClose} cargoData={cargoData} />
    </Box>
  );
}

export default Cargo;

import axios from '../../config/axios';

export async function getVehicles() {
  try {
    const vehicles = await axios.get('/vehicles');

    return vehicles.data || [];
  } catch (error) {
    console.error(error);
  }

  return [];
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  useToast,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import schema from './schema';
import { editAvatarFile } from '../../../providers/user';
import { useUserDataContext } from '../../../contexts';

interface LogoUploadDialogProps {
  title: string;
  text: string;
  isOpen: boolean;
  onClose: any;
}

interface LogoUploadFormProps {
  avatarFile: any;
}

function LogoUploadDialog({
  isOpen, onClose, title, text,
}: LogoUploadDialogProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const cancelRef = React.useRef<any>();
  const [cookies] = useCookies();
  const { setAvatar } = useUserDataContext();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<LogoUploadFormProps>({
    resolver: schema,
  });

  const closeAndClearForm = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<LogoUploadFormProps> = async (data) => {
    const hasEditedAvatar = await editAvatarFile(
      data,
      cookies.token,
    );

    if (hasEditedAvatar?.path) { setAvatar(hasEditedAvatar?.path || ''); }

    toast({
      description: hasEditedAvatar.message,
      status: hasEditedAvatar.status,
      duration: 9000,
      isClosable: true,
    });

    closeAndClearForm();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={closeAndClearForm}
      leastDestructiveRef={cancelRef}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color="white">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody color="white">
              {text}
              <FormControl isInvalid={!!errors?.avatarFile}>
                <FormErrorMessage color="white" py={2}>
                  {errors?.avatarFile && errors?.avatarFile?.message}
                </FormErrorMessage>

                <Input
                  id="avatarFile"
                  accept="image/*"
                  multiple={false}
                  variant="outline"
                  type="file"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  borderWidth={2}
                  color="#f3ebeb"
                  _placeholder={{
                    color: '#f3ebeb',
                  }}
                  {...register('avatarFile')}
                />
              </FormControl>
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack>
                <Button
                  color="white"
                  ref={cancelRef}
                  onClick={closeAndClearForm}
                >
                  {t('TOASTS.COMMONS.CANCEL')}
                </Button>
                <Button
                  color="#FFF"
                  bgColor="#314292"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {t('TOASTS.COMMONS.CONFIRM')}
                </Button>
              </HStack>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default LogoUploadDialog;

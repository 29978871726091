import React, { useEffect } from 'react';
import { Box, Center } from '@chakra-ui/react';

import LANG from '../../locale/pt-br';
import Title from '../../components/Title';

interface PageProps {
  transparent?: boolean;
}

function PrivacyPolicy({ transparent }: PageProps) {
  const glassGradient = {
    background:
      'transparent linear-gradient(143deg, #FFFFFF66 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box',
    opacity: 0.96,
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
  };

  const commonTextStyle = {
    fontSize: '10pt',
    fontWeight: 'bold',
    color: '#314292',
  };

  const titleTextStyle = {
    fontSize: '14pt',
    fontWeight: 'bold',
    color: '#1a244e',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box w="100%" style={transparent ? undefined : glassGradient} minH="100vh">
      <Center py={4}>
        <Title text={LANG.PRIVACY_POLICY.TITLE} size="md1" />
      </Center>

      <Box px={8} pt={8} textAlign="justify">
        <p className="p1">
          <span className="s1" style={titleTextStyle}>
            <strong>
              Lei Geral de Proteção de Dados - LGPD
              <br />
            </strong>
            <span style={commonTextStyle}>
              Ao concordar com este termo, esta empresa fica autorizada a
              tomar decisões referentes ao tratamento de seus dados pessoais.
              <br />
            </span>
            <br />
          </span>
          <span className="s1" style={titleTextStyle}>
            <strong>Finalidades do Tratamento dos Dados</strong>
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={commonTextStyle}>
            O tratamento dos dados pessoais listados neste termo tem as
            seguintes finalidades:
          </span>
        </p>
        <ul className="ul1">
          <li className="li1">
            <span className="s1" style={commonTextStyle}>
              Possibilitar que identifique e entre em contato para fins de
              relacionamento comercial.
            </span>
          </li>
          <li className="li1">
            <span className="s1" style={commonTextStyle}>
              Possibilitar que elabore contratos comerciais e emita cobranças.
            </span>
          </li>
          <li className="li1">
            <span className="s1" style={commonTextStyle}>
              Possibilitar que envie ou forneça seus produtos e serviços, de
              forma remunerada ou gratuita.
            </span>
          </li>
          <li className="li1">
            <span className="s1" style={commonTextStyle}>
              Possibilitar que estruture, teste, promova e faça propaganda de
              produtos e serviços, personalizados ou não ao perfil.
            </span>
          </li>
        </ul>
        <br />
        <p className="p1">
          <span className="s1" style={titleTextStyle}>
            <strong>Compartilhamento de Dados</strong>
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={commonTextStyle}>
            Fica autorizado a compartilhar os dados pessoais com outros agentes
            de tratamento de dados, caso seja necessário para as finalidades
            listadas neste termo, observados os princípios e as garantias
            estabelecidas pela Lei nº 13.709.
            <br />
            <br />
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={titleTextStyle}>
            <strong>Segurança dos Dados</strong>
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={commonTextStyle}>
            Responsabiliza-se pela manutenção de medidas de segurança, técnicas
            e administrativas aptas a proteger os dados pessoais de acessos não
            autorizados e de situações acidentais ou ilícitas de destruição,
            perda, alteração, comunicação ou qualquer forma de tratamento
            inadequado ou ilícito.
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={commonTextStyle}>
            Em conformidade ao art. 48 da Lei nº 13.709, o Controlador
            comunicará ao Titular e à Autoridade Nacional de Proteção de Dados
            (ANPD) a ocorrência de incidente de segurança que possa acarretar
            risco ou dano relevante ao Titular.
            <br />
            <br />
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={titleTextStyle}>
            <strong>Término do Tratamento dos Dados</strong>
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={commonTextStyle}>
            Poderá manter e tratar os dados pessoais durante todo o período em
            que os mesmos forem pertinentes ao alcance das finalidades listadas
            neste termo. Dados pessoais anonimizados, sem possibilidade de
            associação ao indivíduo, poderão ser mantidos por período
            indefinido.
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={commonTextStyle}>
            O cliente poderá solicitar via e-mail ou correspondência, a qualquer
            momento, que sejam eliminados os dados pessoais não anonimizados.
            Fica ciente de que poderá ser inviável continuar o fornecimento de
            produtos ou serviços a partir da eliminação dos dados pessoais.
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={commonTextStyle}>
            &nbsp;
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={titleTextStyle}>
            <strong>Direitos</strong>
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={commonTextStyle}>
            Tem direito a obter em relação aos dados tratados, a qualquer
            momento e mediante requisição: I - confirmação da existência de
            tratamento; II - acesso aos dados; III - correção de dados
            incompletos, inexatos ou desatualizados; IV - anonimização, bloqueio
            ou eliminação de dados desnecessários, excessivos ou tratados em
            desconformidade com o disposto na Lei nº 13.709; V - portabilidade
            dos dados a outro fornecedor de serviço ou produto, mediante
            requisição expressa e observados os segredos comercial e industrial,
            de acordo com a regulamentação do órgão controlador; V -
            portabilidade dos dados a outro fornecedor de serviço ou produto,
            mediante requisição expressa, de acordo com a regulamentação da
            autoridade nacional, observados os segredos comercial e industrial;
            VI - eliminação dos dados pessoais tratados com o consentimento do
            titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709;
            VII - informação das entidades públicas e privadas com as quais o
            controlador realizou uso compartilhado de dados; VIII - informação
            sobre a possibilidade de não fornecer consentimento e sobre as
            consequências da negativa; IX - revogação do consentimento, nos
            termos do § 5º do art. 8º da Lei nº 13.709.
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={commonTextStyle}>
            &nbsp;
          </span>
        </p>
        <p className="p1">
          <span className="s1" style={titleTextStyle}>
            <strong>Direito de Revogação do Consentimento</strong>
          </span>
        </p>
        <p className="p2">
          <span style={commonTextStyle}>
            <span className="s1">
              Este consentimento poderá ser revogado pelo Titular, a qualquer
              momento, pelo aplicativo, solicitação via e-mail ou
              correspondência
            </span>
            <span className="s2">.</span>
          </span>
        </p>
        <br />
        <br />
      </Box>
    </Box>
  );
}

PrivacyPolicy.defaultProps = {
  transparent: false,
};

export default PrivacyPolicy;

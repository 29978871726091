import axios from 'axios';

const port = Number(process.env.REACT_APP_AXIOS_PORT);
const timeout = Number(process.env.REACT_APP_AXIOS_TIMEOUT);
const isDev = process.env.REACT_APP_ENV === 'dev';

export const baseURL = isDev ? `${process.env.REACT_APP_DEV_URL}:${port}` : `${process.env.REACT_APP_PRODUCTION_URL}`;

const instance = axios.create({ baseURL, timeout });

export default instance;

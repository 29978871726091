import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Title from '../../components/Title';
import { activateUser } from '../../providers/user';

interface PageProps {
  transparent?: boolean;
}

function Activation({ transparent }: PageProps) {
  const { t } = useTranslation();
  const { activationToken } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [hasActivated, setHasActivated] = useState(false);
  const navigate = useNavigate();

  const glassGradient = {
    background:
      'transparent linear-gradient(143deg, #FFFFFF66 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box',
    opacity: 0.96,
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
  };

  const activateAccount = useCallback(async () => {
    try {
      const hasActivatedPromise = await activateUser(activationToken);
      setHasActivated(!!hasActivatedPromise);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [activationToken]);

  const navigateToLogin = () => navigate('/logout');

  useEffect(() => {
    activateAccount();
  }, [activateAccount]);

  return (
    <Box w="100%" style={transparent ? undefined : glassGradient} minH="100vh">
      <Center py={4}>
        <Title text={t('USER.ACTIVATION_TITLE')} size="md" />
      </Center>

      <VStack py={8} spacing={8}>
        {!isLoading && hasActivated ? (
          <>
            <Text color="#FFF" fontSize="24px">
              {t('USER.ACTIVATION')}
            </Text>
            <Button
              mt={8}
              fontSize="24px"
              isLoading={isLoading}
              loadingText="Carregando ..."
              onClick={navigateToLogin}
            >
              <Text color="#FFF" fontWeight="semibold">
                {t('USER.ACTIVATION_BUTTON')}
              </Text>
            </Button>
          </>
        ) : (
          <Text color="#FFF" fontWeight="semibold">
            {t('USER.ACTIVATION_ERROR')}
          </Text>
        )}
      </VStack>
    </Box>
  );
}

Activation.defaultProps = {
  transparent: false,
};

export default Activation;

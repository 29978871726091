/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  // eslint-disable-next-line max-len
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { modalGradient } from '../../../../constants/backgroundStyle';
import { createSocialUser } from '../../../../providers/user';

interface Props {
  isOpen: boolean;
  onClose: any;
  email: string;
  // eslint-disable-next-line react/require-default-props
}

function RegisterTypeModal({ isOpen, onClose, email }: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [, setCookie] = useCookies(['token']);
  const [radioValue, setRadioValue] = useState('1');

  const onSubmit = async () => {
    const body = {
      email,
      password: 'adasd22',
      role: 'COMPANY',
      businnessType: 'AGENT',
    };

    if (radioValue !== '1') {
      body.role = 'DRIVER';
      body.businnessType = 'DRIVER';
    }

    const isCreated = await createSocialUser(body);

    toast({
      description: isCreated.message,
      status: isCreated.status,
      duration: 9000,
      isClosable: true,
    });

    if (isCreated.status === 'success') {
      if (isCreated.token) {
        setCookie('token', isCreated.token, {
          path: '/',
        });
      }

      navigate('/user-cargos', { replace: true });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent style={modalGradient}>
        <ModalHeader color="white">
          {t('REGISTER_TYPE_MODAL.SELECT_TYPE')}
          :
        </ModalHeader>
        <ModalBody>
          <RadioGroup
            defaultValue="1"
            color="white"
            value={radioValue}
            onChange={setRadioValue}
          >
            <Stack direction="row" justifyContent="space-evenly">
              <Radio value="1">{t('REGISTER_TYPE_MODAL.IM_COMPANY')}</Radio>
              <Radio value="2">{t('REGISTER_TYPE_MODAL.IM_DRIVER')}</Radio>
            </Stack>
          </RadioGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            color="white"
            bgColor="#009F50"
            onClick={() => {
              onSubmit();
            }}
          >
            {t('REGISTER_TYPE_MODAL.SELECT')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RegisterTypeModal;

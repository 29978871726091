import React from 'react';
import { Text } from '@chakra-ui/react';

interface TitleProps {
  text: string;
  size: 'sm' | 'md' | 'md1'| 'lg' | 'xl' | '2xl';
  center?: boolean;
}

function Title({ text, size, center = false }: TitleProps) {
  const shadowStyle = size === 'sm'
    ? { textShadow: '3px 3px 1px rgba(0, 63, 141, 1)' }
    : { textShadow: '6px 6px 2px rgba(0, 63, 141, 1)' };

  const fontWeight = size === 'sm' ? 'medium' : 'extrabold';

  const availableSizes = [
    { type: 'sm', size: '20px' },
    { type: 'md1', size: '36px' },
    { type: 'md', size: '48px' },
    { type: 'lg', size: '72px' },
    { type: 'xl', size: '94px' },
    { type: '2xl', size: '100px' },
  ];

  const renderFontSize = (type: string) => (type && availableSizes?.find((item) => item.type === type)?.size) || 'lg';
  const fontSize = renderFontSize(size);

  return (
    <Text
      align={center ? 'center' : 'left'}
      lineHeight={1.2}
      color="#FFF"
      fontWeight={fontWeight}
      fontSize={fontSize}
      style={shadowStyle}
    >
      {text}
    </Text>
  );
}

Title.defaultProps = {
  center: false,
};

export default Title;

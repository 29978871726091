import React, { useEffect } from 'react';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  useMediaQuery,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Title from '../../components/Title';
import Wrapper from '../../components/Wrapper';

import ContactUsImg from '../../assets/contact-us.png';

import { createFeedback, sendFeedbackGreetings } from '../../providers/feedback';
import schema from './schema';

interface FormProps {
  name: string;
  email: string;
  feedback: string;
}

interface PageProps {
  transparent?: boolean;
}

function ContactUs({ transparent }: PageProps) {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery(['(max-width: 768px)']);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormProps>({
    resolver: schema,
  });

  const toast = useToast();

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    const { message = '', status = 'error' } = await createFeedback(data);

    toast({
      description: message,
      status,
      duration: 4500,
      isClosable: true,
    });

    if (status === 'success' && data?.email && data?.name) {
      await sendFeedbackGreetings(data.email, data.name);
    }

    reset();

    return true;
  };

  const glassGradient = {
    background:
      'transparent linear-gradient(143deg, #FFFFFF66 0%, #FFFFFF0D 100%) 0% 0% no-repeat padding-box',
    opacity: 0.96,
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper transparent={transparent}>
      <Stack w="100%" pb={12} px={!transparent ? 12 : 0}>
        <Center pb={10}>
          <Title text={t('CONTACT.TITLE')} size="md1" />
        </Center>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} width="full">
          {!isMobile && (
          <GridItem>
            <Image src={ContactUsImg} />
          </GridItem>
          )}
          <GridItem colSpan={isMobile ? 2 : 1}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={4}>
                <FormControl isInvalid={!!errors?.name}>
                  <Input
                    id="name"
                    width="full"
                    placeholder={t('CONTACT.NAME')}
                    style={glassGradient}
                    borderColor="white"
                    borderRadius="none"
                    color="#314292"
                    opacity={0.6}
                    _placeholder={{
                      color: '#314292',
                    }}
                    {...register('name')}
                  />
                  <FormErrorMessage color="white">
                    {errors?.name && errors?.name?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors?.email}>
                  <Input
                    id="email"
                    width="full"
                    placeholder={t('CONTACT.EMAIL')}
                    style={glassGradient}
                    borderColor="white"
                    borderRadius="none"
                    color="#314292"
                    opacity={0.6}
                    _placeholder={{ color: '#314292' }}
                    {...register('email')}
                  />
                  <FormErrorMessage color="white">
                    {errors?.email && errors?.email?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors?.feedback}>
                  <Textarea
                    id="feedback"
                    width="full"
                    placeholder={t('CONTACT.MESSAGE')}
                    style={glassGradient}
                    borderColor="white"
                    borderRadius="none"
                    color="#314292"
                    opacity={0.6}
                    _placeholder={{ color: '#314292' }}
                    resize="none"
                    size="lg"
                    rows={10}
                    {...register('feedback')}
                  />
                  <FormErrorMessage color="white">
                    {errors?.feedback && errors?.feedback?.message}
                  </FormErrorMessage>
                </FormControl>
                <br />
                <Button
                  type="submit"
                  size="lg"
                  px={24}
                  isLoading={isSubmitting}
                  bgColor="#314292"
                >
                  <Text color="white">{t('CONTACT.SEND')}</Text>
                </Button>
              </VStack>
            </form>
          </GridItem>
        </Grid>
      </Stack>
    </Wrapper>
  );
}

ContactUs.defaultProps = {
  transparent: false,
};

export default ContactUs;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  FaEnvelope, FaPhoneAlt, FaWhatsapp,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';

import { NO_SYMBOLS_AND_SPACES } from '../../../constants/regex';

import { filterCargoById } from '../../../providers/search';

import noTruckLogo from '../../../assets/truck-no-logo.png';
import { baseURL } from '../../../config/axios';

interface extraInfoProps {
  notes: string;
  user: {
    id: number;
    tradeName: string;
    cnpj: string;
    bookmarks: {
      title: string;
      description: string;
      whatsapp: boolean;
      phone: string;
    }[];
    contactPhone: string;
    contactCelPhone: string;
    contactEmail: string;
  };
}

export interface CargoModalProps {
  isOpen: boolean;
  onClose: any;
  cargoData: {
    id: number;
    product: string;
    productType: string;
    fromCity: string;
    fromCityIbge: number;
    fromUf: string;
    latitude: number;
    longitude: number;
    toUf: string;
    toCity: string;
    toCityIbge: number;
    toCityLat: number;
    toCityLon: number;
    date: any;
    createdAt: any;
    vehicles: string;
    bodyworks: string;
    weight: number;
    price: number;
    tracing: boolean;
    agencing: boolean;
    complementary: boolean;
    avatarFile: string;
    done: boolean;
  };
}

interface InfoProps {
  title: string;
  info: string;
}

function InfoLine({ title, info }: InfoProps) {
  return (
    <Box w="100%">
      <Text fontSize="md" color="white" fontWeight="semibold">
        {title}
        :
      </Text>
      <Text textAlign="justify" fontSize="md" color="#303B8B">
        {info}
      </Text>

      <Divider
        py={0.8}
        borderColor="white"
        borderTopWidth={1}
        borderBottomWidth={1}
      />
    </Box>
  );
}

function CargoModal({ isOpen, onClose, cargoData }:CargoModalProps) {
  const { t } = useTranslation();
  const [extraInfo, setExtraInfo] = useState<extraInfoProps>();

  const gradient = {
    background: 'transparent linear-gradient(122deg, #7EB9EF 0%, #6772A9 100%) 0% 0% no-repeat padding-box',
    opacity: 0.99,
    backdropFilter: 'blur(9px)',
    WebkitBackdropFilter: 'blur(9px)',
  };

  const logo = cargoData.avatarFile ? `${baseURL}/avatars/${cargoData.avatarFile}` : noTruckLogo;
  const renderYesNo = (isTrue: boolean) => (isTrue ? t('COMMONS.YES') : t('COMMONS.NO'));

  const formattedDate = parseISO(cargoData?.createdAt);
  const stringDate = format(formattedDate, 'dd/MM/yyyy');
  const fixedPrice = cargoData?.price.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
  });

  const generateWhatsAppLink = (phone: string) => `https://api.whatsapp.com/send/?phone=55${phone}&text=${t('SEARCH.WHATSAPP_MSG')}&type=phone_number&app_absent=0`;

  const getInitialData = useCallback(async () => {
    if (cargoData?.id) {
      const extra = await filterCargoById(cargoData?.id);

      setExtraInfo(extra);
    }
  }, [cargoData?.id]);

  const generateGoogleMapsUrl = (fromPlace: string, toPlace: string) => {
    const baseUrl = 'https://www.google.com/maps/dir/';

    if (!fromPlace || !toPlace) return baseUrl;

    const encodedFromPlace = encodeURIComponent(fromPlace);
    const encodedToPlace = encodeURIComponent(toPlace);

    const url = `${baseUrl}${encodedFromPlace}/${encodedToPlace}/`;

    return url;
  };

  useEffect(() => { getInitialData(); }, [getInitialData]);

  return (
    <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader style={gradient} color="white">
          {`${t('CARGO_MODAL.TITLE')} - ${stringDate}`}
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody style={gradient}>
          <Stack py={4} spacing="14px" direction={['column', 'column', 'row']}>
            <VStack flexGrow={1} maxW={{ sm: '80%', md: '80%', lg: '50%' }}>
              <HStack w="100%">
                <Image
                  h="75px"
                  w="75px"
                  src={logo}
                  alt="truck-logo"
                  borderRadius="lg"
                />
                <Box w="100%">
                  <Text color="#FFF" fontWeight="semibold">
                    {extraInfo?.user?.tradeName}
                  </Text>
                </Box>
              </HStack>
              <InfoLine
                title={t('CARGO_MODAL.VEHICLES')}
                info={cargoData?.vehicles}
              />
              <InfoLine
                title={t('CARGO_MODAL.BODYWORKS')}
                info={cargoData?.bodyworks}
              />
              <InfoLine
                title={t('CARGO_MODAL.AGENT')}
                info={renderYesNo(cargoData?.agencing)}
              />
              <InfoLine
                title={t('CARGO_MODAL.COMPLEMENTARY')}
                info={renderYesNo(cargoData?.complementary)}
              />
              <InfoLine
                title={t('CARGO_MODAL.TRACER')}
                info={renderYesNo(cargoData?.tracing)}
              />
              <InfoLine
                title={t('CARGO_MODAL.MORE_INFO')}
                info={extraInfo?.notes || ''}
              />
            </VStack>
            <VStack flexGrow={1} maxW={{ sm: '80%', md: '80%', lg: '50%' }}>
              <Box w="100%">
                <Center bgColor="#016AF2">
                  <Text color="white">
                    {t('CARGO_MODAL.CONTACTS')}
                    :
                  </Text>
                </Center>
                <Stack
                  bgColor="#303B8B"
                  justifyContent="space-around"
                  direction={['column', 'column', 'column']}
                  p={2}
                >
                  {extraInfo?.user?.contactPhone && (
                  <Box>
                    <HStack>
                      <Text color="white">
                        {t('CARGO_MODAL.PHONE')}
                        :
                      </Text>
                      <HStack>
                        <Link href={`tel:${extraInfo?.user?.contactPhone}`}>
                          <Button bgColor="#314292" size="sm">
                            <HStack>
                              <FaPhoneAlt color="white" />
                              <Text color="white">
                                {extraInfo?.user?.contactPhone}
                              </Text>
                            </HStack>
                          </Button>
                        </Link>
                      </HStack>
                    </HStack>
                  </Box>
                  )}

                  {extraInfo?.user?.contactCelPhone && (
                  <Box>
                    <HStack>
                      <Text color="white">
                        {t('CARGO_MODAL.CELLPHONE')}
                        :
                      </Text>
                      <HStack>
                        <Link
                          href={generateWhatsAppLink(
                            extraInfo?.user?.contactCelPhone.replace(
                              NO_SYMBOLS_AND_SPACES,
                              '',
                            ),
                          )}
                          target="_blank"
                        >
                          <Button bgColor="#314292" size="sm">
                            <HStack>
                              <FaWhatsapp color="white" />
                              <Text color="white">
                                {extraInfo?.user?.contactCelPhone}
                              </Text>
                            </HStack>
                          </Button>
                        </Link>
                      </HStack>
                    </HStack>
                  </Box>
                  )}

                  {extraInfo?.user?.contactEmail && (
                  <Box>
                    <HStack>
                      <Text color="white">
                        Email:
                      </Text>
                      <HStack>
                        <Link href={`mailto:${extraInfo?.user?.contactEmail}`}>
                          <Button bgColor="#314292" size="sm">
                            <HStack>
                              <FaEnvelope color="white" />
                              <Text color="white">
                                {extraInfo?.user?.contactEmail}
                              </Text>
                            </HStack>
                          </Button>
                        </Link>
                      </HStack>
                    </HStack>
                  </Box>
                  )}

                  {extraInfo?.user?.bookmarks?.map((contact) => (
                    <Box key={contact?.phone}>
                      <HStack>
                        <Text color="white">
                          {contact?.title}
                          :
                        </Text>
                        <HStack>
                          {contact?.whatsapp ? (
                            <HStack>
                              <Link
                                href={generateWhatsAppLink(
                                  contact?.phone.replace(
                                    NO_SYMBOLS_AND_SPACES,
                                    '',
                                  ),
                                )}
                                target="_blank"
                              >
                                <Button bgColor="#314292" size="sm">
                                  <HStack>
                                    <FaWhatsapp color="white" />
                                    <Text color="white">
                                      {contact?.phone}
                                    </Text>
                                  </HStack>
                                </Button>
                              </Link>
                            </HStack>
                          ) : (
                            <Link href={`tel:${contact?.phone}`}>
                              <Button bgColor="#314292" size="sm">
                                <HStack>
                                  <FaPhoneAlt color="white" />
                                  <Text color="white">
                                    {extraInfo?.user?.contactPhone}
                                  </Text>
                                </HStack>
                              </Button>
                            </Link>
                          )}
                        </HStack>
                      </HStack>
                    </Box>
                  ))}
                </Stack>
                <Center bgColor="#016AF2">
                  <Text color="white">
                    {t('CARGO_MODAL.PRICE')}
                    :
                  </Text>
                </Center>
                <Center py={4} bgColor="#303B8B">
                  <Text fontSize="2xl" fontWeight="bold" color="white">
                    R$
                    {' '}
                    {fixedPrice}
                  </Text>
                </Center>
              </Box>
              <Box w="100%">
                <Link
                  w="100%"
                  target="_blank"
                  href={generateGoogleMapsUrl(
                    `${cargoData?.fromCity}, ${cargoData?.fromUf}`,
                    `${cargoData?.toCity}, ${cargoData?.toUf}`,
                  )}
                >
                  <Button bgColor="#314292" color="#FFF" w="100%">{t('CARGO_MODAL.ROUTE')}</Button>
                </Link>
              </Box>
              <Box w="100%">
                <Button onClick={onClose} bgColor="#314292" color="#FFF" w="100%">{t('CARGO_MODAL.CLOSE')}</Button>
              </Box>
            </VStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CargoModal;

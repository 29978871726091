/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button, Center, Stack, Text,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import Cargo from './Cargo';
import Filters from './Filters';
import Title from '../../components/Title';
import Wrapper from '../../components/Wrapper';
import { CargoModalProps } from './CargoModal';

import { filterCargos } from '../../providers/search';

import { SearchFilterProps } from '../../types';

interface CargoSearchProps {
  filter?: boolean;
  firstPage?: boolean;
  transparent?: boolean;
}

function CargoSearch({ filter, firstPage, transparent }: CargoSearchProps) {
  const { t } = useTranslation();

  const searchRef = useRef<HTMLDivElement>(null);
  const foundCargoRef = useRef<HTMLDivElement>(null);
  const findMoreButtonRef = useRef<HTMLButtonElement>(null);

  const [foundCargos, setFoundCargos] = useState([]);
  const limit = useMemo(
    () => ({ limit: firstPage ? 10 : undefined }),
    [firstPage],
  );

  const scrollToFilter = useCallback(() => {
    if (!firstPage) {
      searchRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [firstPage]);

  const scrollToFoundCargos = useCallback(() => {
    foundCargoRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, []);

  const scrollToNotFoundArea = useCallback(() => {
    findMoreButtonRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }, []);

  const filterCargosBySearch = useCallback(
    async (searchData: SearchFilterProps, firstRender = false) => {
      try {
        const filtered = await filterCargos({
          limit: firstPage ? 10 : undefined,
          ...searchData,
        });

        setFoundCargos(filtered);

        if (!firstPage && !firstRender && filtered?.length > 0) {
          scrollToFoundCargos();
        } else if (!firstPage && !firstRender && filtered?.length === 0) {
          scrollToNotFoundArea();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [firstPage, scrollToFoundCargos, scrollToNotFoundArea],
  );

  useEffect(() => {
    // First time render, force scroll to filter
    filterCargosBySearch(limit, true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper transparent={transparent}>
      <Stack direction={['column', 'column', 'row']} minHeight={!firstPage ? '100vh' : undefined}>
        {filter && (
          <Stack ref={searchRef}>
            <Filters onFilter={filterCargosBySearch} />
          </Stack>
        )}
        <Stack flexGrow={2}>
          <Center textAlign="center" pb={10}>
            {firstPage ? (
              <Title center text={t('SEARCH.FIRST_PAGE_TITLE')} size="md1" />
            ) : (
              <Text color="#314292" fontSize="4xl" fontWeight="extrabold">
                {t('SEARCH.TITLE')}
              </Text>
            )}
          </Center>
          {!foundCargos?.length && filter && (
            <>
              <Center textAlign="center" pb={10} mb={4}>
                <Text color="#314292" fontSize="2xl" fontWeight="extrabold">
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: t('SEARCH.NO_RESULTS') }}
                  />
                </Text>
              </Center>
              <Center>
                <Button
                  bgColor="#314282"
                  textColor="white"
                  onClick={() => filterCargosBySearch({
                    limit: 10,
                  })}
                >
                  {t('SEARCH.LATEST_CARGOS')}
                </Button>
              </Center>
            </>
          )}
          <Center ref={foundCargoRef} flexDirection="column" pt={2}>
            {foundCargos.map((item: CargoModalProps['cargoData']) => (
              <Cargo key={item?.id} cargoData={item} />
            ))}
          </Center>
        </Stack>

        {!firstPage && (
          <Center>
            <Button
              bgColor="#314282"
              textColor="white"
              onClick={() => scrollToFilter()}
              ref={findMoreButtonRef}
            >
              {t('SEARCH.BACK_TO_TOP')}
            </Button>
          </Center>
        )}
      </Stack>
    </Wrapper>
  );
}

CargoSearch.defaultProps = {
  filter: true,
  firstPage: false,
  transparent: false,
};

export default CargoSearch;

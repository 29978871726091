import { Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PWAProps {
  buttonName?: string;
}

function InstallPWAButton({ buttonName }: PWAProps) {
  const { t } = useTranslation();
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<Window | undefined>();

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      console.log('we are being triggered :D');
      setSupportsPWA(true);
      setPromptInstall(e as unknown as Window);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const onClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  if (!supportsPWA) {
    return null;
  }

  return (
    <Button
      size="lg"
      bgColor="#314292"
      _hover={{ bgColor: '#314292' }}
      onClick={onClick}
      color="white"
    >
      {buttonName || t('HOME.INSTALL_BUTTON')}
    </Button>
  );
}

InstallPWAButton.defaultProps = {
  buttonName: '',
};

export default InstallPWAButton;

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  Controller, SubmitHandler, useForm, useWatch,
} from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useCookies } from 'react-cookie';
import ptbr from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

import {
  UfList,
  UfProps,
  CityProps,
  VehicleOptionProps,
  BodyworkOptionProps,
  EditCargoProps,
} from '../../../types';
import schema from './schema';

import { getBodyworks } from '../../../providers/bodyworks';
import { getVehicles } from '../../../providers/vehicles';
import { getUfs } from '../../../providers/uf';
import { getCitiesByUfId } from '../../../providers/cities';
import { createCargo, editCargoById, getCargoById } from '../../../providers/cargos';
import { modalGradient } from '../../../constants/backgroundStyle';
import ControlledSelect from '../../../components/ControlledSelect';

interface CargoModalProps {
  isOpen: boolean;
  onClose: any;
  cargoId: number;
}

function EditCargoModal({ isOpen, onClose, cargoId }:CargoModalProps) {
  registerLocale('pt-BR', ptbr);

  const { t } = useTranslation();

  const fontColor = '#f3ebeb';

  const [cookies] = useCookies();
  const [fromUfs, setFromUfs] = useState<UfList>([]);
  const [toUfs, setToUfs] = useState<UfList>([]);

  const [fromCities, setFromCities] = useState<CityProps[]>([]);
  const [toCities, setToCities] = useState<CityProps[]>([]);

  const [vehicles, setVehicles] = useState<VehicleOptionProps[]>([]);
  const [bodyworks, setBodyworks] = useState<BodyworkOptionProps[]>([]);

  const [priceNumber, setPriceNumber] = useState<number | undefined>(0);
  const [weightNumber, setWeightNumber] = useState<number | undefined>(0);

  const defaultValues = {
    fromCityIbge: undefined,
    fromUf: undefined,
    toCityIbge: undefined,
    toUf: undefined,
    vehicles: [],
    bodyworks: [],
    product: undefined,
    productType: undefined,
    weight: undefined,
    price: undefined,
    notes: undefined,
    agencing: false,
    tracing: false,
    complementary: false,
    date: undefined,
  };

  const {
    handleSubmit,
    register,
    setValue,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<EditCargoProps>({
    resolver: schema,
    defaultValues,
  });

  const watchFromUf = useWatch({
    control,
    name: 'fromUf',
  });

  const watchToUf = useWatch({
    control,
    name: 'toUf',
  });

  const toast = useToast();

  const closeAndReset = () => {
    setToCities([]);
    setFromCities([]);
    reset(defaultValues);
    onClose();
  };

  const onSubmit: SubmitHandler<EditCargoProps> = async (data) => {
    try {
      let description;
      let status;

      const treatedBody = {
        ...data,
        bodyworks: data?.bodyworks?.map((bd) => bd.value) || [],
        vehicles: data?.vehicles?.map((vh) => vh.value) || [],
        price: priceNumber,
        weight: weightNumber,
        // @ts-ignore
        fromCityIbge: parseInt(data?.fromCityIbge?.value, 10),
        // @ts-ignore
        toCityIbge: parseInt(data?.toCityIbge?.value, 10),
        otherInfo: '',
        date: new Date(),
      };

      if (cargoId > 0) {
        const hasEdited = await editCargoById(cargoId, cookies?.token, treatedBody);

        description = hasEdited.message;
        status = hasEdited.status;
      } else {
        const hasCreated = await createCargo(cookies?.token, treatedBody);

        description = hasCreated.message;
        status = hasCreated.status;
      }

      toast({
        description,
        status,
        duration: 4500,
        isClosable: true,
      });

      closeAndReset();
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => (
    cargoId
      ? t('EDIT_CARGO_MODAL.EDIT_CARGO_TITLE')
      : t('EDIT_CARGO_MODAL.NEW_CARGO_TITLE')
  );

  const getCitiesById = async (ufId: number | undefined, type: 'from' | 'to') => {
    try {
      const cities = await getCitiesByUfId(ufId);

      if (type === 'from') {
        setFromCities(cities);
      }

      if (type === 'to') {
        setToCities(cities);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialData = useCallback(async () => {
    try {
      const ufs = await getUfs();
      const bodyworksData = await getBodyworks();
      const vehicleData = await getVehicles();

      setFromUfs(ufs);
      setToUfs(ufs);

      setBodyworks(
        bodyworksData.map((item: { name: string; id: number }) => ({
          label: item?.name,
          value: item?.id,
        })),
      );

      setVehicles(
        vehicleData.map((item: { name: string; id: number; }) => ({
          label: item?.name,
          value: item?.id,
        })),
      );

      if (cargoId > 0) {
        const cargoData = await getCargoById(cookies.token, cargoId);

        const treated = {
          ...cargoData,
          fromUf: {
            label: cargoData?.fromCity?.uf?.name,
            value: cargoData?.fromCity?.ufId,
          },
          fromCityIbge: {
            label: cargoData?.fromCity?.name,
            value: cargoData?.fromCity?.ibge,
          },
          toUf: {
            label: cargoData?.toCity?.uf?.name,
            value: cargoData?.toCity?.ufId,
          },
          toCityIbge: {
            label: cargoData?.toCity?.name,
            value: cargoData?.toCity?.ibge,
          },
          vehicles: cargoData?.vehicles?.map((item: any) => ({
            label: item?.vehicle?.name,
            value: item?.vehicleId,
          })) || [],
          bodyworks: cargoData?.bodyworks?.map((item: any) => ({
            label: item?.bodywork?.name,
            value: item?.bodyworkId,
          })) || [],
        };

        reset(treated);
      }
    } catch (error) {
      console.error('Erro ao buscar ufs');
    }
  }, [cargoId, cookies.token, reset]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    if (watchFromUf) {
      // @ts-ignore
      getCitiesById(watchFromUf?.value, 'from');
    }
  }, [setValue, watchFromUf]);

  useEffect(() => {
    if (watchToUf) {
      // @ts-ignore
      getCitiesById(watchToUf?.value, 'to');
    }
  }, [setValue, watchToUf]);

  return (
    <Modal
      closeOnOverlayClick={false}
      size="4xl"
      isOpen={isOpen}
      onClose={closeAndReset}
    >
      <ModalOverlay />
      <ModalContent style={modalGradient}>
        <ModalHeader color={fontColor}>{renderTitle()}</ModalHeader>
        <ModalCloseButton color={fontColor} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody pb={6}>
            <VStack spacing={2}>
              <Stack
                direction={['column', 'column', 'row']}
                spacing="10px"
                w="100%"
              >
                <Box flexGrow={1}>
                  <FormControl isInvalid={!!errors?.fromUf}>
                    <ControlledSelect
                      inputName="fromUf"
                      placeholder={t('EDIT_CARGO_MODAL.FROM_UF')}
                      control={control}
                      options={fromUfs.map((uf: UfProps) => (
                        {
                          label: uf?.name,
                          value: uf?.id,
                        }
                      ))}
                    />
                    <FormErrorMessage color="white">
                      {errors?.fromUf && errors?.fromUf?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box flexGrow={1}>
                  <FormControl isInvalid={!!errors?.fromCityIbge}>
                    <ControlledSelect
                      inputName="fromCityIbge"
                      fontColor="#FFF"
                      placeholder={t('EDIT_CARGO_MODAL.FROM_CITY')}
                      control={control}
                      // @ts-ignore
                      options={fromCities.map((city) => (
                        {
                          label: city?.name,
                          value: city?.ibge,
                        }
                      ))}
                    />
                    <FormErrorMessage color="white">
                      {errors?.fromCityIbge && errors?.fromCityIbge?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Stack>
              <Stack
                direction={['column', 'column', 'row']}
                spacing="10px"
                w="100%"
              >
                <Box flexGrow={2}>
                  <FormControl isInvalid={!!errors?.toUf}>
                    <ControlledSelect
                      inputName="toUf"
                      placeholder={t('EDIT_CARGO_MODAL.TO_UF')}
                      control={control}
                      options={toUfs.map((uf: UfProps) => (
                        {
                          label: uf?.name,
                          value: uf?.id,
                        }
                      ))}
                    />
                    <FormErrorMessage color="white">
                      {errors?.toUf && errors?.toUf?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box flexGrow={2}>
                  <FormControl isInvalid={!!errors?.toCityIbge}>
                    <ControlledSelect
                      inputName="toCityIbge"
                      fontColor="#FFF"
                      placeholder={t('EDIT_CARGO_MODAL.TO_CITY')}
                      control={control}
                      // @ts-ignore
                      options={toCities.map((city) => (
                        {
                          label: city?.name,
                          value: city?.ibge,
                        }
                      ))}
                    />
                    <FormErrorMessage color="white">
                      {errors?.toCityIbge && errors?.toCityIbge?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Stack>
              <Stack
                direction={['column', 'column', 'row']}
                spacing="10px"
                w="100%"
              >
                <Box flexGrow={2}>
                  <ControlledSelect
                    inputName="bodyworks"
                    control={control}
                    options={bodyworks}
                    isMulti
                    placeholder={t('EDIT_CARGO_MODAL.BODYWORKS')}
                  />
                </Box>
              </Stack>
              <Stack
                direction={['column', 'column', 'row']}
                spacing="10px"
                w="100%"
              >
                <Box flexGrow={2}>
                  <FormControl isInvalid={!!errors?.vehicles}>
                    <ControlledSelect
                      inputName="vehicles"
                      control={control}
                      options={vehicles}
                      isMulti
                      placeholder={t('EDIT_CARGO_MODAL.VEHICLES')}
                    />

                    <FormErrorMessage color="white">
                      {errors?.vehicles?.length}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Stack>
              <Stack
                direction={['column', 'column', 'row']}
                spacing="10px"
                w="100%"
              >
                <Box flexGrow={2}>
                  <FormControl isInvalid={!!errors?.product}>
                    <Input
                      id="product"
                      type="text"
                      placeholder={t('EDIT_CARGO_MODAL.PRODUCT')}
                      variant="outline"
                      h={50}
                      borderColor="#314292"
                      focusBorderColor="#314292"
                      _placeholder={{
                        color: fontColor,
                      }}
                      color={fontColor}
                      {...register('product')}
                    />
                    <FormErrorMessage color="white">
                      {errors?.product && errors?.product?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <Box flexGrow={2}>
                  <FormControl isInvalid={!!errors?.productType}>
                    <Input
                      id="productType"
                      type="text"
                      placeholder={t('EDIT_CARGO_MODAL.PRODUCT_TYPE')}
                      variant="outline"
                      h={50}
                      borderColor="#314292"
                      focusBorderColor="#314292"
                      _placeholder={{
                        color: fontColor,
                      }}
                      color={fontColor}
                      {...register('productType')}
                    />
                    <FormErrorMessage color="white">
                      {errors?.productType && errors?.productType?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Stack>

              <Stack
                direction={['column', 'column', 'row']}
                spacing="10px"
                w="100%"
              >
                <Box flexGrow={2}>
                  <FormControl isInvalid={!!errors?.weight}>
                    <Controller
                      name="weight"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <NumberFormat
                          value={value}
                          onChange={onChange}
                          onValueChange={(values) => {
                            const { floatValue } = values;
                            setWeightNumber(floatValue);
                          }}
                          ref={ref}
                          decimalScale={2}
                          decimalSeparator=","
                          suffix=" kg"
                          customInput={Input}
                          placeholder={t('EDIT_CARGO_MODAL.WEIGHT')}
                          variant="outline"
                          borderColor="#314292"
                          focusBorderColor="#314292"
                          h={50}
                          _placeholder={{
                            color: fontColor,
                          }}
                          color={fontColor}
                          className="white_text"
                        />
                      )}
                    />
                    <FormErrorMessage color="white">
                      {errors?.weight && errors?.weight?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box flexGrow={2}>
                  <FormControl isInvalid={!!errors?.price}>
                    <Controller
                      name="price"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <NumberFormat
                          value={value}
                          onChange={onChange}
                          ref={ref}
                          decimalScale={2}
                          decimalSeparator=","
                          prefix="R$ "
                          customInput={Input}
                          placeholder={t('EDIT_CARGO_MODAL.PRICE')}
                          onValueChange={(values) => {
                            const { floatValue } = values;
                            setPriceNumber(floatValue);
                          }}
                          variant="outline"
                          h={50}
                          borderColor="#314292"
                          focusBorderColor="#314292"
                          _placeholder={{
                            color: fontColor,
                          }}
                          color={fontColor}
                          className="white_text"
                        />
                      )}
                    />
                    <FormErrorMessage color="white">
                      {errors?.price && errors?.price?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Stack>
              <FormControl isInvalid={!!errors?.notes}>
                <Textarea
                  id="notes"
                  placeholder={t('EDIT_CARGO_MODAL.NOTES')}
                  variant="outline"
                  borderColor="#314292"
                  focusBorderColor="#314292"
                  rows={6}
                  resize="none"
                  _placeholder={{
                    color: fontColor,
                  }}
                  color={fontColor}
                  {...register('notes')}
                />
                <FormErrorMessage color="white">
                  {errors?.notes && errors?.notes?.message}
                </FormErrorMessage>
              </FormControl>

              <Stack
                direction={['column', 'column', 'row']}
                spacing="10px"
                w="100%"
              >
                <Box>
                  <Controller
                    name="tracing"
                    control={control}
                    render={({
                      field: {
                        onChange, onBlur, value, name, ref,
                      },
                      fieldState: { invalid, error },
                    }) => (
                      <FormControl isInvalid={invalid}>
                        <Checkbox
                          name={name}
                          ref={ref}
                          onChange={onChange}
                          onBlur={onBlur}
                          isChecked={value}
                          borderColor="#314292"
                          color={fontColor}
                        >
                          {t('EDIT_CARGO_MODAL.TRACING')}
                        </Checkbox>
                        <FormErrorMessage color="white">
                          {error && error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="agencing"
                    control={control}
                    render={({
                      field: {
                        onChange, onBlur, value, name, ref,
                      },
                      fieldState: { invalid, error },
                    }) => (
                      <FormControl isInvalid={invalid}>
                        <Checkbox
                          name={name}
                          ref={ref}
                          onChange={onChange}
                          onBlur={onBlur}
                          isChecked={value}
                          color={fontColor}
                          borderColor="#314292"
                        >
                          {t('EDIT_CARGO_MODAL.AGENCING')}
                        </Checkbox>
                        <FormErrorMessage color="white">
                          {error && error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="complementary"
                    control={control}
                    render={({
                      field: {
                        onChange, onBlur, value, name, ref,
                      },
                      fieldState: { invalid, error },
                    }) => (
                      <FormControl isInvalid={invalid}>
                        <Checkbox
                          name={name}
                          ref={ref}
                          onChange={onChange}
                          onBlur={onBlur}
                          isChecked={value}
                          color={fontColor}
                          borderColor="#314292"
                        >
                          {t('EDIT_CARGO_MODAL.COMPLEMENTARY')}
                        </Checkbox>
                        <FormErrorMessage color="white">
                          {error && error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </Box>
              </Stack>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              bgColor="#314292"
              color="#FFF"
              isLoading={isSubmitting}
              type="submit"
              mr={3}
            >
              {t('EDIT_CARGO_MODAL.REGISTER')}
            </Button>
            <Button color="#FFF" onClick={closeAndReset}>
              {t('EDIT_CARGO_MODAL.CANCEL')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default EditCargoModal;

const MASKS = {
  CNPJ: '##.###.###/####-##',
  CPF: '###.###.###-##',
  ZIPCODE: '#####-###',
  CARD: '#### #### #### ####',
  CARD_EXP_DATE: '##/####',
  CARD_CVV: '###',
  DYNAMIC_PHONE: (phoneNumber: string): string => {
    let formattedValue;

    if (phoneNumber.length < 2) {
      formattedValue = phoneNumber.replace(/^(\d{1})$/, '($1');
    } else if (phoneNumber.length <= 6) {
      formattedValue = phoneNumber.replace(/^(\d{2})(\d{0,4})$/, '($1) $2');
    } else if (phoneNumber.length <= 10) {
      formattedValue = phoneNumber.replace(
        /^(\d{2})(\d{1,4})(\d{0,4})(\d*)$/,
        '($1) $2-$3',
      );
    } else if (phoneNumber.indexOf('0800') !== -1) {
      formattedValue = phoneNumber.replace(/^(\d{4})(\d{3})(\d{4})(\d*)$/, '$1 $2 $3');
    } else {
      formattedValue = phoneNumber.replace(/^(\d{2})(\d{5})(\d{4})(\d*)$/, '($1) $2-$3');
    }

    return formattedValue;
  },
};

export default MASKS;

export const renderImage = (img: unknown) => ({
  backgroundImage: `url(${img})`,
  height: '120px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

export const renderOverlay = (darker: boolean | undefined) => ({
  height: '100%',
  width: '100%',
  backgroundColor: darker ? 'rgba(48, 59, 139, 0.8)' : 'rgba(0, 118, 222, 0.8)',
});

import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../../config/yup';

export default yupResolver(yup.object({
  fromUf: yup.object({
    label: yup.string(),
    value: yup.string(),
  }).notRequired(),
  fromCity: yup.object({
    label: yup.string(),
    value: yup.string(),
  }).notRequired(),
  toUf: yup.object({
    label: yup.string(),
    value: yup.string(),
  }).notRequired(),
  toCity: yup.object({
    label: yup.string(),
    value: yup.string(),
  }).notRequired(),
  vehicle: yup.string().notRequired(),
  bodywork: yup.string().notRequired(),
}));
